<template>
  <template v-if="fetchError">
    <button @click="fetchData" class="btn btn-sm btn-dark text-light active me-2 float-right"
      type="button">Refresh</button>
    <p class="text-danger">{{ fetchError }}</p>
  </template>

  <template v-else>
    <div class="container mt-5" v-if="model">
      <div class="row">
        <h1>
          Hardware Information
          <button v-show="!isEditing" @click="copyLocalVarsFromHardwareModel(false)" class="btn btn-sm btn-dark text-light active me-2 float-right"
            type="button">Edit</button>
        </h1>
        <hr>
      </div>

      <button v-if="isEditing ===false && model.assignmentStatus ==='In stock'" @click="checkinHardware()" class="btn btn-sm btn-success text-light" disabled>Assign Hardware</button>
      <button v-if="isEditing ===false && model.assignmentStatus==='Assigned'" @click="checkinHardware()" class="btn btn-sm btn-info text-light">Check into stock</button>
      <button v-if="isEditing ===false && model.assignmentStatus!=='Retired'" @click="retireHardware()" class="btn btn-sm btn-warning text-light">Retire Hardware</button>
      <button v-if="isEditing ===false" @click="deleteHardware()" class="btn btn-sm btn-danger text-light">Delete Hardware</button>

      <div class="card mb-4 pl-5 mt-3">
        <div class="card-header">Details</div>
        <div class="card-body">
          <div class="row">
            <div class="mb-3 col-lg-3">
              <label for="displayid">DisplayId</label>
              <!-- <input class="form-control" id="displayid" name="displayid" :value="localDisplayId" :readonly="!isEditing" type="text"> -->
              <input class="form-control" id="displayid" name="displayid" :value="localDisplayId" readonly type="text">
            </div>
            <div class="mb-3 col-lg-3">
              <label for="assignmentstatus">Assignment Status</label>
              <input class="form-control" id="assignmentstatus" name="assignmentstatus" :value="model.assignmentStatus" readonly type="text">
            </div>
            <div class="mb-3 col-lg-3">
              <label for="pupil">Pupil</label>
              <input class="form-control" id="pupil" name="pupil" :value="model.pupil?.name || ''" readonly type="text">
            </div>
            <div class="mb-3 col-lg-3">
              <label for="serial">Serial Number</label>
              <input class="form-control" id="serial" name="serial" v-model="localSerial" :readonly="!isEditing" type="text">
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-4 pl-5 mt-3">
        <div class="card-header">Purchase Information</div>
        <div class="card-body">
          <div class="row">
            <div class="mb-3 col-lg-3">
              <label for="supplier">Supplier</label>
              <input v-if="!isEditing" class="form-control" id="supplier" name="supplier" type="text" v-model="localSupplier" readonly>
              <select v-else class="form-control" id="supplier" name="supplier" v-model="localSupplier">
                <option v-for="supplier in suppliers" :key="supplier" :value="supplier" :selected="supplier === localSupplier">
                  {{ supplier }}
                </option>
              </select>
            </div>
            <div class="mb-3 col-lg-3">
              <label for="cost">Cost</label>
              <input v-if="!isEditing" class="form-control" id="cost" name="cost" :value="formattedCost" readonly>
              <input v-else class="form-control" id="cost" name="cost" v-model="localCost" type="number" step="any" @input="validateCost" @blur="validateCost">            </div>
            <div class="mb-3 col-lg-3">
              <label for="invoiceReference">Invoice Reference</label>
              <input class="form-control" id="invoiceReference" name="invoiceReference" type="text"
                v-model="localInvoiceReference" :readonly="!isEditing">
            </div>
            <div class="mb-3 col-lg-3">
              <label for="purchasedate">Purchase Date</label>
              <input v-if="!isEditing" class="form-control" id="purchasedate" name="purchasedate"
                :value="formatDate(model.purchaseDate)" readonly type="text">
              <input v-else class="form-control" id="purchasedate" name="purchasedate" v-model="localPurchaseDate"
                type="date">
            </div>
          </div>
        </div>
      </div>

      <div v-show="isEditing==false">
      <div class="card mb-4 pl-5 mt-3">
        <div class="card-header">Product Information</div>
        <div class="card-body">
          <div class="row">
            <div class="mb-3 col-lg-3">
              <label for="manufacturer">Manufacturer</label>
              <input class="form-control" id="manufacturer" name="manufacturer" type="text"
                :value="model.product && model.product.manufacturer ? model.product.manufacturer : ''" readonly>
            </div>
            <div class="mb-3 col-lg-3">
              <label for="category">Name</label>
              <input class="form-control" id="manufacturer" name="manufacturer" type="text"
                :value="model.product.deviceType ? model.product.deviceType : ''" readonly>
            </div>
            <div class="mb-3 col-lg-3">
              <label for="type">Type</label>
              <input class="form-control" id="type" name="type" type="text"
                :value="model.product && model.product.name ? model.product.name : ''" readonly>
            </div>
            <div class="mb-3 col-lg-3">
              <label for="version">Version</label>
              <input class="form-control" id="version" name="version" type="text"
                :value="model.version ? model.version : ''" readonly>
            </div>

          </div>
        </div>
      </div>

      <!-- Image Upload Section -->
      <!-- <div class="card mb-4 pl-5 mt-3">
        <div class="card-header">Upload Image</div>
        <div class="card-body">
          <input type="file" @change="handleFileUpload" multiple>
        </div>
      </div> -->

      <!-- Image Thumbnails Display Section -->
      <!-- <div class="card mb-4 pl-5 mt-3">
        <div class="card-header">Uploaded Images</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3" v-for="(image, index) in uploadedImages" :key="index">
              <img :src="image.url" class="img-thumbnail">
            </div>
          </div>
        </div>
      </div> -->
      </div>

      <div class="card mb-4 pl-5 mt-3">
        <div class="card-header">Notes</div>
        <div class="card-body">
          <textarea v-if="isEditing" class="form-control" rows="4" v-model="localNotes"></textarea>
          <p v-else>{{ model.notes }}</p>
        </div>
      </div>

      <div class="card mb-4 pl-5 mt-3" v-show="this.isEditing==false">
        <ChangeLogTable :changeLog="model.changeLog" />
      </div>

    <div v-if="isEditing === true" class="mb-4 pl-5 mt-3">
      <button v-if="isEditing" class="btn btn-primary" @click="patchHardware">Update Hardware</button>
    <button v-if="isEditing" class="btn btn-secondary" @click="cancelEditing">Cancel</button>
    </div>
    </div>
  </template>
</template>

<script>
import ChangeLogTable from '../../components/ChangeLogTable.vue';
export default {
  components: {
    ChangeLogTable
  },
    data() {
        return {
            id: '',
            fetchError: null,
            patchError: null,
            model: null,
            suppliers: [],
            isEditing: false,
            uploadedImages: [],
            localDisplayId: null,
            localSerial: null,
            localSupplier: null,
            localCost: null,
            localInvoiceReference: null,
            localPurchaseDate: null,
            localNotes: null
        };
    },
    created() {
        this.setIdFromRoute();
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        formattedCost() {
            if (this.model && this.model.cost) {
                const formattedNumber = this.localCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return `£${formattedNumber}`;
            }
            return '';
        }
    },
    methods: {
        async fetchData() {
            try {
                const resHardware = await fetch('https://be.pencasolutions.com/hardware/' + this.id, { credentials: 'include' });
                if (!resHardware.ok) {
                    throw new Error(`HTTP error! status: ${resHardware.status}`);
                }
                this.model = await resHardware.json();
                this.copyLocalVarsFromHardwareModel(true);
                this.fetchError = null; // clear the error
            }
            catch (error) {
                this.fetchError = error.message;
            }
            try {
                const resSuppliers = await fetch('https://be.pencasolutions.com/hardware/suppliers', { credentials: 'include' });
                if (!resSuppliers.ok) {
                    throw new Error(`HTTP error! status: ${resSuppliers.status}`);
                }
                this.suppliers = await resSuppliers.json();
            }
            catch (error) {
                // Handle supplier fetch error
            }
        },
        setIdFromRoute() {
            this.id = this.$route.params.id;
        },
        formatDate(dateString) {
            if (!dateString)
                return '';
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        },
        handleFileUpload(event) {
            const files = Array.from(event.target.files);
            files.forEach(file => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.uploadedImages.push({ url: e.target.result });
                };
                reader.readAsDataURL(file);
            });
        },
        toggleEdit() {
            this.isEditing = !this.isEditing;
        },
        validateCost(event) {
            // Allow digits and one decimal point
            const regex = /^\d*\.?\d{0,2}$/;
            if (!regex.test(event.target.value)) {
                event.target.value = event.target.value.substring(0, event.target.value.length - 1);
            }
        },
        formatDateToYYYYMMDD(date) {
            const d = new Date(date);
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        copyLocalVarsFromHardwareModel(firstLoad) {
            // Copy the data to the local variables
            this.localDisplayId = this.model.displayId;
            this.localSerial = this.model.serialNumber;
            this.localSupplier = this.model.supplier.name;
            this.localCost = this.model.cost;
            this.localInvoiceReference = this.model.invoiceReference;
            this.localPurchaseDate = this.formatDateToYYYYMMDD(this.model.purchaseDate);
            this.localNotes = this.model.notes;
            if (firstLoad) {
                this.isEditing = false;
            }
            else {
                this.toggleEdit();
            }
        },
        cancelEditing() {
            this.copyLocalVarsFromHardwareModel();
            this.isEditing = false;
        },
        async patchHardware() {
            try {
                const res = await fetch('https://be.pencasolutions.com/hardware/' + this.id, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        displayId: this.localDisplayId,
                        serialNumber: this.localSerial,
                        supplierName: this.localSupplier,
                        cost: this.localCost,
                        invoiceReference: this.localInvoiceReference,
                        purchaseDate: this.localPurchaseDate,
                        notes: this.localNotes
                    }),
                    credentials: 'include'
                });
                if (!res.ok) {
                    this.isEditing = false;
                    this.copyLocalVarsFromHardwareModel();
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                this.patchError = null; // clear the error
                await this.fetchData();
            }
            catch (error) {
                this.patchError = error.message;
            }
            this.isEditing = false;
        },
        async checkinHardware() {
            let checkHardwareIntoStockMsg = 'Validate the following before checking into stock:\n' +
                'Condition of equipment\n' +
                'Laptops/pc - Licences (still active)\n' +
                'Software on the device\n';
            if (confirm(checkHardwareIntoStockMsg)) {
                let res = await fetch("https://be.pencasolutions.com/" + 'hardware/' + this.model._id + '/checkin', {
                    method: "PATCH",
                    credentials: 'include',
                    headers: {
                        'Content-type': 'application/json',
                    },
                });
                if (res.status === 200) {
                    await this.fetchData();
                }
            }
        },
        async retireHardware() {
            if (confirm("Are you sure you wish to retire hardware?")) {
                let res = await fetch("https://be.pencasolutions.com/hardware/" + this.model._id + "/retire", {
                    method: "PATCH",
                    credentials: 'include',
                    headers: {
                        'Content-type': 'application/json',
                    },
                });
                if (res.status === 200) {
                    this.$router.push('/hardware');
                }
            }
        },
        async deleteHardware() {
            if (confirm("Are you sure you wish to delete hardware?")) {
                let res = await fetch("https://be.pencasolutions.com/" + 'hardware/' + this.model._id, {
                    method: "DELETE",
                    credentials: 'include',
                    headers: {
                        'Content-type': 'application/json',
                    },
                });
                if (res.status === 200) {
                    this.$router.push('/hardware');
                }
            }
        },
    },
}
</script>

<style scoped>
.scrollable-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 100%);
}
</style>