<template>
    <div class="container">
        <h1>IT Ticket</h1>
        <form>
            <div class="row">
                <div class="form-group col-lg-4">
                    <label for="title">Logged By</label>
                    <input type="text" class="form-control" id="title" v-model="ticket.loggedBy" readonly>
                </div>
            <div class="form-group col-lg-4">
                    <label for="title">Priority</label>
                    <input type="text" class="form-control" id="title" v-model="ticket.priority" readonly>
            </div>
            <div class="form-group col-lg-4">
                    <label for="title">Created At</label>
                    <input type="text" class="form-control" id="title" v-model="ticket.createdAt" readonly>
            </div>
            </div>
            <div class="row">
            <div class="form-group col-lg-3">
                <label for="category">Category</label>
                <input type="text" class="form-control" id="title" v-model="ticket.category" readonly>

            </div>
            <div class="form-group col-lg-3">
                <label for="subCategory">Sub Category</label>
                <input type="text" class="form-control" id="title" v-model="ticket.subCategory" readonly>

            </div>
            <div class="form-group col-lg-3">
                <label for="subCategory">Status</label>
                <input type="text" class="form-control" id="title" v-model="ticket.status" readonly>

            </div>
            <div class="form-group col-lg-3">
                <label for="assignedTo">Assigned To</label>
                <input type="text" class="form-control" id="title" v-model="ticket.assignedTo" readonly>

            </div>
            <div class="form-group col-lg-6" v-if="ticket.category === 'hardware'">
                <label for="subCategory">Sub Category</label>
                <select class="form-control" id="subCategory" v-model="ticket.subCategory">
                    <option value="repair">Repair</option>
                    <option value="upgrade">Upgrade</option>
                </select>
            </div>
        </div>
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" class="form-control" id="title" v-model="ticket.title" readonly>
            </div>
            <div class="form-group">
                <label for="description">Description</label>
                <textarea class="form-control" id="description" rows="3" v-model="ticket.description" readonly></textarea>
            </div>
            <br>
        </form>

        <h3>Time Entries</h3>
        <vue-good-table
    :columns="timeEntryColumns"
    :rows="timeEntries"
    :pagination-options="{
        enabled: true,
        mode: 'pages'
    }"
    :search-options="{
        enabled: true,
        skipDiacritics: true
    }"
    />
    <button type="submit" class="btn btn-primary" @click.prevent="submitTicket">Add time entry</button>

    <br>
    <br>

    <h3>Assignment History</h3>
    <vue-good-table
    :columns="assignmentHistoryColumns"
    :rows="assignmentHistory"
    :pagination-options="{
        enabled: true,
        mode: 'pages'
    }"
    :search-options="{
        enabled: true,
        skipDiacritics: true
    }"
    />

    </div>


</template>

<script>
import { VueGoodTable } from 'vue-good-table-next'

export default {
    components: {
        VueGoodTable,
    },  
    data() {
        return {
            ticket: {
                title: 'Install Excel',
                description: 'I need excel installed before a meeting on Friday.',
                category: 'Software',
                subCategory: 'Install',
                priority: 'Low',
                status: "Complete",
                loggedBy: 'John Doe',
                createdAt: '10-01-2024',
                assignedTo: 'James Joseph',
            },
            timeEntryColumns: [
                {
                    label: 'Date',
                    field: 'date',
                },
                {
                    label: 'Time',
                    field: 'time',
                },
                {
                    label: 'Duration',
                    field: 'duration',
                },
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Logged By',
                    field: 'loggedBy',
                },
            ],
            assignmentHistoryColumns: [
                {
                    label: 'Date',
                    field: 'date',
                },
                {
                    label: 'Message',
                    field: 'message',
                },
                {
                    label: 'Actioned By',
                    field: 'actionedBy',
                },
            ],
            timeEntries: [
            {
                    date: '11-01-2024',
                    time: '09:00',
                    duration: '11 minutes',
                    description: 'Completed install, the error was due to a missing dependency which can be downloaded from ....',
                    status: 'Complete',
                    loggedBy: 'James Joseph',
                },
                {
                    date: '10-01-2024',
                    time: '10:00',
                    duration: '36 minutes',
                    description: 'Tried installing but kept getting error 604, decided to do x y and z instead',
                    status: 'Open',
                    loggedBy: 'James Joseph',
                },
            ],
            assignmentHistory: [
                {
                    date: '10-01-2024',
                    message: 'Ticket created',
                    actionedBy: 'John Doe',
                },
                {
                    date: '10-01-2024',
                    message: 'Ticket Assigned to James Joseph',
                    actionedBy: 'Christopher Walker',
                },
            ],
        };
    },
    methods: {
        submitTicket() {
            // TODO: Implement ticket submission logic
            console.log('Ticket submitted:', this.ticket);
        }
    }
};
</script>

<style scoped>
.container {
    margin-top: 20px;
}
</style>
