<template>
    <div class="card plr-5 mt-3">
    <div class="card-header">
      Equipment Requests
    </div>
    <vue-good-table
    :columns="myEquipmentRequestsColumns"
    :rows="myEquipmentRequests"
    :pagination-options="{
     enabled: true,
     mode: 'pages'
    }"
    :search-options="{
     enabled: false,
     skipDiacritics: true,
    }">
    <template #table-row="props">
      <span v-if="props.column.field == 'createdAt'">
        {{moment(props.row.createdAt)}}
      </span>
    </template>
    </vue-good-table>
    </div>
  </template>
  
  <script>
  import 'vue-good-table-next/dist/vue-good-table-next.css'
  import { VueGoodTable } from 'vue-good-table-next'
  import moment from 'moment'
  
  export default {
    name: "MyEquipmentRequests",
    components: {
      VueGoodTable,
    },
    props: {
      myEquipmentRequests: {}
    },
    data() {
      return {
        myEquipmentRequestsColumns: [
          {
            label: 'Date',
            field: 'createdAt',
            sortable: true,
            globalSearchDisabled: true,
            filterOptions: {
              enabled: true
            },
          },
          {
            label: 'Request',
            field: 'request',
            sortable: true,
            globalSearchDisabled: true,
            filterOptions: {
              enabled: true
            },
          },
          {
            label: 'Status',
            field: 'status',
            sortable: true,
            globalSearchDisabled: true,
            filterOptions: {
              enabled: true
            },
          },
        ],
      }
    },
    methods: {
      moment(dateToFormat) {
        return moment.utc(dateToFormat).format('DD/MM/YYYY');
      },
    },
  }
  </script>
  
  
  
  
  
  
  
  