<template lang="html">
    <div class="card plr-5 mt-3">
      <div class="card-header">
        Software
      </div>
      <vue-good-table :columns="columns" :rows="props.software" :pagination-options="{
        enabled: true,
        mode: 'pages'
      }" 
      :search-options="{
        enabled: false,
        skipDiacritics: true,
        }">
  
        <template #table-row="props">
          <span v-if="props.column.field == 'name'">
            <router-link :to="{ name: 'displaysoftware', params: {id: props.row._id} }">
            {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span v-if="props.column.field == 'actions'">
              <button @click="checkIntoStock(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
                title="Revoke license">
                {{ props.formattedRow[props.column.field] }}
                <i class="fa-solid fa-check"></i>
              </button>
          </span>
        </template>
      </vue-good-table>
    </div>
</template>
  
<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import {defineProps, defineEmits} from 'vue'
  
const props = defineProps({
    software: Array,
    pupil: Object
});

const emit = defineEmits(['didRevokeLicense']);

var columns = [
{
    label: 'Manufacturer',
    field: 'manufacturer.name',
    sortable: true,
    globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
    label: 'Software',
    field: 'name',
    sortable: true,
    globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
    label: 'Licence Key',
    field: licenseKeyRow,
    globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
    label: 'Assigned Date',
    field: assignedDateRow,
    globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
    label: 'Actions',
    field: 'actions',
    },
]

function licenseKeyRow(row) {
    //Get the first license key from individualLicenseKeys array and return it's licenseKey property 
    if (row.individualLicenses.length > 0) {
        return row.individualLicenses[0].licenseKey;
    } else {
        return 'No Key';
    }
}

function assignedDateRow(row) {
    if (row.individualLicenses.length > 0) {
        const date = row.individualLicenses[0].assignmentDate;
        //Convert to dd-mm-yyyy and remove time 
        return date.split('T')[0].split('-').reverse().join('-');
    } else {
        return 'No date';
    }
}

async function checkIntoStock(row) {
    //Post to /software/reveoke with the software ID and the license ID
    const confirmed = confirm('Are you sure you want to revoke the license?');
    if (confirmed) {
        await fetch('https://be.pencasolutions.com/software/revoke', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                softwareid: row._id,
                licenseid: row.individualLicenses[0]._id
            })
        });
        emit('didRevokeLicense');
    }
}
</script>
  