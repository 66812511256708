<template>
  <div class="modal fade" id="assignHardwareModal" tabindex="-1" role="dialog" aria-labelledby="assignHardwareModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Pupils</h5>
      </div>
      <div class="modal-body">
        <!-- <div class="card plr-5 mt-3"> -->
        <vue-good-table
        :columns="pupilColumns"
        :rows="pupils"
        :pagination-options="{
         enabled: true,
         perPage: 5,
         mode: 'pages'
        }"
        :search-options="{
         enabled: false,
         skipDiacritics: true,
        }">
        <template #table-row="props">
          <span v-if="props.column.field == 'uniqueCode'">
            <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
            </PupilStatusIndicator>
          </span>
          <span v-if="props.column.field == 'firstName'">
            <!-- <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }"> -->
              {{props.row.firstName}}
            <!-- </router-link> -->
          </span>
          <span v-if="props.column.field == 'lastName'">
            <!-- <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }"> -->
              {{props.row.lastName}}
            <!-- </router-link> -->
          </span>
          <span v-if="props.column.field == 'school.name'">
            <!-- <router-link :to="{ name: 'displayschool', params: {id: props.row.school._id} }"> -->
              {{props.row.school.name}}
            <!-- </router-link> -->
          </span>
          <span v-if="props.column.field == 'actions'">
            <button @click="assignHardwareToPupil(props.row.id)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Assign to pupil" >
              {{props.formattedRow[props.column.field]}}
            <i class="fa-solid fa-arrow-right"></i>
            </button>
          </span>
        </template>
        </vue-good-table>
        </div>
      <!-- </div> -->
      <div class="modal-footer">
        <button class="btn btn-primary" id="closehardwareassignmodal" type="button" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import PupilStatusIndicator from './PupilStatusIndicator.vue'

export default {
  name: 'AssignHardwareModal',
  components: {
    VueGoodTable,
    PupilStatusIndicator
  },
  props: {
    hardwareId: String,
    pupils: [],
    batchHardwareToAssign: {
      type: Array,
      default: null,
    },
  },
  data() {
    return  {
      pupilColumns: [
        {
          label: 'ID',
          field: 'uniqueCode',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'First Name',
          field: 'firstName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Last Name',
          field: 'lastName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'School',
          field: 'school.name',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
    }
  },
  methods: {
    async assignHardwareToPupil(pupilId) {
      let assignHardwareMsg = "Confirm the following:\n" +
        "Double check the condition of equipment\n" +
        'Check what software is installed\n' +
        'Install required software incl Teamviewer\n' +
        'Check to see software is installed correctly\n' +
        'Setup the user if applicable\n' +
        'Set default web page to CENMAC.com\n' +
        'Remove heading and content (msedge)\n'
        if(confirm(assignHardwareMsg)) {
          //Single assignment 
          var endpoint = ""
          var body = ""
          if (this.batchHardwareToAssign.length > 0) {
            endpoint = 'https://be.pencasolutions.com/hardware/batch/assign'
            //Map batch hardwareToAssign to the _id property 
            const hardwareIds = this.batchHardwareToAssign.map(hardware => hardware._id)
            body = JSON.stringify({pupilId, hardwareIds})
          }
          else {
            endpoint = 'https://be.pencasolutions.com/hardware/' + this.hardwareId + '/assign'
            body = JSON.stringify({pupilId})
          }
          await fetch(endpoint, {
              method: 'POST',
              headers: {
                'Content-type': 'application/json',
              },
              credentials: 'include',
              body: body
            })
          //Batch assignment 
          document.getElementById('closehardwareassignmodal').click()
          this.$emit('didassignhardware')

        }
      }
    }
}
</script>
