<template>
<div class="card-header">Change Log</div>
<div class="card-body scrollable-container" style="max-height: 200px; overflow-y: auto;">
  <div v-for="(log, index) in changeLog" :key="index">
    <p><strong>Date:</strong> {{ formatDate(log.date) }}</p>
    <p><strong>Message:</strong> {{ log.message }}</p>
    <hr v-if="index < changeLog.length - 1">
  </div>
</div>
</template>

<script setup>
import { defineProps } from 'vue';
defineProps({
    changeLog: Array
})
function formatDate(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}
</script>