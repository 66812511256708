<template>
  <div class="card">
    <div class="card-header">
    <ul class="nav nav-pills card-header-pills" id="cardContactPill" role="tablist">
        <li class="nav-item">
            <a :class="{ 'nav-link': true, active: activeTab === 'contactPill' }" id="contact-pill" href="#contactPill"
                data-bs-toggle="tab" role="tab" aria-controls="overview" aria-selected="true" @click.prevent="activeTab = 'contactPill'">
                Active Contacts
                <button @click="resetSelectedContact" class="btn btn-sm btn-success text-light" type="button"
                    data-bs-toggle="modal" data-bs-target="#createContactModal">Add New</button>
            </a>
        </li>
        <li class="nav-item">
            <a :class="{ 'nav-link': true, active: activeTab === 'archiveContactPill' }" id="archive-contact-pill" href="#archiveContactPill"
                data-bs-toggle="tab" role="tab" aria-controls="overview" aria-selected="true" @click.prevent="activeTab = 'archiveContactPill'">
                Archived Contacts
            </a>
        </li>
        <li v-if="showPupilsSchoolContacts" class="nav-item">
            <a :class="{ 'nav-link': true, active: activeTab === 'schoolContactPill' }" id="school-contact-pill"
                href="#schoolContactPill" data-bs-toggle="tab" role="tab" aria-controls="example"
                aria-selected="false" @click.prevent="activeTab = 'schoolContactPill'">
                Active School Contacts
            </a>
        </li>
    </ul>
    </div>
    <div class="tab-content" id="cardContactPillContent">
      <div v-for="tab in tabs" :key="tab.id" :class="['tab-pane fade', { 'show active': activeTab === tab.id }]" :id="tab.id" role="tabpanel" :aria-labelledby="tab.label">
      <vue-good-table :columns="columns" :rows="currentRows" :pagination-options="{
          enabled: true,
          mode: 'pages'
        }" :search-options="{
          enabled: false,
          skipDiacritics: true,
        }">
          <template #table-row="props">
            <span v-if="props.column.field == 'phoneNumber'">
              <template v-if='props.row.phoneNumber'>
                <a :href="'tel:' + props.row.phoneNumber" target="_blank">tel: {{ props.row.phoneNumber }}</a>
                <span v-if="props.row.extensionNumber">
                  (ext: {{ props.row.extensionNumber }})
                </span>
              </template>
              <p v-if="props.row.mobileNumber">
                <a :href="'tel:' + props.row.mobileNumber" target="_blank">mob: {{ props.row.mobileNumber }}</a>
              </p>
            </span>
            <span v-else-if="props.column.field == 'name'">
              {{ props.row.name }} 
            </span>
            <span v-else-if="props.column.field == 'email'">
              <a :href="'mailto:' + props.row.email" target="_blank">{{ props.row.email }}</a>
            </span>
            <span v-else-if="props.column.field == 'actions' && props.row.name && activeTab !== 'archiveContactPill'">
              <button v-if="!props.row.isArchived" @click="archiveContact(props.row, false)"
                class="btn btn-datatable btn-icon btn-transparent-dark" title="Archive Contact">
                {{ props.formattedRow[props.column.field] }}
                <i class="fa-solid fa-lock"></i>
              </button>
              <button @click="deleteContact(props.row, false)" class="btn btn-datatable btn-icon btn-transparent-dark"
                title="Delete Contact">
                {{ props.formattedRow[props.column.field] }}
                <i class="fa-solid fa-xmark"></i>
              </button>
              <button @click="setSelectedContact(props.row, false)" data-bs-toggle="modal"
                data-bs-target="#createContactModal" type="submit"
                class="btn btn-datatable btn-icon btn-transparent-dark" title="Edit">
                {{ props.formattedRow[props.column.field] }}
                <i class="fa-solid fa-pencil"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>

  <!-- Contacts Modal -->
  <div class="modal fade" id="createContactModal" tabindex="-1" role="dialog" aria-labelledby="createContactModal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-3 col-lg-4">
              <label for="name">Name*</label>
              <input class="form-control form-control" id="name" input name="name" type="text" placeholder="John Smith"
                v-model="selectedContact.name" required>
            </div>
            <div class="mb-3 col-lg-4">
              <label for="role">Role*</label>
              <input class="form-control form-control" id="role" input name="role" type="text" placeholder="Support"
                v-model="selectedContact.role" required>
            </div>
            <div class="mb-3 col-lg-4">
              <label for="email">Email</label>
              <input class="form-control form-control" id="email" input name="email" type="email"
                placeholder="john.smith@example.com" v-model="selectedContact.email">
            </div>
            <div class="mb-3 col-lg-4">
              <label for="phoneNumber">Phone</label>
              <input class="form-control form-control" id="phoneNumber" input name="phoneNumber" type="text"
                placeholder="02031009321" v-model="selectedContact.phoneNumber">
            </div>
            <div class="mb-3 col-lg-4">
              <label for="phoneExtension">Extension</label>
              <input class="form-control form-control" id="ext" input name="extensionNumber" type="text"
                placeholder="08" v-model="selectedContact.extensionNumber">
            </div>
            <div class="mb-3 col-lg-4">
              <label for="mobileNumber">Mobile</label>
              <input class="form-control form-control" id="mobile" input name="mobileNumber" type="text"
                placeholder="07440394423" v-model="selectedContact.mobileNumber">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="saveContactClicked" class="btn btn-secondary">Save Contact</button>
          <button @click="resetSelectedContact" class="btn btn-primary" type="button"
            data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'


export default {
  emits: ['didupdatecontacts'],
  components: {
    VueGoodTable,
  },
  props: {
    createContactURL: String,
    deleteContactURL: String,
    createSchoolContactURL: String,
    deleteSchoolContactURL: String,
    showPupilsSchoolContacts: Boolean,
    // contacts: [],
    contacts: {
      type: Array,
      required: false,
      default: () => [
        {
          id: "",
          name: "",
          phoneNumber: "",
          extensionNumber: "",
          mobileNumber: "",
          email: "",
          role: ""
        }
      ]
    },
    schoolContacts: {
      type: Array,
      required: false,
      default: () => [
        {
          id: "",
          name: "",
          phoneNumber: "",
          extensionNumber: "",
          mobileNumber: "",
          email: "",
          role: ""
        }
      ]
    },
  },
  data() {
    return {
      activeTab: 'contactPill',
      tabs: [
        { id: 'contactPill', label: 'contact-pill', data: 'contacts' },
        { id: 'archiveContactPill', label: 'archive-contact-pill', data: 'contacts' },
        { id: 'schoolContactPill', label: 'school-contact-pill', data: 'schoolContacts' },
      ],
      isCreatingNewContact: true,
      isEditingSchoolContactFromPupilPage: Boolean,
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Role',
          field: 'role',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Phone & Extension',
          field: 'phoneNumber',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Email',
          field: 'email',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
        }
      ],
      selectedContact: {
        id: "",
        name: "",
        phoneNumber: "",
        extensionNumber: "",
        mobileNumber: "",
        email: "",
        role: "",
      },
    }
  },
  computed: {
    title() {
      return this.isCreatingNewContact == true ? "Create Contact" : "Edit Contact"
    },
    currentRows() {
      //This gets the row data filtered for each tab 
      const activeTabData = this.tabs.find(tab => tab.id === this.activeTab).data;
      switch (this.activeTab) {
        case 'archiveContactPill':
          return this[activeTabData].filter(contact => contact.isArchived === true)
        default:
        return this[activeTabData].filter(contact => contact.isArchived === false)
      }
    },
  },
  methods: {
    resetSelectedContact() {
      this.isCreatingNewContact = true
      this.selectedContact.id = ""
      this.selectedContact.name = ""
      this.selectedContact.phoneNumber = ""
      this.selectedContact.extensionNumber = ""
      this.selectedContact.mobileNumber = ""
      this.selectedContact.email = ""
      this.selectedContact.role = ""
    },

    setSelectedContact(selectedContact, isEditingSchoolContactFromPupilPage) {
      this.isCreatingNewContact = false
      this.isEditingSchoolContactFromPupilPage = isEditingSchoolContactFromPupilPage
      // Setting values this way as setting them directly to the contact uses a pointer/reference instead of by value
      for (let key in selectedContact) {
        this.selectedContact[key] = selectedContact[key]
      }
    },

    async saveContactClicked() {
      var fetchMethod = (this.isCreatingNewContact === true) ? 'POST' : 'PATCH';
      var url = (this.isCreatingNewContact === true) ? this.createContactURL : this.createContactURL + this.selectedContact.id

      // If this is the school display tab on pupils, modify the URL to use school endpoint instead of pupil for patching.
      if (this.isEditingSchoolContactFromPupilPage && fetchMethod == "PATCH") {
        url = this.createSchoolContactURL + this.selectedContact.id
      }
      await fetch(url, {
        method: fetchMethod,
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(this.selectedContact)
      })
      this.resetSelectedContact()
      await this.$emit('didupdatecontacts')
      document.getElementById('createContactModal').click();
    },

    async deleteContact(contactRow, isForSchoolDisplay) {
      var deleteUrl = isForSchoolDisplay ? this.deleteSchoolContactURL + '/' + contactRow.id : this.deleteContactURL + '/' + contactRow.id
      if (confirm("Are you sure you want to delete" + ' ' + contactRow.name)) {
        await fetch(deleteUrl, {
          method: "DELETE",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        })
        await this.$emit('didupdatecontacts')
      }
    },

    async archiveContact(contactRow, isForSchoolDisplay) {
      var url = this.createContactURL + contactRow.id + "/archive"
      if (isForSchoolDisplay) {
        url = this.createSchoolContactURL + contactRow.id + "/archive"
      }

      // Open a modal or form for creating a new diversity
      const archiveReason = prompt("Enter the reason for archival");
      if (archiveReason) {
        // Make an API call to create the new diversity
        const body = {
          'archiveReason': archiveReason
        }
        await fetch(url, {
          method: "PATCH",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        await this.$emit('didupdatecontacts')
      }
    }
  }
}
</script>