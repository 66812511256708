<template>
  <h1>Schools</h1>
  <hr>
  <div class="schools">
    <!-- <Table :columns='columns' :rows='rows' />
   -->
   <vue-good-table
  :columns="columns"
  :rows="rows"
  :pagination-options="{
    enabled: true,
    mode: 'pages'
  }"
  :search-options="{
    enabled: false,
    skipDiacritics: true,
  }">

  <template #table-row="props">
    <span v-if="props.column.field == 'name'">
      <router-link :to="{ name: 'displayschool', params: {id: props.row.id} }">
        {{props.formattedRow[props.column.field]}}
      </router-link>
    </span>
    <span v-else-if="props.column.field == 'localauthority'">
      <router-link :to="{ name: 'displayauthority', params: {id: props.row.localAuthorityId} }">
        {{props.formattedRow[props.column.field]}}
      </router-link>
    </span>
    <span v-else-if="props.column.field == 'actions'">
      <button class="btn btn-datatable btn-icon btn-transparent-dark" @click="deleteSchool(props.row)">
        <i class="fa-solid fa-trash"></i>
      </button>
      <!-- <button class="btn btn-datatable btn-icon btn-transparent-dark" onClick="return confirm('Are you sure you wish to delete this school?');"><i data-feather="trash-2"></i></button> -->
      <!-- {{props.formattedRow[props.column.field]}} -->
    </span>
    <span v-else>
      {{props.formattedRow[props.column.field]}}
    </span>
  </template>
</vue-good-table>
</div>

  <router-link :to="{ name: 'createschool'}">
    <div class="card mb4 mt-3 col-lg-2">

    <button class="btn btn-primary">Create School</button>
  </div>

  </router-link>

</template>

<script>
// import Table from '@/components/Table.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'

export default {
  name: 'ListSchools',
  emits: ['updatepagetitle'],
  data() {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'LocalAuthority',
          field: 'localauthority',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Phase',
          field: 'phase',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
        }
      ],
      rows: []
    }
  },
  components: {
    VueGoodTable,
  },
  async created() {
    this.$emit('updatepagetitle', 'Schools')
    this.fetchSchools()

  },
  methods: {
    async fetchSchools() {
      const res = await fetch('https://be.pencasolutions.com/schools/', {
        method: "GET",
        credentials: 'include',
      })
      const json = await res.json()
      this.rows = json.map(school => (
        {'id': school._id, 'name': school.name, 'localAuthorityId': school.localAuthority._id, 'localauthority': school.localAuthority.name, 'phase': school.phase }
      ))
    },
    async deleteSchool(schoolRow) {
      if(confirm("Are you sure you want to delete " + schoolRow.name)) {
        const res = await fetch('https://be.pencasolutions.com/schools/' + schoolRow.id, {
          method: 'DELETE',
          credentials: 'include',
        })
        if (res.status === 200) {
          this.fetchSchools()
        }
      }
    },
    async createSchool() {

    },
  }
}
</script>
