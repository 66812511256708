<template>
  <h3>Updated</h3>
  <ContactsTable v-if="pupilContacts.length > 0" :contacts="pupilContacts" />

</template>
<script setup>
import { ref, onMounted } from 'vue'
import ContactsTable from '@/components/ContactsTable.vue'

var pupilContacts = ref([]) 
var schoolContacts = ref([])
var authorityContacts = ref([])


//Async onMounted lifecycle hook
onMounted(() => {
  fetchContacts()
})

//Fetch contacts from API endpoint using async await 
async function fetchContacts() {
    const response = await fetch('https://be.pencasolutions.com/contacts', { credentials: 'include' })
    const json = await response.json()
    pupilContacts.value = json.pupil
    schoolContacts.value = json.school
    authorityContacts.value = json.authority
}


</script>