<template>
    <h3>My Tickets</h3>
    <div>
        <vue-good-table 
        :columns="columns" 
        :rows="tickets" 
        :row-class="getRowClass" 
        :search-options="{ enabled: true }"
        />
    </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table-next'

export default {
    components: {
        VueGoodTable,
    },
    data() {
        return {
            columns: [
                {
                    label: 'Created',
                    field: 'created',
                },
                {
                    label: 'Title',
                    field: 'title',
                },
                {
                    label: 'Category',
                    field: 'category',
                },
                {
                    label: 'Sub Category',
                    field: 'subcategory',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
            ],
            tickets: [
                {
                    created: '2024-01-01',
                    title: 'Install Excel',
                    category: 'Software',
                    subcategory: 'Install',
                    status: 'Open',
                },
                {
                    created: '2023-12-15',
                    title: 'Mouse not working',
                    category: 'Hardware',
                    subcategory: 'Replacement',
                    status: 'Response Required',
                },
                {
                    created: '2023-11-03',
                    title: 'Screen issue',
                    category: 'Hardware',
                    subcategory: 'Repair',
                    status: 'Resolved',
                },
            ],
        };
    },
    methods: {
        getRowClass(row) {
            if (row.status === 'Resolved') {
                return 'resolved-row';
            } else if (row.status === 'Open') {
                return 'open-row';
            } else if (row.status === 'Response Required') {
                return 'response-required-row';
            }
            return '';
        },
    },
};
</script>

<style scoped>
.resolved-row {
    background-color: lightgreen;
}

.open-row {
    background-color: lightyellow;
}

.response-required-row {
    background-color: lightpink;
}
</style>
