<template lang="html">
  <form @submit.prevent="submit" method="POST">
    <div class="card mb4 pl-5 mt-3">
      <div class="card-header">Pupil Information</div>
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-lg-4">
            <label for="firstName">First Name *</label>
            <input class="form-control form-control" id="firstName" name="firstName" type="text" required>
          </div>
          <div class="mb-3 col-lg-4">
            <label for="middleName">Middle Name</label>
            <input class="form-control form-control" id="middleName" name="middleName" type="text">
          </div>
          <div class="mb-3 col-lg-4">
            <label for="lastName">Last Name *</label>
            <input class="form-control form-control" id="lastName" name="lastName" type="text" required>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-lg-4">
            <label for="school">School</label>
            <select id="schoolId" input name="schoolId" class="form-control">
              <option v-for="school in schools" :value="school._id" :key="school">
                {{ school.name }}
              </option>
            </select>
          </div>
          <div class="mb-3 col-lg-4">
            <label for="localAuthority">Local Authority</label>
            <select id="localAuthorityId" input name="localAuthorityId" class="form-control">
              <option v-for="authority in localAuthorities" :value="authority._id" :key="authority"
                v-bind:selected="index === 0 ? false : true">
                {{ authority.name }}
              </option>
            </select>
          </div>
          <div class="mb-3 col-lg-4">
            <label for="gender">Gender</label>
            <select id="gender" input name="gender" class="form-control">
              <option v-for="gender in genders" :value="gender" :key="gender">
                {{ gender }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-lg-3">
            <label for="phase">Phase</label>
            <select id="phase" input name="phase" class="form-control">
              <option v-for="phase in phases" :value="phase" :key="phase">
                {{ phase }}
              </option>
            </select>
          </div>
          <div class="mb-3 col-lg-3">
            <label for="category">Category</label>
            <select id="inputCategory" input name="category" class="form-control">
              <option v-for="category in categories" :value="category" :key="category">
                {{ category }}
              </option>
            </select>
          </div>
          <div class="mb-3 col-lg-3">
            <label class="form-check-label" for="paysInvoicesDirectly">AAC</label>
            <select id="aac" input name="aac" class="form-control">
              <option value="yes">Yes</option>
              <option value="yeshub">Yes (Hub)</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="mb-3 col-lg-3">
            <label class="form-check-label" for="ehcp">EHCP</label>
            <select id="ehcp" input name="ehcp" class="form-control">
              <option value=true>Yes</option>
              <option value=false>No</option>
            </select>
          </div>
          <div class="row">
            <div class="mb-3 col-lg-4">
              <label for="yearGroup">Year Group</label>
              <select id="yearGroup" input name="yearGroup" class="form-control">
                <option v-for="yearGroup in yearGroups" :value="yearGroup" :key="yearGroup">
                  {{ yearGroup }}
                </option>
              </select>


            </div>
            <div class="mb-4 col-lg-4">
              <label for="dob">Date of Birth</label>
              <input id="dob" input name="dob" class="form-control" type="date" required />
            </div>
            <div class="mb-4 col-lg-4">
              <label for="referralDate">Referral Date</label>
              <input id="referralDate" input name="referralDate" class="form-control" type="date" required />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow mb-4 pl-5 mt-3">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Languages</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <span class="align-bottom col-lg-4" v-for="language in languages" :key="language">
            <div class="mb-4 ml-5 col-lg-4">
              <input type="checkbox" :value="language" :key="language" v-model="selectedLanguages">
              <span class="checkbox-label"> {{ language }} </span>
            </div>
          </span>
          <div class="col-lg-6">
            <p>Specify other language:
              <input class="form-control form-control" id="otherlang" name="otherlang" type="text">
            </p>
          </div>

        </div>
      </div>
    </div>

    <div class="card shadow mb-4 pl-5 mt-3">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Diversities
          <span v-if="selectedDiversities.length">
            - <span v-for="(diversity, index) in selectedDiversities" :key="index" @click="didSelectDiversity(diversity)"
              class="diversity-clickable" :class="{ 'comma-separator': index !== 0 }">
              {{ diversity.name }}<span v-if="index !== selectedDiversities.length - 1">, </span>
            </span>
          </span>
        </h6>
      </div>
      <!-- <span v-for="diversity in selectedDiversities" :key="diversity">
            {{ diversity.name }} <br>
          </span> -->
      <Diversities @didSelectDiversity="didSelectDiversity" :diversities="diversities" />
    </div>

    <CreateAddressInfo :isCreatingNonPupilAddress="false" />

    <button class="btn btn-primary">Create Pupil</button>
  </form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import CreateAddressInfo from '../../components/AddressInfo/CreateAddressInfo'
import Diversities from '../../components/Diversities'
import router from '../../router'

let localAuthorities = ref([
  { name: "", _id: "" }
])
let schools = ref([])
let phases = ref([])
let genders = ref([])
let categories = ref([])
let yearGroups = ref([])
let languages = ref([])
let diversities = ref([])
let selectedLanguages = ref([])
let selectedDiversities = ref([])

onMounted(async () => {
  await fetchCreatePupilData()
})
async function fetchCreatePupilData() {
  let res = await fetch('https://be.pencasolutions.com/pupils/createreq', {
    credentials: 'include'
  })
  let json = await res.json()
  localAuthorities.value = json.localAuthorities
  phases.value = json.phases
  yearGroups.value = json.yearGroups
  schools.value = json.schools
  genders.value = json.genders
  categories.value = json.categories
  languages.value = json.languages
  diversities.value = json.diversities
}

async function submit(event) {

  var{ firstName, middleName, lastName, schoolId, localAuthorityId, gender, phase, category, aac, ehcp, yearGroup, dob, referralDate } = Object.fromEntries(new FormData(event.target))
  let { addressLine1, addressLine2, contactNumber, contactNumber2, city, postCode, county } = Object.fromEntries(new FormData(event.target))
  
  let address = {
    'firstLine': addressLine1,
    'secondLine': addressLine2,
    'primaryPhone': contactNumber,
    'secondaryPhone': contactNumber2,
    city, postCode, county
  }

  let diversities = selectedDiversities.value.map(diversity => (diversity._id))

  var languages = selectedLanguages.value
  let otherLang = document.getElementById('otherlang').value
  if (otherLang != "" && otherLang != " ") {
    languages.push(otherLang)
  }

  //Converting to booleans as the event target see's them as strings
  let EHCP = (ehcp === "true");

  var aacHub = false 
  if (aac === "yeshub") {
    aac = "yes"
    aacHub = true
  }

  let newPupil = {
    firstName, middleName, lastName, schoolId, localAuthorityId, gender, phase, category, EHCP, aac, aacHub, yearGroup, dob, referralDate, address, diversities, languages
  }

  let res = await fetch('https://be.pencasolutions.com/pupils/', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(newPupil)
  })
  let resp = await res.json()
  document.getElementById('firstName').value = ''
  document.getElementById('middleName').value = ''
  document.getElementById('lastName').value = ''

  router.push({ name: 'displaypupil', params: { id: resp } })
}

function didSelectDiversity(diversity) {
  //Add diversity or remove if it already exists (by ID)
  selectedDiversities.value.indexOf(diversity) === -1 
  ? selectedDiversities.value.push(diversity) 
  : selectedDiversities.value.splice(selectedDiversities.value.indexOf(diversity), 1);
}
</script>
