<template>
    <div>
        <h1>{{ software.name }}</h1>
        <hr>
        <button @click="addVolLicense()" class="btn btn-sm btn-info text-light" disabled>Add Volume License</button>
        <button @click="showAddIndividualLicensemodalModal()" class="btn btn-sm btn-warning text-light">Add Single License</button>
        <br>
        <br>
        <h3>Volume Licenses</h3>
        <vue-good-table :columns="individualColumns" :rows=[]></vue-good-table>
        <br>
        <!-- <vue-good-table :columns="volColumns" :rows="volumeLicenses"></vue-good-table> -->
        <h3>Individual Licenses</h3>
        <vue-good-table :columns="individualColumns" :rows="softwareLicenses">
        <template #table-row="props">
            <span v-if="props.column.field == 'purchaseDate'">
                {{ momentDDMMYYYY(props.row.purchaseDate)}}
            </span>
            <span v-if="props.column.field == 'price'">
                £{{ props.row.price.toFixed(2) }}
            </span>
            <span v-if="props.column.field == 'pupil.name'">
                <router-link v-if="props.row.pupil" :to="{ name: 'displaypupil', params: {id: props.row.pupil._id} }">
                    {{ props.row.pupil.name }}
                </router-link>
            </span>
            <span v-if="props.column.field == 'actions'">

                <button v-if="!props.row.pupil" @click="showAssignModal(props.row._id)" class="btn btn-datatable btn-icon btn-transparent-dark"
                    title="Assign License">
                    {{ props.formattedRow[props.column.field] }}
                    <i class="fa-solid fa-arrow-right"></i>
                </button>

                <button v-if="props.row.pupil" @click="revokeLicense(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
                    title="Revoke License">
                    {{ props.formattedRow[props.column.field] }}
                    <i class="fa-solid fa-check"></i>
                </button>

                <button v-if="!props.row.pupil" @click="deleteLicense(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
                    title="Delete License">
                    {{ props.formattedRow[props.column.field] }}
                    <i class="fa-solid fa-xmark" style="color: red;"></i>
                </button>
            </span>
        </template>
        </vue-good-table>
        <!-- Add Individual License Modal -->
        <button hidden id="showAddIndividualLicensemodalBtn" data-bs-toggle="modal" data-bs-target="#addindividuallicense" style="display: none"></button>
        <div class="modal fade" id="addindividuallicense" tabindex="-1" role="dialog" aria-labelledby="addindividuallicense" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>Add Individual License</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <label for="purchaseDate">Purchase Date</label>
                                <input type="date" id="purchaseDate" v-model="purchaseDate" required>
                            </div>
                            <div class="col-6">
                                <label for="supplierName">Supplier</label>
                        <select id="supplierName" v-model="supplier">
                            <option v-for="supplier in suppliers" :key="supplier" :value="supplier">{{ supplier }}</option>
                        </select>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-6">
                                <label for="price">Price</label>
                                <input type="number" id="price" v-model="price" required>
                            </div>
                            <div class="col-6">
                                <label for="invoiceReference">Invoice Reference </label>
                        <input type="text" id="invoiceReference" v-model="invoiceReference" required>
                            </div>
                        </div>
                        <br>
                        <label for="licenseKey">License Key</label>
                        <input type="text" id="licenseKey" v-model="licenseKey" required>

                        <br>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" @click="addIndividualLicense()">Add</button>
                        <button class="btn btn-primary" id="closeindividualbtn" type="button" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button id="assignLicenseModalBtn" class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#assignLicenseModal" style="display: none"></button>
    <AssignSwModal v-if="shouldShowAssignModal" :softwareid="this.software._id" :pupils="pupils" :licenseid="this.selectedLicenseId"
    @didAssignSw="didAssignSw" />

</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import AssignSwModal from '../../components/AssignSoftwareModal.vue'
import moment from 'moment'

export default {
    name: 'DisplaySoftware',
    components: {
    VueGoodTable,
    AssignSwModal
},
    async mounted() {
        await this.getSoftware()
        await this.fetchPupils()
        await this.fetchManufacturersAndSuppliers()
    },
    data() {
        return {
            software: {},
            selectedLicenseId: String,
            pupils: [],
            shouldShowAssignModal: false,
            //Values to create a new license 
            purchaseDate: '',
            price: '',
            invoiceReference: '',
            licenseKey: '',
            supplier: '',
            suppliers: [],
            isLoaded: false,
            volColumns: [
            {
                    label: 'Manufacturer',
                    field: 'manufacturer',
                    filterOptions: {
                        enabled: true
                    },
                },
                {
                    label: 'Name',
                    field: 'name',
                    filterOptions: {
                        enabled: true
                    },
                },
                {
                    label: 'Volume Licenses',
                    field: 'volumeLicenses',
                },
                {
                    label: 'Individual Licenses',
                    field: 'individualLicenses',
                },
                {
                    label: 'Cost',
                    field: 'cost',
                },
                {
                    label: 'Actions',
                    field: 'actions',
                }
            ],
            individualColumns: [
                {
                    label: 'License Key',
                    field: 'licenseKey',
                    filterOptions: {
                        enabled: true
                    },
                },
                {
                    label: 'Supplier',
                    field: 'supplier.name',
                    // filterOptions: {
                    //     enabled: true
                    // },
                },
                {
                    label: 'Purchase Date',
                    field: 'purchaseDate',
                },
                {
                    label: 'Invoice Reference',
                    field: 'invoiceReference',
                },
                {
                    label: 'Price',
                    field: 'price',
                },
                {
                    label: 'Assignment Status',
                    field: 'status',
                },
                {
                    label: 'Assigned To',
                    field: 'pupil.name',    
                },
                {
                    label: 'Actions',
                    field: 'actions',
                },
            ],
            volumeLicenses: [],
            softwareLicenses: []
        }
    },
    methods: {
        async getSoftware() {
            const urlParts = window.location.pathname.split('/');
            const lastParameter = urlParts[urlParts.length - 1];

            const res = await fetch(`https://be.pencasolutions.com/software/${lastParameter}`, {
                method: "GET",
                credentials: 'include',
            })        
            let json = await res.json()
            this.software = json
            this.softwareLicenses = json.individualLicenses;
            this.isLoaded = true
        },
        async fetchPupils() {
            let res = await fetch('https://be.pencasolutions.com/pupils/', {
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                },
                credentials: 'include',
            })

            const json = await res.json()
            this.pupils = json['activePupils'].map(pupil => (
                {
                'id': pupil._id,
                'uniqueCode': pupil.uniqueCode,
                'firstName': pupil.firstName,
                'lastName': pupil.lastName,
                'year': pupil.yearGroup,
                'category': pupil.category,
                'teacher': pupil.teacher,
                'aac': pupil.aac,
                'lastReviewDate': pupil.lastReviewDate,
                'school': pupil.school,
                'status': pupil.status,
                'actionStatus': pupil.actionStatus
                }
            ))
            this.pupilsLoaded = true
        },
        async showAddIndividualLicensemodalModal() {
            document.getElementById('showAddIndividualLicensemodalBtn').click()
        },
        async addVolLicense() {
            let res = await fetch('https://be.pencasolutions.com/software/' + this.software._id + '/volumeLicense', {
                method: "POST",
                credentials: 'include',
            })
            let json = await res.json()
            this.volumeLicenses.push(json)
        },
        async addIndividualLicense() {
            await fetch('https://be.pencasolutions.com/software/' + this.software._id , {
                method: "POST",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    purchaseDate: this.purchaseDate,
                                    price: parseFloat(this.price),
                                    invoiceReference: this.invoiceReference,
                                    licenseKey: this.licenseKey,
                                    supplier: { name: this.supplier, _id: "65c19600fb4a8acc05f2c0ae" }
                })
            })
            await this.getSoftware()
            document.getElementById('closeindividualbtn').click()
        },
        showAssignModal(licenseId) {
            this.selectedLicenseId = licenseId
            this.shouldShowAssignModal = true
            document.getElementById('assignLicenseModalBtn').click()
        },
        cancelAddIndividualLicense() {
            this.purchaseDate = ''
            this.price = ''
            this.invoiceReference = ''
            this.licenseKey = ''
            this.supplier = {}
        },
        async revokeLicense(license) {
            let comformationMessage = "Are you sure you want to revoke?"
            if (confirm(comformationMessage)) {
            await fetch('https://be.pencasolutions.com/software/revoke', {
                method: "POST",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    softwareid: this.software._id,
                    licenseid: license._id
                })
            })
            await this.getSoftware()
        }
        },
        async deleteLicense(license) {
            let comformationMessage = "Are you sure you want to delete?"
            if (confirm(comformationMessage)) {
                await fetch('https://be.pencasolutions.com/software/', {
                method: "DELETE",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    softwareid: this.software._id,
                    licenseid: license._id
                })
            })
            await this.getSoftware()
            }
        },
        async didAssignSw() {
            this.shouldShowAssignModal = false
            await this.getSoftware()
        },
        async fetchManufacturersAndSuppliers() {
            const manufacturerSupplierResponse = await fetch("https://be.pencasolutions.com/hardware/manufacturers-suppliers", {
                method: "GET",
                credentials: 'include',
                });
                const data = await manufacturerSupplierResponse.json();
                this.suppliers = [...data["suppliers"]];
        },
        momentDDMMYYYY (dateToFormat) {
            let date = moment.utc(dateToFormat);
            if (dateToFormat == null || !date.isValid()) {
                return "-";
            }
            return moment.utc(dateToFormat).format('DD/MM/YYYY');
        },
    },  
}
</script>