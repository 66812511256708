<template lang="html">
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6">
    <div class="card plr-5 mt-3">
      <div class="card-header">
        Diversities
        <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#assignDiversity">+</button>
      </div>
      <div class="card-body">
        <p v-for="diversity in pupilDiversities" :value="diversity" :key="diversity">
          {{diversity.name}}
          <span @click="deleteDiversityClicked(diversity)"><i class="fa-solid fa-trash"></i></span>
        <br>
        </p>
      </div>
     </div>
  </div>
  <!-- Assign Diversity Modal -->
  <div class="modal fade" id="assignDiversity" tabindex="-1" role="dialog" aria-labelledby="assignDiversity" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Diversities</h5>
        <span v-if="isEditingDiversityName">
          <button type="button" class="btn btn-sm btn-primary" @click="cancelEditingDiversty()">Cancel Editing</button>
        </span>
        <span v-else>
          <button type="button" class="btn btn-sm btn-success" @click="createDiversity()">Create New Diversity</button>
          <button type="button" class="btn btn-sm btn-primary" @click="updateDiversityClicked()">Update Diversity</button>
        </span>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12">
        <span v-if="isEditingDiversityName">
          <input class="form-control form-control" id="diversityNameInput" name="diversityNameInput" type="text" required v-model="selectedDiversityName">
        </span>
        <span v-else>
          <select id="diversityId" input name="diversityId" class="form-control">
           <option v-for="diversity in allDiversities" :value="diversity._id" :key="diversity">
                {{ diversity.name }}
           </option>
         </select>
        </span>
        </div>
        </div>
        <div class="modal-footer">
          <span v-if="isEditingDiversityName">
            <button @click="patchDiversity()" class="btn btn-secondary">Update Diversity</button>
          </span>
          <span v-else>
            <button @click="assignDiversity()" class="btn btn-success">Assign Diversity</button>
          </span>
          <button class="btn btn-primary" id="closediversitymodal" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

    <div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pupilDiversities: [],
      allDiversities: [],
      divesityToAdd: "",
      isEditingDiversityName: false,
      selectedDiversityName: "",
      selectedDivserityId: ""
    }
  },
  created() {
    this.fetchDiversitiesForPupil()
    this.fetchAllDiversities()
  },
  methods: {
    async reload() {
      await this.fetchDiversitiesForPupil()
      await this.fetchAllDiversities()
    },
    async fetchDiversitiesForPupil() {
      const res = await fetch('https://be.pencasolutions.com/pupils/' + this.$route.params.id + '/diversities', {
          credentials: 'include',
      })
      this.pupilDiversities = await res.json()
    },
    async fetchAllDiversities() {
      const res = await fetch('https://be.pencasolutions.com/pupils/diversities', {
        credentials: 'include',
      })
      this.allDiversities = await res.json()
    },
    async assignDiversity() {
      const body = {
        'diversityId': document.getElementById('diversityId').value
      }

      await fetch('https://be.pencasolutions.com/pupils/' + this.$route.params.id + '/diversities', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      })
      document.getElementById('closediversitymodal').click()
      this.reload()
    },
    async createDiversity() {
      // Open a modal or form for creating a new diversity
      const name = prompt("Enter the name of the new diversity:");
      if (name) {
        // Make an API call to create the new diversity
        const body = {
          'name': name
        }
        try {
          const res = await fetch('https://be.pencasolutions.com/pupils/diversities', {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(body),
          })
          let newDiversity = await res.json()
          //Find where to insert the new diversity in the array
          let i;
          for (i = 0; i < this.allDiversities.length; i++) {
            if (this.allDiversities[i].name > newDiversity.name) {
              break;
            }
          }
          this.allDiversities.splice(i, 0, newDiversity);
        } catch (error) {
          //Handle error
        }
      }
    },
    updateDiversityClicked() {
      this.isEditingDiversityName = true 
      var selectElement = document.getElementById('diversityId');
      this.selectedDivserityId = selectElement.value;
      this.selectedDiversityName = selectElement.options[selectElement.selectedIndex].textContent;
    },

    async patchDiversity() {
      let existingName = ""
      let newName = ""
      if (confirm("Are you sure you want to rename " + existingName + " to " + newName + "?")) {
        let resp = await fetch('https://be.pencasolutions.com/pupils/' + this.$route.params.id + '/diversities/' + this.selectedDivserityId, {
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ name: this.selectedDiversityName }),
        })
        console.log(resp.status)
        await this.reload()
      }
      this.cancelEditingDiversty()
    },

    cancelEditingDiversty() {
      this.isEditingDiversityName = false 
    },

    async deleteDiversityClicked(diversity) {
      const diversityToRemove = {
        'diversityIdToRemove': diversity._id
      }

      if(confirm("Are you sure you want to remove " + diversity.name +"?" )){
        await fetch('https://be.pencasolutions.com/pupils/' + this.$route.params.id + '/diversities', {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(diversityToRemove),
        })
      }
      this.reload()
    }
  }
}
</script>
