<template>
    <div class="modal fade" id="createInteraction" tabindex="-1" role="dialog" aria-labelledby="createInteraction" ref="myModalEl"
        aria-hidden="true">
        <p>{{ props.targetType }}</p>
        <p>{{ props.pupilIds }}</p>
        <div :class="getModalSize" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 v-if="props.isEditingExistingInteraction" class="modal-title">Update Interaction</h5>
                        <h5 v-else class="modal-title">Create Interaction</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" @click="stopEditingInteraction()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="mb-4 col-lg-12">
                                <label for="interactionDate">Interaction Date</label>
                                <input v-if="props.isEditingExistingInteraction" id="interactionDate" input
                                    name="interactionDate" v-model.lazy="interactionDate"
                                    class="form-control" type="date" />
                                <input v-else id="interactionDate" input name="interactionDate" class="form-control"
                                    type="date" v-model.lazy="interactionDate" required/>
                            </div>

                            <div class="mb-3 col-lg-6 d-flex align-items-center">
                                <label for="isSignificantCheck" class="me-3">Significant Interaction</label>
                                <div class="form-check">
                                    <span v-if="props.isEditingExistingInteraction">
                                        <span v-if="props.interactionToEdit.isSignificantInteraction == true">
                                            <input class="form-check-input" type="checkbox" id="isSignificantCheck" checked>
                                        </span>
                                        <span v-else>
                                            <input class="form-check-input" type="checkbox" id="isSignificantCheck">
                                        </span>
                                    </span>
                                    <span v-else>
                                        <input class="form-check-input" type="checkbox" id="isSignificantCheck">
                                    </span>
                                </div>
                            </div>
                            <div class="row">

                                <div class="mb-3 col-lg-6">
                                    <label for="interactionType">Interaction Type</label>

                                    <select id="interactionType" input name="interactionType" class="form-control">
                                        <option v-for="interaction in interactionTypes" :value="interaction.interactionType"
                                            :key="interaction" :selected="props.interactionToEdit.interactionType == interaction ? true : false">
                                            {{ interaction }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="targetType == 'pupil'" class="mb-3 col-lg-6">
                                    <label for="actionStatus">Action Status</label>

                                    <select id="actionStatusSelector" input name="actionStatusSelector" class="form-control" v-model="selectedActionStatus">
                                    <option v-for="actionStatus in actionStatuses" :value="actionStatus" :key="actionStatus">
                                        {{ actionStatus }}
                                    </option>
                                    </select>
                                </div>

                            </div>

                            <h3>Duration</h3>

                            <div class="mb-4 col-lg-4">
                            <button :class="getButtonClassHalfDay" @click="setInteractionLength('halfDay')">
                                Half Day (3.5 Hours)
                            </button>
                            </div>
                            <div class="mb-4 col-lg-4">
                            <button :class="getButtonClassFullDay" @click="setInteractionLength('fullDay')">
                                Full Day (7 hours)
                            </button>
                            </div>
                            <div class="mb-4 col-lg-4">
                            <button :class="getButtonClassOther" @click="setInteractionLength('other')">
                                Other Amount
                            </button>
                            </div>

                            <div v-if="interactionLength==='other'" class="row">
                                <div class="mb-4 col-lg-6">
                                    <label for="interactionhours">Hours</label>
                                    <select v-model="selectedHours" id="interactionhours" input name="interactionhours" class="form-control">
                                        <option v-for="(hour, index) in hours" :key="index" :value="hour.value" :selected="selectedHours.value == hour.value">
                                        {{ hour.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-4 col-lg-6">
                                    <label for="interactionminutes">Minutes</label>
                                    <select v-model="selectedMinutes" id="interactionminutes" input name="interactionminutes" class="form-control">
                                        <option v-for="(minute, index) in minutes" :key="index" :value="minute.value" :selected="selectedMinutes.value == minute.value">
                                        {{ minute.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-0">
                                <label for="interaction">Interaction</label>
                                <textarea v-if="props.isEditingExistingInteraction" class="form-control form-control"
                                    id="interaction" input name="interaction" rows="10"
                                    v-model.lazy="interactionText" contenteditable="true" @paste="onPaste"
                                    @keydown.enter.prevent="onEnter"></textarea>
                                <textarea v-else class="form-control form-control" id="interaction" input
                                    name="interaction" rows="10" contenteditable="true" v-model.lazy="interactionText" @paste="onPaste"
                                    @keydown.enter.prevent="onEnter"></textarea>
                            </div>

                            <div v-if="targetType == 'school'" class="mb-0">
                                <div v-if="selectedPupils.length > 0">
                                    <h3>Selected Pupils</h3>
                                    <ul class="horizontal-list">
                                        <li v-for="pupil in selectedPupils" :key="pupil._id">
                                            {{ pupil.firstName }} {{ pupil.lastName }}
                                        </li>
                                    </ul>
                                </div>
                                <hr>
                                <h3>Pupils</h3>
                                <vue-good-table v-if="targetType == 'school'" :columns="pupilColumns" :rows="props.schoolPupilRows"
                                    :pagination-options="{
                                    enabled: true,
                                    mode: 'pages',
                                    perPage: 3
                                    }" :search-options="{
                                        enabled: false,
                                        skipDiacritics: true,
                                        keepselectedonsearch: true,
                                        }">
                                    <template #table-row="props">
                                    <span v-if="props.column.field == 'category'
                                        || props.column.field == 'yearGroup'">
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                    <span v-else-if="props.column.field == 'selected'">
                                        <input type="checkbox" :checked="selectedPupils.some(pupil => pupil._id === props.row._id)" @change="updateSelectedPupils(props.row)">
                                    </span>
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                    </template>
                                </vue-good-table>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button v-if="props.isEditingExistingInteraction" class="btn btn-secondary" @click="submitInteraction">Update
                            Interaction</button>
                        <button v-else class="btn btn-secondary" @click="submitInteraction">Create Interaction</button>
                        <button @click="stopEditingInteraction()" class="btn btn-primary" id="closeinteractionmodal"
                            type="button" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
        </div>
    </div>
</template>
<script setup>
import { ref, computed, onMounted, onBeforeUnmount, defineProps, defineEmits, nextTick} from 'vue'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'

let props = defineProps({
    //Gives us access to all the pupils for a school
    schoolPupilRows: Array,
    //These are the pupils that have been selected for the interaction in name, id , local authority format 
    pupilsOnInteraction: Array,

    isEditingExistingInteraction: Boolean,
    interactionToEdit: {
        type: Object,
        default: null
    },
    //Used when creating an interaction for a single pupil
    //Also used editing a school interaction with multiple pupils
    pupilIds: Array,
    targetType: String,
    // These are only needed for the school and authority interactions
    schoolId: {
        type: String,
        default: null
    },
    localAuthorityId: {
        type: String,
        default: null
    },
})

const emit = defineEmits(['updatedInteraction','stopEditingInteraction'])

var interactionLength = ref('halfDay')
let hours = Array.from({length: 7}, (_, i) => ({ label: i, value: i }))
let minutes = Array.from({length: 12}, (_, i) => ({ label: i * 5, value: i * 5 }))
var selectedHours = ref(0)
var selectedMinutes = ref(0)
var selectedActionStatus = ref('OK')

var interactionText = ref("")
var interactionDate = ref("")

var interactionTypes = ref([])
var actionStatuses = ref([])

//Selects pupils for a school interaction
var selectedPupils = ref([])

const myModalEl = ref(null)

// Used for school interactions to list pupils 
const pupilColumns = [
    {
        label: 'Unique Code',
        field: 'uniqueCode',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
        enabled: true
        },
    },
    {
        label: 'First Name',
        field: 'firstName',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
        enabled: true
        },
    },
    {
        label: 'Last Name',
        field: 'lastName',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
        enabled: true
        },
    },
    {
        label: 'Year Group',
        field: 'yearGroup',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
        enabled: true
        },
    },
    {
        label: 'Category',
        field: 'category',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
        enabled: true
        },
    },
    {
        label: 'Selected',
        field: 'selected',
        sortable: true,
    },
]

onMounted(async () => {
    await fetchInteractionTypes()
    await fetchActionStatuses()
    if (props.isEditingExistingInteraction) {
        interactionText.value = props.interactionToEdit.interaction
        interactionDate.value = props.interactionToEdit.interactionDate
        const halfDay = 210
        const fullDay = 420
        if (props.interactionToEdit.durationInMinutes === halfDay) {
            interactionLength.value = 'halfDay'
        } else if (props.interactionToEdit.durationInMinutes === fullDay) {
            interactionLength.value = 'fullDay'
        } else {
            interactionLength.value = 'other'
            let hours = Math.floor(props.interactionToEdit.durationInMinutes / 60)
            selectedHours.value = hours
            let minutes = props.interactionToEdit.durationInMinutes - (hours * 60)
            selectedMinutes.value = minutes
        }
        if (props.targetType === 'school') {
            //Add any pupils from props.pupils (using id property) that exist in the props.schoolPupilRows array and push them to selectedPupils
            props.pupilsOnInteraction.forEach(pupil => {
                let pupilInRows = props.schoolPupilRows.find(row => row._id === pupil._id)
                if (pupilInRows) {
                    updateSelectedPupils(pupilInRows)
                }
            })
        }
    }

    myModalEl.value.addEventListener('hidden.bs.modal', () => {
        emit('stopEditingInteraction')
    })
})

onBeforeUnmount(() => {
    myModalEl.value.removeEventListener('hidden.bs.modal', () => {
        emit('stopEditingInteraction')
    })
})

async function fetchInteractionTypes() {
    var url = ''
    switch (props.targetType) {
        case 'pupil':
            url = 'https://be.pencasolutions.com/pupils/' + props.pupilIds[0] + '/interaction/types'
            break
        case 'school':
            url = 'https://be.pencasolutions.com/schools/' + props.schoolId + '/interaction/types'
            break
        case 'localAuthority':
            url = 'https://be.pencasolutions.com/authorities/' + props.localAuthorityId + '/interaction/types'
            break
    }
    const res = await fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ targetType: props.targetType }),
    })
    interactionTypes.value = await res.json()
}

async function fetchActionStatuses() {
    const res = await fetch('https://be.pencasolutions.com/pupils/actionstatuses', {
        method: "GET",
        credentials: 'include',
    })
    actionStatuses.value = await res.json()
}

async function submitInteraction() {
    var interactionDurationInMins = 0
    switch(interactionLength.value) {
    case 'halfDay':
        interactionDurationInMins = 210
        break
    case 'fullDay':
        interactionDurationInMins = 420
        break
    case 'other':
        var hoursInMinutes = selectedHours.value * 60
        interactionDurationInMins = hoursInMinutes + selectedMinutes.value
      break
    }

    if (interactionDate.value === '') {
        alert('Please enter an interaction date')
        return
    }

    let isSignificantInteraction = false 
    if (props.targetType === 'pupil' || props.targetType === 'school') {
        isSignificantInteraction = document.getElementById('isSignificantCheck').checked
    }

    let payload = {
        interactionType: document.getElementById('interactionType').value,
        interactionDate: interactionDate.value,
        durationInMinutes: interactionDurationInMins,
        interaction: interactionText.value,
        isSignificantInteraction: isSignificantInteraction,
        actionStatus: selectedActionStatus.value,
        targetType: props.targetType,
        pupilIds: props.pupilIds,
    }

    if (props.targetType === 'localAuthority') {
        payload.localAuthorityId = props.localAuthorityId
        payload.pupilIds = []
    }

    if (props.targetType === 'school') {
        payload.schoolId = props.schoolId
        payload.pupilIds = selectedPupils.value.map(pupil => pupil._id)
    }

    if (props.isEditingExistingInteraction) {
        payload.interactionId = props.interactionToEdit._id
    }

    var url = ''
    switch (props.targetType) {
        case 'pupil':
            url = 'https://be.pencasolutions.com/pupils/' + props.pupilIds[0] + '/interaction'
            break
        case 'school':
            url = 'https://be.pencasolutions.com/schools/' + props.schoolId + '/interaction'
            break
        case 'localAuthority':
            url = 'https://be.pencasolutions.com/authorities/' + props.localAuthorityId + '/interaction'
            break
    }
    const submitMethod = props.isEditingExistingInteraction ? 'PATCH' : 'POST'
    // Post the interaction to the server
    let res = await fetch(url, {
        method: submitMethod,
        headers: {
            'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(payload),
    })

    if (await res.status !== 200) {
        alert('There was an error submitting the interaction')
        return
    }
    document.getElementById('closeinteractionmodal').click()
    await nextTick()
    //Remove the listener from the modal
    myModalEl.value.addEventListener('hidden.bs.modal', () => {
        emit('updatedInteraction')
    })
}

const getModalSize = computed(() => {
  return props.targetType === 'school' ? 'modal-dialog modal-lg' : 'modal-dialog modal-m';
});

const getButtonClassHalfDay = computed(() => {
    return interactionLength.value === "halfDay" ? 'btn btn-green' : 'btn btn-outline-teal';
});

const getButtonClassFullDay = computed(() => {
    return interactionLength.value  === "fullDay" ? 'btn btn-green' : 'btn btn-outline-teal';
});

const getButtonClassOther = computed(() => {
    return interactionLength.value === "other" ? 'btn btn-green' : 'btn btn-outline-teal';
});


const setInteractionLength = (type) => {
  interactionLength.value = type;
};

function stopEditingInteraction() {
    //Signal to the parent component that editing has stopped
    emit('stopEditingInteraction')
}

function updateSelectedPupils(selectedPupil) {
    //Get the _id firstname and lastname and append to selected pupils 
    let pupil = {
        _id: selectedPupil._id,
        firstName: selectedPupil.firstName,
        lastName: selectedPupil.lastName
    }

    if (selectedPupils.value.some(p => p._id === pupil._id)) {
        selectedPupils.value = selectedPupils.value.filter(p => p._id !== pupil._id)
    } else {
        selectedPupils.value.push(pupil)
    }
}

function onPaste(event) {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const text = clipboardData.getData('text/plain');
    const formattedText = formatText(text);
    document.execCommand('insertHTML', false, formattedText);
}
function formatText(text) {
    const lines = text.split('\n');
    let formattedText = '';
    let bulletPoint = '•'
    for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line.startsWith(bulletPoint)) {
        let indentedLine = "    " + line
        formattedText += `<div>${indentedLine}</div>`;
    } else {
        formattedText += `<div>${line}</div>`;
    }
    }

    return formattedText;
}

function onEnter(event) {
    var textarea = document.getElementById("interaction");
    var start = textarea.selectionStart;
    var end = textarea.selectionEnd;
    var lines = textarea.value.split("\n");
    var lineIndex = -1;
    var lineStart = 0;
    for (var i = 0; i < lines.length; i++) {
    lineIndex = i;
    if (start <= lineStart + lines[i].length) {
        break;
    }
    lineStart += lines[i].length + 1;
    }
    var line = lines[lineIndex].trim()
    var firstCharacter = (line.length > 0) ? line[0] : "";

    let bulletPoint = '•'
    if (firstCharacter == bulletPoint) {
    if (line.length == 1) {
        // Remove the bullet point
        lines[lineIndex] = "";
        // Insert a new empty line at the same position
        lines.splice(lineIndex, 0, "");
        // Set the text area value to the modified text
        textarea.value = lines.join("\n");
        // Set the cursor position to the start of the new empty line
        textarea.selectionStart = lineStart + lines[lineIndex].length + 1;
        textarea.selectionEnd = textarea.selectionStart;
    } else {
        addNewLine(true)
        console.log(event)
    }
    } else {
    addNewLine(false)
    }

    function addNewLine(withBulletPoint) {
    let indentation = "    ";
    let newLineText = withBulletPoint ? `${indentation}•   ` : "";
    let textAreaText = textarea.value.substring(0, start) + "\n" + newLineText + textarea.value.substring(end);
    textarea.value = textAreaText;
    let newLineIndex = textAreaText.indexOf('\n', start) + 1;
    if (withBulletPoint) {
        newLineIndex += newLineText.length;
    }
    textarea.selectionStart = newLineIndex;
    textarea.selectionEnd = newLineIndex;
    }
}

</script>
<style scoped>
.horizontal-list {
    display: flex;        /* Use flexbox to lay out children inline */
    flex-wrap: wrap;      /* Allows items to wrap onto the next line as needed */
    list-style: none;     /* Removes bullet points from list items */
    padding: 0;           /* Removes padding around the list */
    margin: 0;            /* Removes margin around the list */
}

.horizontal-list li {
    margin-right: 10px;   /* Spacing between items, adjust as necessary */
}
[contenteditable] {
  font-family: Arial, sans-serif;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>