<template lang="html">
  <div v-if="createReq.pupil && createReq.school">

    <PupilInformationHeader v-if="createReq" :createReq="createReq" v-model:startDateTime="startDateTime"
      v-model:completionDateTime="completionDateTime" @update:updateDateValue="updateDateValue" />
    <div class="row">

      <div class="col-lg-12 mb-4 pl-5 mt-3">
        <div class="card shadow mb-4">

          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">SETT Framework – (Student, Environment, Tasks, Tools)</h6>
          </div>

          <div class="card-body">
            <ReviewSection title="Overview" description="Summary of the assessment / review" inputId="overView"
              inputName="overView" :previousReviewValue="createReq?.previousReview?.overView ?? ''" v-model:vModel="overView" />
            <ReviewSection title="EHCP" description="Suggested Provisions & Outcomes" inputId="ehcpOutcomes"
              inputName="ehcpOutcomes" :previousReviewValue="createReq?.previousReview?.ehcpOutcomes ?? ''"
              v-model:vModel="ehcpOutcomes" />
            <ReviewSection title="Student" description="My strengths and interests, what’s working? What’s not working?"
              inputId="student" inputName="student" :previousReviewValue="createReq?.previousReview?.student ?? ''"
              v-model:vModel="student" />
            <ReviewSection title="Environment and Support" description="What does the environment and support need to look like to help me
                      achieve?" inputId="environment" inputName="environment"
              :previousReviewValue="createReq?.previousReview?.environment ?? ''" v-model:vModel="environment" />
            <ReviewSection title="Tasks" description="What I need and would like to be able to do" inputId="tasks"
              inputName="tasks" :previousReviewValue="createReq?.previousReview?.tasks ?? ''" v-model:vModel="tasks" />
            <ReviewSection title="Existing tools and Support"
              description="What are my current abilities, equipment and support available?" inputId="existingTools"
              inputName="existingTools" :previousReviewValue="createReq?.previousReview?.existingTools ?? ''"
              v-model:vModel="existingTools" />
            <ReviewSection title="Possible tools and Support" description="What I need to achieve my goals and potential?"
              inputId="possibleTools" inputName="possibleTools"
              :previousReviewValue="createReq?.previousReview?.possibleTools ?? ''" v-model:vModel="possibleTools" />
            <ReviewSection title="Additional Notes" description="" inputId="notes" inputName="notes"
              :previousReviewValue="createReq?.previousReview?.notes ?? ''" v-model:vModel="notes" />
            <ReviewSection title="Hardware & Software Request" description="" inputId="equipmentRequest"
              inputName="equipmentRequest" :previousReviewValue="createReq?.previousReview?.equipmentRequest ?? ''"
              v-model:vModel="equipmentRequest" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-lg-12 mb-4 pl-5 mt-3">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div class="row">
            <h6 class="m-0 font-weight-bold text-primary">Existing Targets</h6>
          </div>
        </div>

        <div class="card-body">
          <div @submit.prevent>

            <vue-good-table :columns="tableColumns.existingTargetColumns" :rows="existingPupilTargets"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
              }" :search-options="{
  enabled: false,
  skipDiacritics: true,
}">

              <template #table-row="props">
                <span v-if="props.column.field == 'target'" v-on:mouseover="showFullText(props.row.target)">
                  {{ truncatedText(props.row.target) }}
                </span>
                <span v-else-if="props.column.field == 'action'" v-on:mouseover="showFullText(props.row.action)">
                  {{ truncatedText(props.row.action) }}
                </span>
                <span v-else-if="props.column.field == 'targetDate'">
                  {{ formattedDate(props.row.targetDate) }}
                </span>
                <span v-else-if="props.column.field == 'actions'" class="centered">
                  <button @click="toggleCompleteExistingTarget(props.row._id)" type="button" class="btn btn-datatable btnicon btn-transparent-dark">
                      <i v-if="isExistingTargetComplete(props.row._id)" class="fa-solid fa-check"></i>
                      <i v-else class="fa solid fa-xmark"></i>
                  </button>
                </span>
              </template>
            </vue-good-table>
        </div>
        </div>
      </div>
    </div>--> 

    <!-- <div class="col-lg-12 mb-4 pl-5 mt-3">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div class="row">
            <h6 class="m-0 font-weight-bold text-primary">
              New Targets
              <button type="button" class="btn btn-sm btn-success text-light" id="addTarget" data-bs-toggle="modal"
                data-bs-target="#addtargetmodal">+</button>
            </h6>
          </div>
        </div>
        <div class="body">
          <div @submit.prevent>
            <vue-good-table :columns="tableColumns.newTargetColumns" :rows="newPupilTargets" :pagination-options="{
              enabled: true,
              mode: 'pages'
            }" :search-options="{
  enabled: false,
  skipDiacritics: true,
}">

              <template #table-row="props">
                <span v-if="props.column.field == 'target'" v-on:mouseover="showFullText(props.row.target)">
                  {{ truncatedText(props.row.target) }}
                </span>
                <span v-else-if="props.column.field == 'action'" v-on:mouseover="showFullText(props.row.action)">
                  {{ truncatedText(props.row.action) }}
                </span>
                <span v-else-if="props.column.field == 'targetDate'">
                  {{ formattedDate(props.row.targetDate) }}
                </span>
                <span v-else-if="props.column.field == 'actions'">
                  <button type="button" @click="removeNewTarget(props.row.target, props.row.action, props.row.targetDate)"
                    class="btn btn-datatable btnicon btn-transparent-dark" title="Remove Target" id="removeTarget"><i
                      class="fa-solid fa-xmark"></i></button>
                </span>
              </template>
            </vue-good-table>
          </div>


        </div>
      </div>
    </div>--> 
    <button @click="submit()" class="btn btn-secondary">Complete</button>
    <button @click="cancel()" class="btn btn-primary">Cancel</button>
  </div>

  <!-- <CreatePupilTargetModal @didAddTarget="addPupilTarget" /> -->
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
// import { VueGoodTable } from "vue-good-table-next";
import PupilInformationHeader from "../../views/Reviews/PupilInformationHeader.vue";
import ReviewSection from "../../views/Reviews/ReviewSection.vue";
// import CreatePupilTargetModal from "../../components/CreatePupilTargetModal.vue";
import moment from "moment";

export default {
  name: "createreview",
  components: {
    // VueGoodTable,
    // CreatePupilTargetModal,
    PupilInformationHeader,
    ReviewSection
  },
  setup() {
    const router = useRouter();
    const emptyString = ref("");
    const createReq = ref(Object);
    const overView = ref("");
    const ehcpOutcomes = ref("");
    const student = ref("");
    const environment = ref("");
    const tasks = ref("");
    const existingTools = ref("");
    const possibleTools = ref("");
    const notes = ref("");
    const equipmentRequest = ref("");
    const startDateTime = ref("");
    const completionDateTime = ref("");
    const newPupilTargets = ref([]);
    const existingPupilTargets = ref([]);
    const completedPupilTargets = ref([]);
    const tableColumns = reactive({
      existingTargetColumns: [
        createColumn('Target', 'target'),
        createColumn('Action', 'action'),
        createColumn('Target Date', 'targetDate'),
        createColumn('Actions', 'actions'),
      ],
      newTargetColumns: [
        createColumn('Target', 'target'),
        createColumn('Action', 'action'),
        createColumn('Target Date', 'targetDate'),
        createColumn('Actions', 'actions'),
      ],
    });

    onMounted(async () => {
      await getCreateReq();
    });

    async function getCreateReq() {
      const currentPageURL = router.currentRoute.value.fullPath;
      const res = await fetch("https://be.pencasolutions.com" + currentPageURL, {
        credentials: "include",
      });
      createReq.value = await res.json();
      console.log(createReq.value)
      existingPupilTargets.value = createReq.value.allPupilTargets;
    }

    function updateDateValue({ prop, value }) {
      if (prop === 'startDateTime') {
        startDateTime.value = value;
      } else if (prop === 'completionDateTime') {
        completionDateTime.value = value;
      }
    }

    async function submit() {
      if (!startDateTime.value) {
        alert("Start time can't be empty");
        return;
      }
      if (!completionDateTime.value) {
        alert("Completion time can't be empty");
        return;
      }
      const newReview = {
        overView: overView.value,
        ehcpOutcomes: ehcpOutcomes.value,
        student: student.value,
        environment: environment.value,
        tasks: tasks.value,
        existingTools: existingTools.value,
        possibleTools: possibleTools.value,
        notes: notes.value,
        equipmentRequest: equipmentRequest.value,
        startDateTime: startDateTime.value,
        completionDateTime: completionDateTime.value,
        newPupilTargets: newPupilTargets.value,
        completedPupilTargets: completedPupilTargets.value
      };

      const currentPageURL = router.currentRoute.value.fullPath;
      await fetch('https://be.pencasolutions.com' + currentPageURL, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(newReview)
      })
      const pupilURL = currentPageURL.replace("/review", "").replace("/pupils/", "");
      router.push({ name: 'displaypupil', params: { id: pupilURL } })
    }

    function cancel() {
      router.push('/dashboard');
    }

    function addPupilTarget(pupilTarget) {
      newPupilTargets.value.push(pupilTarget);
    }

    function removeNewTarget(pupilTarget, action, pupilTargetDate) {
      if (confirm("Are you sure you wish to remove the target?")) {
        const index = this.newPupilTargets.findIndex(t => t.target === pupilTarget && t.action === action && t.targetDate === pupilTargetDate);
        if (index !== -1) {

          this.newPupilTargets.splice(index, 1);
        }
      }
    }

    function isExistingTargetComplete(targetId) {
      const index = this.completedPupilTargets.findIndex(obj => obj.targetId === targetId); // Find index of object with matching ID
      return index !== -1;
    }

    function toggleCompleteExistingTarget(targetId) {
      const index = this.completedPupilTargets.findIndex(obj => obj.targetId === targetId); // Find index of object with matching ID
      if (index === -1) {
        this.completedPupilTargets.push({ targetId }); // ID not found, so add object with ID property
      } else {
        this.completedPupilTargets.splice(index, 1); // ID found, so remove object at index
      }
    }

    function truncatedText(text) {
      return text.length <= 200 ? text : text.slice(0, 200) + "...";
    }

    function showFullText(text) {
      event.target.title = text;

    }

    function formattedDate(date) {
      return moment(date).format("DD-MM-YYYY");
    }

    // function setTargetStatus(existingPupilTarget) {
    //   const target = this.existingPupilTargets.find(t => t._id === existingPupilTarget._id);
    //   if (target) {
    //     target.isComplete = !target.isComplete;
    //     this.existingPupilTargets = [...this.existingPupilTargets];
    //   }
    // }

    function createColumn(label, field) {
      return {
        label,
        field,
        filterOptions: {
          enabled: true,
        },
      };
    }

    return {
      startDateTime, completionDateTime,
      emptyString, createReq, newPupilTargets, existingPupilTargets, completedPupilTargets, tableColumns, overView,
      ehcpOutcomes, student, environment, tasks, existingTools, possibleTools, notes, equipmentRequest,
      updateDateValue, submit, cancel, addPupilTarget, removeNewTarget,
      truncatedText, showFullText, formattedDate, toggleCompleteExistingTarget, createColumn, isExistingTargetComplete
    };
  },
};
</script>

<style>
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>