<template>
    <div class="card-header">
        <div class="row">
            <div v-show="!props.isAuthoritySecondaryDisplay" :class="`mb-4 ${props.targetType === 'localAuthority' ? 'col-lg-2' : 'col-lg-3'}`">
                <p v-if="props.targetType === 'pupil' || props.targetType === 'school' ">Interactions</p>
                <button id="addInteractionBtn" type="button" data-bs-toggle="modal" data-bs-target="#createInteraction" style="display: none"></button>
                <p class="btn btn-sm btn-success text-light" @click="shouldShowInteractionModal()">Add Interaction</p>
            </div>
            <!-- <div class="mb-4 col-lg-2">
                <button v-if="shouldFilterDates" @click='shouldFilterDates = false'
                    class="btn btn-sm btn-info text-light">Close Date Filter</button>
                <button v-else @click='shouldFilterDates = true' class="btn btn-sm btn-info text-light">Filter
                    Dates</button>
                <br>
                <button v-show="shouldFilterDates" @click="clearDateFilter">Clear date filter</button>
            </div>
            <div v-show="shouldFilterDates" class="mb-4 col-lg-2">
                <label for="filteredStartDate">Start Date</label>
                <input id="filteredStartDate" v-model="filteredStartDate" class="form-control" type="date" required />
            </div>
            <div v-show="shouldFilterDates" class="mb-4 col-lg-2">
                <label for="filteredEndDate">End Date</label>
                <input id="filteredEndDate" v-model="filteredEndDate" class="form-control" type="date"
                    :min="filteredStartDate" required />
            </div>
            <div v-show="shouldFilterDates" class="mb-4 col-lg-2">
                <button @click="filterDates">Filter</button>
            </div> -->
        </div>
    </div>

    <vue-good-table v-if="loaded" :columns="getColumnsForTargetType" :rows="interactions" :pagination-options="{
                enabled: true,
                mode: 'pages'
            }" :search-options="{
                enabled: false,
                skipDiacritics: true,
            }" :sort-options="{
                enabled: true,
                initialSortBy: { field: 'interactionDate', type: 'desc' }
            }">
        <template #table-row="props">
            <span v-if="props.column.field == 'interactionDate'">
                {{ formatDate(props.row.interactionDate) }}
            </span>
            <span v-if="props.column.field == 'durationInMinutes'">
                <span v-if="props.row.durationInMinutes == '210'">
                    {{ props.row.durationInMinutes }} - Half Day
                </span>
                <span v-else-if="props.row.durationInMinutes == '420'">
                    {{ props.row.durationInMinutes }} - Full Day
                </span>
                <span v-else>
                    {{ props.row.durationInMinutes }}
                </span>
            </span> 
            <span v-if="props.column.field == 'pupils'">
                <router-link :to="{ name: 'displaypupil', params: { id: props.row.pupils[0]._id } }">
                    {{ props.row.pupils[0].name }}
                </router-link>
            </span>
            <span v-if="props.column.field == 'isSignificantInteraction'">
                <span v-if="props.row.isSignificantInteraction">
                    <i class="fa-solid fa-check"></i>
                </span>
            </span>
            <span v-if="props.column.field == 'interaction'" v-bind:title="props.row.interaction"
                v-on:mouseover="props.row.interaction">
                {{ props.row.interaction }}
            </span>
            <span v-if="props.column.field == 'actions'">
                <button v-if="targetType" class="btn btn-datatable btn-icon btn-transparent-dark"
                    @click="editInteraction(props.row)">
                    <i class="fa-solid fa-pencil"></i>
                </button>
                <button class="btn btn-datatable btn-icon btn-transparent-dark" @click="deleteInteraction(props.row)">
                    <i class="fa-solid fa-trash"></i>
                </button>
            </span>
        </template>
    </vue-good-table>

<StaffInteractionModal v-if="shouldPresentInteractionModal && loaded"
  :isEditingExistingInteraction="isEditingExistingInteraction"
  :pupilActionStatus="props.pupilActionStatus"
  :pupilIds="[props.pupilId]"
  :targetType="props.targetType"
  :interactionToEdit="interactionToEdit" 
  :localAuthorityId="props.localAuthorityId"
  :schoolId="props.schoolId"
  :schoolPupilRows="props.schoolPupilRows"
  :pupilsOnInteraction="pupilsOnInteraction"
  @updatedInteraction="handleUpdatedInteraction()"
  @stopEditingInteraction="handleStopEditingInteraction"
/>

</template>
<script setup>
import { ref, defineProps, onMounted, nextTick, computed} from 'vue';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import moment from 'moment';
import StaffInteractionModal from './StaffInteractionModal.vue'


var loaded = ref(false);
let props = defineProps({
    targetType: String,
    isAuthoritySecondaryDisplay: { type: Boolean, default: false},
    isDisplayingOnPupilPage: Boolean,
    pupilId: { type: String, default: null },
    localAuthorityId: { type: String, default: null },
    schoolPupilRows: { type: Array, default: null },
    schoolId: { type: String, default: null },
});

// var showAddInteraction = true;
var shouldPresentInteractionModal = ref(false);
// var shouldFilterDates = ref(false);

var isEditingExistingInteraction = ref(false);
var interactionToEdit = ref(Object);
var pupilsOnInteraction = ref([]);

//Set to string or null
// var filteredStartDate = '';
// var filteredEndDate = '';

var interactions = []
let pupilTableColumns = [
    {
        label: 'Date',
        field: 'interactionDate',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Duration (mins)',
        field: 'durationInMinutes',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Pupil',
        field: 'pupils',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Staff Member',
        field: 'teacher.name',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Type',
        field: 'interactionType',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Significant',
        field: 'isSignificantInteraction',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true,
            filterDropdownItems: [
                { value: true, text: 'Yes' },
                { value: false, text: 'No' }
            ],
        },
    },
    {
        label: 'Interaction',
        field: 'interaction',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Actions',
        field: 'actions',
        sortable: false,
    },
]

let localAuthorityTableColumns = [
    {
        label: 'Date',
        field: 'interactionDate',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Duration (mins)',
        field: 'durationInMinutes',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Interaction',
        field: 'interaction',
        sortable: true,
        globalSearchDisabled: true,
        filterOptions: {
            enabled: true
        },
    },
    {
        label: 'Actions',
        field: 'actions',
        sortable: false,
    }
]

onMounted(async () => {
    await fetchInteractions();
    loaded.value = true;

});

const getColumnsForTargetType = computed(() => {
    switch (props.targetType) {
        case "pupil": 
            //Return pupil table columns except for this element containing field "pupils"
            return pupilTableColumns.filter(column => column.field !== 'pupils');
        case "school":
            //Return pupil table columns except for this element containing field "pupils"
            return pupilTableColumns.filter(column => column.field !== 'pupils');
        case "localAuthority": 
            if (props.isAuthoritySecondaryDisplay) {
                //Return local authority table columns minus the last actions element 
                return pupilTableColumns.filter(column => column.field !== 'actions');
            }
            return localAuthorityTableColumns;
        default: return [];
    }
});



function getPupilRowsFor(fetchedInteractions) {
    // let currentInteractions = filteredInteractions.value; // Use .value to access the computed array
    switch (props.targetType) {
        case "localAuthority": 
            if (props.isAuthoritySecondaryDisplay) {
                return fetchedInteractions.filter(interaction => interaction.targetType === "pupil");
            }
            return fetchedInteractions.filter(interaction => interaction.targetType === "localAuthority");
        default: 
            return fetchedInteractions
    }
}


async function fetchInteractions() {
    isEditingExistingInteraction.value = false
    let url = getTargetUrl() + '/list'
    let res = await fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ targetType: props.targetType }),
    })
    const jsonResp = await res.json()
    interactions = getPupilRowsFor(jsonResp)
}

function getTargetUrl() {
    if (props.targetType == "localAuthority") {
    return 'https://be.pencasolutions.com/authorities/' + props.localAuthorityId + '/interaction'
    } else if (props.targetType == "pupil") {
    return 'https://be.pencasolutions.com/pupils/' + props.pupilId + '/interaction'
    } else if (props.targetType == "school") {
    return 'https://be.pencasolutions.com/schools/' + props.schoolId + '/interaction'
    }
}

function shouldShowInteractionModal() {
    shouldPresentInteractionModal.value = true
    nextTick(() => {
        document.getElementById('addInteractionBtn').click()
    })
}

function editInteraction(interaction) {
    interactionToEdit.value = interaction
    if (interaction.targetType === 'school' && interaction.pupils) {
        if (interaction.pupils.length >= 1) {
            pupilsOnInteraction.value = interaction.pupils
        }
    } 
    isEditingExistingInteraction.value = true
    shouldShowInteractionModal()
}

function formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

async function handleUpdatedInteraction() {
    isEditingExistingInteraction.value = false
    shouldPresentInteractionModal.value = false
    loaded.value = false
    await fetchInteractions()
    loaded.value = true
    pupilsOnInteraction.value = []
}

async function handleStopEditingInteraction() {
    isEditingExistingInteraction.value = false
    shouldPresentInteractionModal.value = false
    loaded.value = false
    await fetchInteractions()
    loaded.value = true
    pupilsOnInteraction.value = []
}

async function deleteInteraction(interactionRow) {
    // const pupilId = this.$route.params.id
    const interactionToDelete = interactionRow._id
    const body = {
    interactionToDelete
    }
    let target = this.getTargetUrl()
    if (confirm("Are you sure you want to delete the interaction?")) {
    await fetch(target, {
        method: 'DELETE',
        headers: {
        'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
    })
    }
    await handleStopEditingInteraction()
}

// function clearDateFilter() {
//     filteredStartDate = ''
//     filteredEndDate = ''
// }

// const filteredInteractions = computed(() => {
//     if (!shouldFilterDates.value) {
//         return interactions;
//     }
//     return interactions.filter(interaction => {
//         const startDate = filteredStartDate ? new Date(filteredStartDate) : null;
//         const endDate = filteredEndDate ? new Date(filteredEndDate) : null;
//         const interactionDate = new Date(interaction.interactionDate);
        
//         if (startDate && interactionDate < startDate) {
//             return false;
//         }
//         if (endDate && interactionDate > endDate) {
//             return false;
//         }
//         return true;
//     });
// });

</script>