<template>
  <div class="card plr-5 mt-3">
  <div class="card-header">
    Book Reviews
  </div>
  <vue-good-table
  :columns="pupilsToBookColumns"
  :rows="filteredPupils"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: false,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span v-if="props.column.field == 'uniqueCode'">
      <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
      </PupilStatusIndicator>
      <p v-if="isSecondaryTeacher(props.row)"> (Secondary)</p>
    </span>
    <span v-if="props.column.field == 'firstName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.firstName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'lastName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.lastName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'school.name'">
      <router-link :to="{ name: 'displayschool', params: {id: props.row.school._id} }">
        {{props.row.school.name}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'lastReviewDate'">
      <span v-if="props.row.lastReviewDate">
        {{dateFormat(props.row.lastReviewDate)}}
      </span>
    </span>
    <span v-if="props.column.field == 'actions'">
      <button class="btn btn-datatable btn-icon btn-transparent-dark" id="showScheduleModalBtn" type="button" data-bs-toggle="modal" data-bs-target="#reschedulemodal" style="display: none"><i class="fa-regular fa-clock"></i></button>
      <button @click="$emit('clickedReschedule', props.row._id)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Reschedule"><i class="fa-regular fa-clock"></i></button>
    </span>
  </template>
  </vue-good-table>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import moment from 'moment'
import PupilStatusIndicator from '../../../../components/PupilStatusIndicator.vue'

let props = defineProps({
  pupilsToBook: Array,
  requestedTeacherId: String
})
let pupilsToBookColumns = [
  {
    label: 'ID',
    field: 'uniqueCode',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'First Name',
    field: 'firstName',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Last Name',
    field: 'lastName',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'School',
    field: 'school.name',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Year Group',
    field: 'yearGroup',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Last Review Date',
    field: 'lastReviewDate',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Status',
    field: 'status',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Actions',
    field: 'actions',
    sortable: true,
  },
]
let filteredPupils = computed(() => {
  var updatedPupils = [...props.pupilsToBook];
  updatedPupils.forEach(pupil => {
    if (!pupil.lastReviewDate && pupil.completedAssessmentDate) {
      pupil.lastReviewDate = pupil.completedAssessmentDate;
    }
  });
  return updatedPupils;
})

function dateFormat(dateToFormat) {
  return moment.utc(dateToFormat).format('DD/MM/YYYY');
}

function isSecondaryTeacher(pupil) {
  return pupil.teachers?.map(teacher => teacher._id).includes(props.requestedTeacherId);
}

</script>
