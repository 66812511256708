<template>
    <div>
        <h1>Software</h1>
        <hr>
        <vue-good-table :columns="columns" :rows="software">
            <template #table-row="props">
                <span v-if="props.column.field == 'manufacturer'">
                    {{props.row.manufacturer.name}}
                </span>
                <span v-if="props.column.field == 'name'">
                    <router-link :to="{ name: 'displaysoftware', params: {id: props.row._id} }">
                        {{props.formattedRow[props.column.field]}}
                    </router-link>
                </span>
                <span v-if="props.column.field == 'volumeLicenses'">
                    {{ props.row.assignedVolumeLicenseSeats }} / {{ props.row.totalVolumeLicenseSeats }}
                </span>
                <span v-if="props.column.field == 'individualLicenses'">
                    {{ props.row.assignedIndividualLicenes }} / {{ props.row.totalIndividualLicenses }}
                </span>
            </template>
        </vue-good-table>
        <br>
        <!-- Add manufacturer modal -->
        <button class="btn btn-success" @click="shouldShowManufacturerModal">Add Manufacturer</button>
        <button hidden id="showAddManufacturerModal" data-bs-toggle="modal" data-bs-target="#addmanufacturermodal" style="display: none"></button>
        <div class="modal fade" id="addmanufacturermodal" tabindex="-1" role="dialog" aria-labelledby="addmanufacturermodal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>Add Manufacturer</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <label for="manufacturer">Manufacturer:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" id="manufacturer" v-model="newManufacturer" class="w-100">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" @click="addManufacturer">Submit</button>
                        <button class="btn btn-primary" @click="cancelAddManufacturer" id="closemanufacturermodal" type="button" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add SW Modal -->
        <button class="btn btn-primary" @click="shouldShowSwModal">Add Software</button>
        <button hidden id="showAddSwModal" data-bs-toggle="modal" data-bs-target="#addswmodal" style="display: none"></button>
        <div class="modal fade" id="addswmodal" tabindex="-1" role="dialog" aria-labelledby="addswmodal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>Add Software</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <label for="manufacturer">Manufacturer:</label>
                            </div>
                            <div class="col-6">
                                <select id="manufacturer" v-model="selectedManufacturerId" class="w-100">
                                    <option v-for="manufacturer in manufacturers.sort((a, b) => a.name.localeCompare(b.name))" :key="manufacturer._id" :value="manufacturer._id">{{ manufacturer.name }}</option>
                                </select>
                                </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label for="name">Software Name:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" id="name" v-model="newSoftwareName" class="w-100">
                            </div>
                        </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" @click="addSoftware">Submit</button>
                <button class="btn btn-primary" @click="cancelAddSoftware" id="closeswmodal" type="button" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'

export default {
    name: 'ListSoftware',
    components: {
        VueGoodTable,
    },
    mounted() {
        this.getSoftware()
    },
    data() {
        return {
            manufacturers: [],
            hasFetchedManufacturers: false,
            selectedManufacturerId: null,
            newSoftwareName: '',
            isLoaded: false,
            software: [],
            columns: [
            {
                    label: 'Manufacturer',
                    field: 'manufacturer',
                    // filterOptions: {
                    //     enabled: true
                    // },
                },
                {
                    label: 'Name',
                    field: 'name',
                    // filterOptions: {
                    //     enabled: true
                    // },
                },
                {
                    label: 'Used Volume License Seats',
                    field: 'volumeLicenses',
                },
                {
                    label: 'Used Individual Licenses',
                    field: 'individualLicenses',
                },
                {
                    label: 'Cost',
                    field: 'costFormatted',
                }
            ],
        }
    },
    methods: {
        async getSoftware() {
            const res = await fetch('https://be.pencasolutions.com/software/', {
            method: "GET",
            credentials: 'include',
        })
        this.software = await res.json()
        this.isLoaded = true
        },
        async shouldShowManufacturerModal() {
            document.getElementById('showAddManufacturerModal').click()
        },
        async shouldShowSwModal() {
            // Fetch the manufactuers list 
            if (this.hasFetchedManufacturers === false) {
                let manufactuerers = await fetch('https://be.pencasolutions.com/software/manufacturers', {
                    method: "GET",
                    credentials: 'include',
                })
                this.manufacturers = await manufactuerers.json()
                this.hasFetchedManufacturers = true
            }
            document.getElementById('showAddSwModal').click()
        },
        async addManufacturer() {
            let res = await fetch('https://be.pencasolutions.com/software/manufacturers', {
                method: "POST",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.newManufacturer
                })
            })
            if (res.status === 200) {
                if (this.manufacturers.includes(this.selectedManufacturer) === false) {
                    this.manufacturers.push(this.selectedManufacturer)
                    this.manufacturers.sort((a, b) => a.localeCompare(b))
                }
            }
            this.cancelAddManufacturer()
        },
        
        async addSoftware() {
            await fetch('https://be.pencasolutions.com/software/', {
                method: "POST",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    manufacturer: this.selectedManufacturerId,
                    name: this.newSoftwareName
                })
            })
            await this.getSoftware()
            this.selectedManufacturer = null
            this.newSoftwareName = ''
            this.cancelAddSoftware()
        },

        cancelAddManufacturer() {
            // Reset the form and close the modal
            this.newManufacturer = ''
            document.getElementById('closemanufacturermodal').click()
        },
        cancelAddSoftware() {
            // Reset the form and close the modal
            this.selectedManufacturerId = null
            this.newSoftwareName = ''
            document.getElementById('closeswmodal').click()
        }
    },
}
</script>