<template>
    <div class="modal fade" id="renamehardwaremodal" tabindex="-1" role="dialog" aria-labelledby="renamehardwaremodal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Rename Hardware</h5>
        </div>
        <div class="modal-body">
          <p>Rename from {{ existingName }} to: <input v-model="newName" /></p>
          <!-- Checkbox input -->
          <div>
            <input type="checkbox" id="renameAllCheckbox" v-model="shouldUpdateAll">
            <label for="renameAllCheckbox">Rename every item with the same name?</label>
          </div>
          <div class="modal-footer">
            <button @click="confirm" class="btn btn-success">Confirm</button>
            <button @click="closeModal" id="closerenamemodal" class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  </template>
    
  <script>
  export default {
    props: ['existingName'],
    data() {
      return {
        newName: '',
        shouldUpdateAll: false  // Data property for the checkbox
      }
    },
    methods: {
      confirm() {
        this.$emit('confirm', {
          newName: this.newName,
          shouldUpdateAll: this.shouldUpdateAll 
        });
        this.closeModal();
      },
      closeModal() {
        this.newName = ''
        this.shouldUpdateAll = false
        document.getElementById('renameAllCheckbox').checked = false
        document.getElementById("closerenamemodal").click()
        this.$emit('close');
      }
    }
  }
  </script>
  