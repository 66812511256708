<template>
  <h1>Pupils</h1>
  <hr>
  <button class="btn btn-sm btn-primary text-light px-4 mr-2" id="allbtn" @click="setFilter('allbtn')">All</button>
  <button class="btn btn-sm btn-success text-light px-4 mr-2" id="cenmacBtn" @click="setFilter('cenmacBtn')">Cenmac</button>
  <button class="btn btn-sm btn-success text-light px-4 mr-2" id="cpaBtn" @click="setFilter('cpaBtn')">CPA</button>
  <button class="btn btn-sm btn-success text-light px-4 mr-2" id="hatBtn" @click="setFilter('hatBtn')">HAT</button>
  <button class="btn btn-sm btn-success text-light px-4 mr-2" id="limboBtn" @click="setFilter('limboBtn')">Limbo</button>
  <br>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills" id="cardPill" role="tablist">
        <li class="nav-item"><a class="nav-link active" id="overview-pill" href="#overviewPill" data-bs-toggle="tab"
            role="tab" aria-controls="overview" aria-selected="true">Active</a></li>
        <li class="nav-item"><a class="nav-link" id="archive-pill" href="#archivePill" data-bs-toggle="tab" role="tab"
            aria-controls="example" aria-selected="false">Archived</a></li>
        <li class="nav-item"><a class="nav-link" id="rejected-pill" href="#rejectedPill" data-bs-toggle="tab" role="tab"
            aria-controls="example" aria-selected="false">Rejected</a></li>
      </ul>
    </div>
    <div class="tab-content" id="cardPillContent">
      <div class="tab-pane fade show active" id="overviewPill" role="tabpanel" aria-labelledby="overview-pill">
        <ActivePupilsTable 
          :pupils="filteredActivePupils" 
          @pupilActionStatusChanged="updatePupilActionStatus"
          />
      </div>
      <div class="tab-pane fade" id="archivePill" role="tabpanel" aria-labelledby="archive-pill">
        <ArchivedPupilsTable :pupils="filteredArchivedPupils" />
      </div>
      <div class="tab-pane fade" id="rejectedPill" role="tabpanel" aria-labelledby="rejected-pill">
        <RejectedPupilsTable :pupils="filteredRejectedPupils" />
      </div>
    </div>
  </div>

  <router-link :to="{ name: 'createpupil' }">
    <div class="card mb4 mt-3 col-lg-2">
      <button class="btn btn-primary">Create Pupil</button>
    </div>
  </router-link>
</template>

<script>
import ActivePupilsTable from '../../Pupils/ListPupils/Tables/ActivePupilsTable.vue'
import ArchivedPupilsTable from '../../Pupils/ListPupils/Tables/ArchivedPupilsTable.vue'
import RejectedPupilsTable from '../../Pupils/ListPupils/Tables/RejectedPupilsTable.vue'

export default {
  name: 'ListPupils',
  emits: ['updatepagetitle'],
  components: {
    ActivePupilsTable,
    ArchivedPupilsTable,
    RejectedPupilsTable,
  },
  data() {
    return {
      activePupils: [],
      archivedPupils: [],
      rejectedPupils: [],
      activeFilter: ""
    }
  },
  computed: {
    filteredActivePupils() {
      if (this.activeFilter == "") { return this.activePupils }
      else if (this.activeFilter == "Limbo") {
        let filteredPupils = this.activePupils.filter(function (pupil) {
          return pupil.status === "Limbo"
        });
        return filteredPupils
      }

      let filter = this.activeFilter 
      let filteredPupils = this.activePupils.filter(function (pupil) {
        return 'uniqueCode' in pupil && pupil.uniqueCode.includes(filter)
      });
      return filteredPupils
    },
    filteredArchivedPupils() {
      if (this.activeFilter == "") { return this.archivedPupils }
      else if (this.activeFilter == "Limbo") {
        let filteredPupils = this.archivedPupils.filter(function (pupil) {
          return pupil.status === "Limbo"
        });
        return filteredPupils
      }
      let filter = this.activeFilter 
      let filteredPupils = this.archivedPupils.filter(function (pupil) {     
        return 'uniqueCode' in pupil && pupil.uniqueCode.includes(filter)
      });
      return filteredPupils
    },
    filteredRejectedPupils() {
      if (this.activeFilter == "") { return this.rejectedPupils }
      else if (this.activeFilter == "Limbo") {
        let filteredPupils = this.rejectedPupils.filter(function (pupil) {
          return pupil.status === "Limbo"
        });
        return filteredPupils
      }
      let filter = this.activeFilter 
      let filteredPupils = this.rejectedPupils.filter(function (pupil) {
        return 'uniqueCode' in pupil && pupil.uniqueCode.includes(filter)
      });
      return filteredPupils
    },
  },
  async created() {
    this.$emit('updatepagetitle', 'All Pupils')
    this.fetchPupils()
  },
  methods: {
    async fetchPupils() {
      let res = await fetch('https://be.pencasolutions.com/pupils/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })

      const json = await res.json()
      this.activePupils = json['activePupils'].map(pupil => (
        {
          'id': pupil._id,
          'uniqueCode': pupil.uniqueCode,
          'firstName': pupil.firstName,
          'lastName': pupil.lastName,
          'year': pupil.yearGroup,
          'category': pupil.category,
          'teacher': pupil.teacher,
          'aac': pupil.aac,
          'aacHub': pupil.aacHub,
          'lastReviewDate': pupil.lastReviewDate,
          'scheduledAssessmentDate': pupil.scheduledAssessmentDate,
          'completedAssessmentDate': pupil.completedAssessmentDate,
          'school': pupil.school,
          'status': pupil.status,
          'actionStatus': pupil.actionStatus
        }
      ))
      this.archivedPupils = json['archivedPupils'].map(pupil => (
        {
          'id': pupil._id,
          'uniqueCode': pupil.uniqueCode,
          'firstName': pupil.firstName,
          'lastName': pupil.lastName,
          'year': pupil.yearGroup,
          'category': pupil.category,
          'school': pupil.school,
          'status': pupil.status,
          'archiveReason': pupil.archiveReason
        }
      ))
      this.rejectedPupils = json['rejectedPupils'].map(pupil => (
        {
          'id': pupil._id,
          'firstName': pupil.firstName,
          'lastName': pupil.lastName,
          'year': pupil.yearGroup,
          'category': pupil.category,
          'status': pupil.status,
          'rejectionDate': pupil.rejectionDate,
          'rejectionReason': pupil.rejectionReason
        }
      ))
    },
    async setFilter(btnId) {

      switch (btnId) {
        case "allbtn":
          this.activeFilter = "";
          break;
        case "cenmacBtn":
          this.activeFilter = "cenmac";
          break;
        case "cpaBtn":
          this.activeFilter = "CPA";
          break;
        case "hatBtn":
          this.activeFilter = "HAT"
          break;
        case "limboBtn":
          this.activeFilter = "Limbo"
          break;
      }

      var btns = ["allbtn", "cenmacBtn", "cpaBtn", "hatBtn", "limboBtn"]
      var filteredBtns = btns.filter(function (btn) {
        return btn !== btnId;
      });
      btns.forEach(function (btn) {
        document.getElementById(btn).classList.remove("btn-success");
        document.getElementById(btn).classList.remove("btn-primary");
      });
      filteredBtns.forEach(function (btn) {
        document.getElementById(btn).classList.add("btn-success");
      });
      document.getElementById(btnId).classList.add("btn-primary");
    },

    updatePupilActionStatus(data) {
    let [pupilId, selectedStatus] = data;
    console.log(pupilId, selectedStatus)
    //Now set this emitter from the child and then the grand child modal 
    let pupil = this.activePupils.find(p => p.id === pupilId);
    if (pupil) { pupil.actionStatus = selectedStatus }
    else { console.log("Couldnt find pupil")}
}
  },
}

</script>

<style scoped>
.nav-pills .nav-link {
    padding: 3px 7px; 
    opacity: 0.9;
}
</style>

