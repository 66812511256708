import { createRouter, createWebHistory } from 'vue-router'
// import auth from '../middleware/auth'
import { useCookies } from "vue3-cookies";

import Dashboard from '../views/Dashboard.vue'

import Login from '../Login.vue'
import Analytics from '../views//Analytics/Analytics.vue'

import ListStaff from '../views/Staff/ListStaff.vue'
import CreateStaff from '../views/Staff/CreateStaff.vue'
import GetTeacher from '../views/Staff/Teacher/GetTeacher.vue'
import ListContacts from '../views/Contacts/ListContacts.vue'

import ListPupils from '../views/Pupils/ListPupils/ListPupils.vue'
import CreatePupil from  '../views/Pupils/CreatePupil.vue'
import GetPupil from '../views/Pupils/GetPupil.vue'

import ListAuthorities from '../views/Authorities/ListAuthorities.vue'
import CreateAuthority from  '../views/Authorities/CreateAuthority.vue'
import GetAuthority from '../views/Authorities/GetAuthority.vue'

import ListSchools from '../views/Schools/ListSchools.vue'
import CreateSchool from '../views/Schools/CreateSchool.vue'
import GetSchool from '../views/Schools/GetSchool.vue'

// import ListHardwareProducts from '../views/Hardware/ListHardwareProducts.vue'
import CreateHardwareForm from '../views/Hardware/Create/DeviceForm.vue'
import ListHardware from '../views/Hardware/ListHardware.vue'
import DisplayHardware from '../views/Hardware/DisplayHardware.vue'
import ListSoftware from '../views/Software/ListSoftware.vue'
import DisplaySoftware from '../views/Software/DisplaySoftware.vue'
import ListTickets from '../views/Tickets/ListTickets.vue'

import LogTicket from '../views/Tickets/LogTicket.vue'
import TechTicket from '../views/Tickets/TechTicket.vue'
import StaffTickets from '../views/Tickets/StaffTickets.vue'

import CreateReview from '../views/Reviews/CreateReview.vue'
import GetReview from '../views/Reviews/GetReview.vue'

// import Login from '../components/Login.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Cenmac'
}
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/staff',
    name: 'Advisory Team',
    component: ListStaff,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/staff/:id',
    name: 'displayteacher',
    component: GetTeacher,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/staff/create',
    name: 'createstaff',
    component: CreateStaff,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ListContacts,
  },
  {
    path: '/pupils/create',
    name: 'createpupil',
    component: CreatePupil,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/pupils',
    name: 'Pupils',
    component: ListPupils,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/pupils/:id',
    name: 'displaypupil',
    component: GetPupil,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/pupils/:id/review',
    name: 'createreview',
    component: CreateReview,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/pupils/:id/review/:reviewid',
    name: 'getreview',
    component: GetReview,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/authorities',
    name: 'Authorities',
    component: ListAuthorities,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/authorities/create',
    name: 'createauthority',
    component: CreateAuthority,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/authorities/:id',
    name: 'displayauthority',
    component: GetAuthority,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/schools',
    name: 'Schools',
    component: ListSchools,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/schools/create',
    name: 'createschool',
    component: CreateSchool,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/schools/:id',
    name: 'displayschool',
    component: GetSchool,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/hardware',
    name: 'Hardware',
    // component: ListHardwareProducts,
    component: ListHardware,

    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/hardware/:id',
    name: 'displayhardware',
    component: DisplayHardware,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/hardware/create',
    name: 'createhardware',
    component: CreateHardwareForm,
    // meta: {
    //   middleware: auth
    // }
  },
  {
    path: '/software/',
    name: 'Software',
    component: ListSoftware,
  },
  {
    path: '/software/:id',
    name: 'displaysoftware',
    component: DisplaySoftware,
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: ListTickets,
  },
  {
    path: '/tickets/create',
    name: 'Log Ticket',
    component: LogTicket,
  },
  {
    path: '/tickets/tech',
    name: 'Technician Ticket',
    component: TechTicket,
  },
  {
    path: '/tickets/staff',
    name: 'Staff Tickets',
    component: StaffTickets,
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { cookies } = useCookies();
  const token = cookies.get('jwt')
  // If logged in, or going to the Login page.
  // if (token || to.name === 'Login') {
  if (token) {
    // Continue to page.
    if (to.path == "/") {
      next({path: '/dashboard'});
    } else {
      next();
    }
  } else {
    // Not logged in, redirect to login.
    if (to.path == "/login") {
      next();
    } else {
      next({path: '/login'});
    }
  }
});


  //Check for expiry
// // Creates a `nextMiddleware()` function which not only
// // runs the default `next()` callback but also triggers
// // the subsequent Middleware function.
// function nextFactory(context, middleware, index) {
//   const subsequentMiddleware = middleware[index];
//   // If no subsequent Middleware exists,
//   // the default `next()` callback is returned.
//   if (!subsequentMiddleware) return context.next;
//
//   return (...parameters) => {
//     // Run the default Vue Router `next()` callback first.
//     context.next(...parameters);
//     // Then run the subsequent Middleware with a new
//     // `nextMiddleware()` callback.
//     const nextMiddleware = nextFactory(context, middleware, index + 1);
//     subsequentMiddleware({ ...context, next: nextMiddleware });
//   };
// }
//
// router.beforeEach((to, from, next) => {
//   if (to.meta.middleware) {
//     const middleware = Array.isArray(to.meta.middleware)
//       ? to.meta.middleware
//       : [to.meta.middleware];
//
//     const context = {
//       from,
//       next,
//       router,
//       to,
//     };
//     const nextMiddleware = nextFactory(context, middleware, 1);
//
//     return middleware[0]({ ...context, next: nextMiddleware });
//   }
//
//   return next();
// });

export default router
