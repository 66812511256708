<template>
  <vue-good-table
  :columns="columns"
  :rows="pupils"
  :pagination-options="{
    enabled: true,
    mode: 'pages'
    }"
    :search-options="{
      enabled: false,
      skipDiacritics: true,
      }">

      <template #table-row="props">
        <span v-if="props.column.field == 'firstName'">
          <router-link :to="{ name: 'displaypupil', params: {id: props.row.id} }">
            {{props.row.firstName}}
          </router-link>
        </span>
        <span v-else-if="props.column.field == 'lastName'">
          <router-link :to="{ name: 'displaypupil', params: {id: props.row.id} }">
            {{props.row.lastName}}
          </router-link>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
</template>

<script>
import moment from 'moment'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
export default {
  name: 'RejectedPupilsTable',
  components: {
    VueGoodTable
  },
  props: {
    pupils: []
  },
  data() {
    return {
      columns: [
        {
          label: 'First Name',
          field: 'firstName',
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Last Name',
          field: 'lastName',
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Year',
          field: 'year',
          filterOptions: {
            enabled: true,
          },
          sortable: true,
          sortFn: this.sortYearGrps,
        },
        {
          label: 'Category',
          field: 'category',
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Rejection Date',
          field: 'rejectionDate',
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Reason',
          field: 'rejectionReason',
          filterOptions: {
            enabled: true
          },
        },
      ],
    }
  },
  methods: {
    moment(dateToFormat) {
      return moment.utc(dateToFormat).format('YYYY/MM/DD');
    },
    sortYearGrps (a, b) { 

      //Create a dictionary with these values
      const yearGrps = {
        "N": -1, 
        "R": -2,
        "16+": 17, 
      }
      //If yearGrps doesnt contain a use the value of a otherwise use a new value from the dictionary
      const aVal = yearGrps[a] || parseInt(a)
      const bVal = yearGrps[b] || parseInt(b)

      return parseInt(aVal) - parseInt(bVal)

    }
  },
}
</script>
