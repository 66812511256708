<template lang="html">
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6">
    <div class="card plr-5 mt-3">
       <div class="card-header">
         Languages
         <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#assignLanguage">+</button>
       </div>
       <div class="card-body">
         <p v-for="language in pupilLanguages" :value="language" :key="language" >
           {{language}}
           <span @click="deleteLanguageClicked(language)"><i class="fa-solid fa-trash"></i></span>
           <br>
         </p>
       </div>
     </div>
  </div>

  <!-- Assign Language Modal -->
  <div class="modal fade" id="assignLanguage" tabindex="-1" role="dialog" aria-labelledby="assignLanguage" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Languages</h5>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12">
         <select id="language" input name="language" v-on:change="onChangeSelectedLanguage" class="form-control">
           <option v-for="language in allLanguages" :value="language" :key="language">
                {{ language }}
           </option>
         </select>
        </div>
        <div v-if="shouldShowLanguageEntry" class="form-group col-md-12">
          <p>Specify other language:
            <input class="form-control form-control" id="otherlang" name="otherlang" type="text">
          </p>
        </div>
        </div>
        <div class="modal-footer">
          <button @click="assignLanguage()" class="btn btn-success">Assign Language</button>
          <button class="btn btn-primary" id="closelanguagemodal" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pupilLanguages: [],
      allLanguages: [],
      shouldShowLanguageEntry: false
    }
  },
  created() {
    this.fetchPupilLanguages()
    this.fetchAllLanguages()
  },
  methods: {
    async reload() {
      await this.fetchPupilLanguages()
      await this.fetchAllLanguages()
    },
    async fetchPupilLanguages() {
      const pupilId = this.$route.params.id
      const res = await fetch('https://be.pencasolutions.com/pupils/' + pupilId + '/language', {
        credentials: 'include',
      })
      this.pupilLanguages = await res.json()
    },
    async fetchAllLanguages() {
      const res = await fetch('https://be.pencasolutions.com/pupils/languages', {
        credentials: 'include',
      })
      this.allLanguages = await res.json()
    },
    async assignLanguage() {
      const pupilId = this.$route.params.id
      var language = document.getElementById('language').value
      if (language == "Other") {
        language = document.getElementById('otherlang').value
      }
      await fetch('https://be.pencasolutions.com/pupils/'+ pupilId + '/language', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(language),
      })
      document.getElementById('closelanguagemodal').click()
      this.reload()
    },
    async deleteLanguageClicked(language){
      const pupilId = this.$route.params.id
      if(confirm("Are you sure you want to remove " + language +"?" )){
      await fetch('https://be.pencasolutions.com/pupils/'+ pupilId + '/language', {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(language),
      })
    }
      this.reload()
    },
    async onChangeSelectedLanguage() {
      if(document.getElementById('language').value == "Other") {
        this.shouldShowLanguageEntry = true
      } else {
        this.shouldShowLanguageEntry = false
      }
    }
  },
}
</script>
