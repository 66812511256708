<template>
  <vue-good-table :columns="columns" :rows="filteredPupils" :pagination-options="{
     enabled: true,
     mode: 'pages'
   }" :search-options="{
   enabled: false,
   skipDiacritics: true,
 }">
 
     <template #table-row="props">
       <span v-if="props.column.field == 'firstName'">
         <router-link :to="{ name: 'displaypupil', params: { id: props.row.id } }">
           {{ props.row.firstName }}
         </router-link>
       </span>
       <span v-else-if="props.column.field == 'lastName'">
         <router-link :to="{ name: 'displaypupil', params: { id: props.row.id } }">
           {{ props.row.lastName }}
         </router-link>
       </span>
       <span v-else-if="props.column.field == 'uniqueCode'">
         <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
         </PupilStatusIndicator>
       </span>
       <span v-else-if="props.column.field == 'teacher'">
         <router-link :to="{ name: 'displayteacher', params: { id: props.row.teacher._id } }">
           {{ props.row.teacher.firstName }} {{ props.row.teacher.lastName }}
         </router-link>
       </span>
       <span v-else-if="props.column.field == 'aac'">
          <span v-if="props.row.aac == 'yes' && props.row.aacHub === false">
            Yes
          </span>
          <span v-if="props.row.aac == 'hub'">
            Yes (Hub)
          </span>
          <span v-if="props.row.aac == 'no'">
            No
         </span>
       </span>
       <span v-else-if="props.column.field == 'school.name'">
         <router-link :to="{ name: 'displayschool', params: { id: props.row.school._id } }">
           {{ props.row.school.name }}
         </router-link>
       </span>
       <span v-else-if="props.column.field == 'lastReviewDate'">
         <span v-if="props.row.lastReviewDate">
           {{ formatDate(props.row.lastReviewDate) }}
         </span>
       </span>
       <span v-else>
         {{ props.formattedRow[props.column.field] }}
       </span>
     </template>
   </vue-good-table>
 </template>
 
 <script setup>
 import { ref } from 'vue'
 import { defineProps, computed } from 'vue'
 import moment from 'moment'
 import 'vue-good-table-next/dist/vue-good-table-next.css'
 import { VueGoodTable } from 'vue-good-table-next'
 import PupilStatusIndicator from '../../../../components/PupilStatusIndicator.vue'
 
 let props = defineProps({
   pupils: Array
 })
 let columns = ref([
   {
     label: 'ID',
     field: 'uniqueCode',
     filterOptions: {
       enabled: true
     },
   },
   {
     label: 'First Name',
     field: 'firstName',
     filterOptions: {
       enabled: true
     },
   },
   {
     label: 'Last Name',
     field: 'lastName',
     filterOptions: {
       enabled: true
     },
   },
   {
     label: 'Year',
     field: 'year',
     filterOptions: {
       enabled: true,
     },
     sortable: true,
     sortFn: sortYearGrps
   },
   {
     label: 'Category',
     field: 'category',
     filterOptions: {
       enabled: true
     },
   },
   {
     label: 'Teacher',
     field: 'teacher',
     filterOptions: {
       enabled: true,
       filterFn: filterTeacherName
     },
   },
   {
     label: 'AAC',
     field: 'aac',
     filterOptions: {
       enabled: true,
       filterDropdownItems: [
         { value: "yes", text: 'Yes' },
         { value: "no", text: 'No' },
         { value: "hub", text: 'Yes (Hub)' },
       ],
     },
   },
   {
     label: 'Last Review',
     field: 'lastReviewDate',
     filterOptions: {
       enabled: true
     },
   },
   {
     label: 'School',
     field: 'school.name',
     filterOptions: {
       enabled: true,
     }
   },
   {
     label: 'Status',
     field: 'status',
     filterOptions: {
       enabled: true
     },
   },
 ])
 
 const filteredPupils = computed(() => {
   var updatedPupils = [...props.pupils];
   updatedPupils.forEach(pupil => {
    //Set hub value for filtering 
    if (pupil.aac === 'yes' && pupil.aacHub === true) {
      pupil.aac = 'hub'
    }

    //Set last review date to use the completed assessment date if it exists in place of the last review 
    if (!pupil.lastReviewDate && pupil.completedAssessmentDate) {
        pupil.lastReviewDate = pupil.completedAssessmentDate;
    } else if (!pupil.lastReviewDate && pupil.scheduledAssessmentDate) {
        pupil.lastReviewDate = pupil.scheduledAssessmentDate;
    }
   });
   return updatedPupils;
 })
 
 function formatDate(dateToFormat) {
   return moment.utc(dateToFormat).format('DD/MM/YYYY');
 }
 
 function filterTeacherName(teacher, filterString) {
   if (teacher.firstName.toLowerCase().includes(filterString.toLowerCase()) ||
     teacher.lastName.toLowerCase().includes(filterString.toLowerCase())) {
     return true
   }
 }
 
 function sortYearGrps (a, b) { 
 
   //Create a dictionary with these values
   const yearGrps = {
     "N": -1, 
     "R": -2,
     "16+": 17, 
   }
   //If yearGrps doesnt contain a use the value of a otherwise use a new value from the dictionary
   const aVal = yearGrps[a] || parseInt(a)
   const bVal = yearGrps[b] || parseInt(b)
 
   return parseInt(aVal) - parseInt(bVal)
 
 }
 </script>