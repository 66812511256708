<template>
  <h1>Local Authorities</h1>
  <hr>
  <div class="authorities">

    <!-- <Table :columns='columns' :rows='rows' />
   -->
   <vue-good-table
  :columns="columns"
  :rows="rows"
  :pagination-options="{
    enabled: true,
    mode: 'pages'
  }"
  :search-options="{
    enabled: true,
    skipDiacritics: true,
  }">

  <template #table-row="props">
    <span v-if="props.column.field == 'name'">
      <router-link :to="{ name: 'displayauthority', params: {id: props.row.id} }">
        {{props.formattedRow[props.column.field]}}
      </router-link>
    </span>
    <span v-else-if="props.column.field == 'actions'">
      <button class="btn btn-datatable btn-icon btn-transparent-dark" @click="deleteAuthority(props.row)">
        <i class="fa-solid fa-trash"></i>
      </button>
    </span>

  </template>
</vue-good-table>

  <router-link :to="{ name: 'createauthority' }">
    <div class="card mb4 mt-3 col-lg-2">
    <button class="btn btn-primary">Create Authority</button>
  </div>
  </router-link>

</div>
</template>

<script>
// import Table from '@/components/Table.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'

export default {
  name: 'ListAuthorities',
  emits: ['updatepagetitle'],
  data() {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Actions',
          field: 'actions',
        }
      ],
      rows: []
    }
  },
  components: {
    VueGoodTable,
  },
  async created() {
    this.$emit('updatepagetitle', 'Local Authorities')
    this.fetchAuthorities()
  },
  methods: {
    async fetchAuthorities() {
      const res = await fetch('https://be.pencasolutions.com/authorities/', {
        method: 'GET',
        credentials: 'include',
      })
      const json = await res.json()
      this.rows = json.map(authority => (
        {'id': authority._id, 'name': authority.name }
      ))
    },
    async deleteAuthority(authorityRow) {
      if(confirm("Are you sure you want to delete " + authorityRow.name)) {
        const res = await fetch('https://be.pencasolutions.com/authorities/' + authorityRow.id, {
          method: 'DELETE',
          credentials: 'include',
        })
        if (res.status === 200) {
          this.fetchAuthorities()
        }
      }
    },
  }
}
</script>
