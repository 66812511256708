<template>
  <div class="modal fade" id="archivePupilModal" tabindex="-1" role="dialog" aria-labelledby="archivePupilModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Archive Pupil</h5>
      </div>
      <div class="modal-body">
        <div class="mb-0">
          <label for="transportNotes">Reason</label>
          <textarea class="form-control form-control" id="reason" name="reason" rows="3"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button @click="archivePupil()" class="btn btn-success">Archive</button>
        <button class="btn btn-primary" id="closearchivemodal" type="button" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ArchivePupilModal',
  props: {
    pupilId: String
  },
  methods: {
    async archivePupil() {
      const archiveReason = document.getElementById('reason').value
      await fetch("https://be.pencasolutions.com/pupils/" + this.pupilId + '/archive', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({'reason':archiveReason})
      })
      document.getElementById('reason').value = ""
      this.$emit('didarchivepupil')
      document.getElementById('closearchivemodal').click()
    }
  }
}
</script>
