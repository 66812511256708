<template>
    <h1>Analytics</h1>
    <hr>
    <div class="card mb4 pl-5 mt-3">
      <div class="card-header">
        <p>Active Pupils By Category</p>
        <hr>
        <div class="d-flex justify-content-center">
          <ul class="nav nav-pills card-header-pills" id="cardPill" role="tablist">
            <li @click="activeTab = 'total'; fetchAll()" class="nav-item"><a class="nav-link active" id="total-pill" href="#totalPill"
                data-bs-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Total</a></li>
            <li @click="activeTab = 'monthly'; handleDropdownChange()" class="nav-item"><a class="nav-link" id="monthly-pill" href="#monthlyPill"
                data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false">Monthly</a></li>
          </ul>
        </div>
        <!-- Dropdowns for Month and Year (Only shown when Monthly tab is active) -->
        <div v-show="activeTab === 'monthly'">
          <hr>
          <div class="row d-flex justify-content-center">
            <div class="mb-3 col-lg-3">
              <select class="form-select me-2" v-model="selectedMonth" @change="handleDropdownChange">
                <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
              </select>
  
            </div>
            <div class="mb-3 col-lg-2">
              <select class="form-select" v-model="selectedYear" @change="handleDropdownChange">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
  
      <div class="card-body tab-content">
        <div class="tab-pane fade show active" id="totalPill" role="tabpanel" aria-labelledby="total-pill">
          <Bar v-if="loaded" id="my-chart-id" :options="chartOptions" :data="chartData" />
        </div>
        <div class="tab-pane fade" id="monthlyPill" role="tabpanel" aria-labelledby="monthly-pill">
          <Bar v-if="loaded" id="my-chart-id" :options="chartOptions" :data="chartData" />
        </div>
      </div>
    </div>
  
    <!-- <div class="card mb4 pl-5 mt-3">
      <div class="card-header">
        Pupil Information
      </div>
      <div class="card-body">
        <Bar v-if="loaded" id="my-chart-id" :options="chartOptions" :data="chartData" />
      </div>
    </div> -->
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'CategoryAnalytics',
    components: { Bar },
    computed: {
      years() {
        const startYear = 2020;
        const currentYear = new Date().getFullYear();
        const years = [];
  
        for (let year = currentYear; year >= startYear; year--) {
          years.push(year);
        }
  
        return years;
      },
    },
    data() {
      return {
        loaded: false,
        chartData: {},
        activeTab: "total",
        selectedMonth: new Date().toLocaleString('default', { month: 'long' }),
        selectedYear: new Date().getFullYear(),
        months: ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"],
        chartOptions: {
          responsive: true,
          title: {
            display: true,
            text: 'Category Stats'
          },
  
          scales: {
            x: {
              grid: {
                display: false  // This will remove only the vertical grid lines
              }
            },
            y: {
              grid: {
                display: true  // This will remove only the horizontal grid lines
              }
            }
          },
  
          plugins: {
            legend: {
              display: false  // This will hide the legend entirely
            },
          },
        }
      }
    },
    async mounted() {
      this.loaded = false
      this.fetchAll()
      
    },
    methods: {
      async fetchAll() {
        try {
        let response = await fetch('https://be.pencasolutions.com/analytics/bycategory', {
          'method': "POST",
          'credentials': 'include'
        })
        this.chartData = await response.json();
        this.loaded = true
      } catch (e) {
        console.error(e)
      }
      },
      async handleDropdownChange() {
        //0 index based array january = 0, adding 1 to get the correct month
        const selectedMonthIndex = this.months.indexOf(this.selectedMonth) + 1;
  
        let response = await fetch('https://be.pencasolutions.com/analytics/bycategory', {
          'method': "POST",
          'credentials': 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            month: selectedMonthIndex,
            year: this.selectedYear
          })
  
        })
        this.chartData = await response.json();
      },
    }
  }
  </script>
  
  <style scoped>
  .nav-pills .nav-link {
    padding: 3px 7px;
    opacity: 0.9;
  }
  </style>
  
  