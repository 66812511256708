<template>
  <h1>Staff</h1>
  <hr>
  <div class="staff">
    <vue-good-table 
    :columns="columns" 
    :rows="state.staff" 
    :pagination-options="{
      enabled: true,
      mode: 'pages'
    }" 
    :search-options="{
      enabled: true,
      skipDiacritics: true,
      initialSortBy: {field: 'name', type: 'asc'}
    }" 
    :style="{ tableLayout: 'fixed' }"
    :key="state.tableKey"
    >

      <template #table-row="props">
        <span v-if="props.column.field == 'name'">
          <span v-if="props.row.role == 'Teacher'">
            <router-link :to="{ name: 'displayteacher', params: { id: props.row.id } }">
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>
        <span v-if="props.column.field == 'isPupilReviewEvaluator'">
          <button @click='setReviewEvaluator(props.row)' class="btn btn-datatable btn-icon btn-transparent-dark">
            <i v-if="props.row.isPupilReviewEvaluator" class="fa-solid fa-check"></i>
            <i v-else class="fa-solid fa-xmark"></i>
          </button>
        </span>
      </template>
    </vue-good-table>

    <!-- <router-link :to="{ name: 'createstaff'}"> -->
    <div class="card mb4 mt-3 col-lg-2">
      <button class="btn btn-primary" disabled>Create Staff</button>
    </div>

    <!-- </router-link> -->

  </div>
</template>

<script>
import { reactive, onBeforeMount, ref } from 'vue';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'

export default {
  name: 'ListStaff',
  components: {
    VueGoodTable
  },
  setup() {
    const state = reactive({
      staff: [],
      tableKey: ref(0)
    });

    onBeforeMount(async () => {
      const res = await fetch('https://be.pencasolutions.com/staff/', {
        credentials: 'include'
      })
      let json = await res.json()
      state.staff = json.filter(staff => {
        return staff.role == 'Teacher'
      })
      //Sort by name
      state.staff.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    });

    return {
      state
    };
  },
  data() {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
        },
        {
          label: 'Role',
          field: 'role',
          sortable: true,
        },
        {
          label: 'Pupil Review Evaluator',
          field: 'isPupilReviewEvaluator',
          sortable: true,
        }
      ],
    }
  },
  methods: {
    setReviewEvaluator(staff) {
      let addOrRemoveText = staff.isPupilReviewEvaluator ? "Remove " : "Add ";
      let additionalText = addOrRemoveText === "Add " ? " to": " from";
      if (confirm(addOrRemoveText + staff.name + additionalText + " the reviewer pool?")) {
        fetch("https://be.pencasolutions.com/staff/teachers/" + staff.id + "/reviewerStatus", {
          method: "PATCH",
          credentials: 'include',
        })
          .then((response) => {
            if (response.ok) {
              const targetStaff = this.state.staff.find(t => t.id === staff.id);
              if (targetStaff) {
                targetStaff.isPupilReviewEvaluator = !targetStaff.isPupilReviewEvaluator;
                this.state.staff = [...this.state.staff];
              }
              this.state.tableKey += 1
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
}
</script>
