<template>
    <div class="modal fade" id="assignLicenseModal" tabindex="-1" role="dialog" aria-labelledby="assignLicenseeModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pupils</h5>
        </div>
        <div class="modal-body">
          <!-- <div class="card plr-5 mt-3"> -->
          <vue-good-table
          :columns="pupilColumns"
          :rows="pupils"
          :pagination-options="{
           enabled: true,
           perPage: 5,
           mode: 'pages'
          }"
          :search-options="{
           enabled: false,
           skipDiacritics: true,
          }">
          <template #table-row="props">
            <span v-if="props.column.field == 'uniqueCode'">
              <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
              </PupilStatusIndicator>
            </span>
            <span v-if="props.column.field == 'firstName'">
              <!-- <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }"> -->
                {{props.row.firstName}}
              <!-- </router-link> -->
            </span>
            <span v-if="props.column.field == 'lastName'">
              <!-- <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }"> -->
                {{props.row.lastName}}
              <!-- </router-link> -->
            </span>
            <span v-if="props.column.field == 'school.name'">
              <!-- <router-link :to="{ name: 'displayschool', params: {id: props.row.school._id} }"> -->
                {{props.row.school.name}}
              <!-- </router-link> -->
            </span>
            <span v-if="props.column.field == 'actions'">
              <button @click="assignLicenseeToPupil(props.row.id)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Assign to pupil" >
                {{props.formattedRow[props.column.field]}}
              <i class="fa-solid fa-arrow-right"></i>
              </button>
            </span>
          </template>
          </vue-good-table>
          </div>
        <!-- </div> -->
        <div class="modal-footer">
          <button class="btn btn-primary" id="closeswassignment" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import 'vue-good-table-next/dist/vue-good-table-next.css'
  import { VueGoodTable } from 'vue-good-table-next'
  import PupilStatusIndicator from './PupilStatusIndicator.vue'
  export default {
    name: 'AssignSwModal',
    components: {
      VueGoodTable,
      PupilStatusIndicator
    },
    props: {
        licenseid: String,
        softwareid: String,
        pupils: [],
    },
    data() {
      return  {
        pupilColumns: [
          {
            label: 'ID',
            field: 'uniqueCode',
            sortable: true,
            globalSearchDisabled: true,
            filterOptions: {
              enabled: true
            },
          },
          {
            label: 'First Name',
            field: 'firstName',
            sortable: true,
            globalSearchDisabled: true,
            filterOptions: {
              enabled: true
            },
          },
          {
            label: 'Last Name',
            field: 'lastName',
            sortable: true,
            globalSearchDisabled: true,
            filterOptions: {
              enabled: true
            },
          },
          {
            label: 'School',
            field: 'school.name',
            sortable: true,
            globalSearchDisabled: true,
            filterOptions: {
              enabled: true
            },
          },
          {
            label: 'Actions',
            field: 'actions',
          },
        ],
      }
    },
    methods: {
        async assignLicenseeToPupil(pupilId) {
            await fetch('https://be.pencasolutions.com/software/assign', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    "licenseid": this.licenseid,
                    "pupilid": pupilId,
                    "softwareid": this.softwareid
                })
            })
            document.getElementById('closeswassignment').click()
            this.$emit('didAssignSw')
            },
        }
  }
  </script>
  