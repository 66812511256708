<template>
    <div class="nav accordion" id="accordionSidenav">

      <img :src="logoSrc" alt="" style="padding: 10px;">
      <hr>
        <SideBarNavLink
          routerName="Dashboard"
          icon="dashboard.svg"
          :isActive="isPageActive('Dashboard')"
          @didClickNavLink = "didClickSideBarLink"
        />

      <SideBarNavLink 
        routerName="Analytics" 
        icon="analytics.svg" 
        :isActive="isPageActive('Analytics')"
        @didClickNavLink = "didClickSideBarLink"
      />

      <div class="sidenav-menu-heading">People</div>
        <SideBarNavLink
        routerName="Advisory Team"
        icon="teacher.svg"
        :isActive="isPageActive('Advisory Team')"
        @didClickNavLink = "didClickSideBarLink"
        />
        <SideBarNavLink
        routerName="Pupils"
        icon="pupils.svg"
        :isActive="isPageActive('Pupils')"
        @didClickNavLink = "didClickSideBarLink"
        />
        <!-- <SideBarNavLink
        routerName="Contacts"
        icon="contacts.svg"
        :isActive="isPageActive('Contacts')"
        @didClickNavLink = "didClickSideBarLink"
        /> -->

      <div class="sidenav-menu-heading">Authorities and Schools</div>
        <SideBarNavLink
        routerName="Authorities"
        icon="authorities.svg"
        :isActive="isPageActive('Authorities')"
        @didClickNavLink = "didClickSideBarLink"
      />
        <SideBarNavLink
          routerName="Schools"
          icon="school.svg"
          :isActive="isPageActive('Schools')"
          @didClickNavLink = "didClickSideBarLink"
        />
      <div class="sidenav-menu-heading">Information Technology</div>
      <SideBarNavLink
        routerName="Hardware"
        icon="laptop.svg"
        :isActive="isPageActive('Hardware')"
        @didClickNavLink = "didClickSideBarLink"
      />
      <SideBarNavLink
        routerName="Software"
        icon="software.svg"
        :isActive="isPageActive('Software')"
        @didClickNavLink = "didClickSideBarLink"
      />
      <!-- <div class="sidenav-menu-heading">Staff Ticketing</div>
      <SideBarNavLink
        routerName="Staff Tickets"
        icon="ticket.svg"
        :isActive="isPageActive('Tickets')"
        @didClickNavLink = "didClickSideBarLink"
      />
      <SideBarNavLink
        routerName="Log Ticket"
        icon="ticket.svg"
        :isActive="isPageActive('Tickets')"
        @didClickNavLink = "didClickSideBarLink"
      /> -->
      <!-- <div class="sidenav-menu-heading">Technician Ticketing</div> -->
      <!-- <SideBarNavLink
        routerName="Technician Ticket"
        icon="ticket.svg"
        :isActive="isPageActive('Tickets')"
        @didClickNavLink = "didClickSideBarLink"
      /> -->
      <!-- <div class="sidenav-menu-heading">Ticket Queue</div>
      <SideBarNavLink
        routerName="Technician Tickets"
        icon="ticket.svg"
        :isActive="isPageActive('Tickets')"
        @didClickNavLink = "didClickSideBarLink"
      /> -->
    </div>
</template>

<script>

import SideBarNavLink from '@/components/SideBarNavLink.vue'
import logo from '@/assets/logo.jpg';

export default {
  name: 'SideBar',
  data() {
    return {
      activeRouterName: 'Dashboard',
      logoSrc: logo,
    }
  },
  components: {
    SideBarNavLink
  },
  methods: {
    didClickSideBarLink(routerName) {
      this.activeRouterName = routerName
    },
    isPageActive(routerName) {
      return this.activeRouterName == routerName
    }
  },
}
</script>
