<template>
  <body class="nav-fixed">
    <div v-if="shouldShowLoginScreen">
      <router-view />
    </div>
    <div v-else id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <nav class="sidenav shadow-right sidenav-light">
          <!-- Sidenav Menu-->
          <div class="sidenav-menu">
            <SideBarMenu />
          </div>
          <!-- Sidenav Footer-->
          <div class="sidenav-footer">
            <div class="btn-group" style="width:100%">
              <button class="btn btn-dark" type="button" @click="logOut()">Logout</button>
            </div>
            <div class="sidenav-footer-content">
            </div>
          </div>
        </nav>
      </div>
      <div id="layoutSidenav_content">
        <main>
          <!-- Main page content-->
          <div class="container-fluid px-4">
            <router-view/>
          </div>
        </main>
      </div>
  </div>
  </body>
</template>

<script setup>
import { computed } from 'vue';
import router from './router';
import { useCookies } from 'vue3-cookies';
import SideBarMenu from '@/components/SideBarMenu.vue';
import { useRoute } from 'vue-router';

const { cookies } = useCookies();

const route = useRoute();
const shouldShowLoginScreen = computed(() => route.path === "/login");

async function logOut() {
  fetch('https://be.pencasolutions.com/logout/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
    },
    credentials: 'include',
  }).then(res => {
    if (res.status === 200 || res.status === 303) {
      cookies.remove('jwt');
      router.push('/');
    }
  });
}

</script>
