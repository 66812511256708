<template>
      <div class="card plr-5 mt-3">
  <div class="card-header">
    Equipment Request Tickets
  </div>
  </div> 
  
  <div class="card plr-5 mt-3">
  <div class="card-header">
    Internal Tickets
  </div>
  </div>
</template>
<script>
// import 'vue-good-table-next/dist/vue-good-table-next.css'
// import { VueGoodTable } from 'vue-good-table-next'

export default {
    name: "TechDash",
    // components: {
        // VueGoodTable
    // }
}
</script>