<template>
  <div class="card plr-5 mt-3">
  <div class="card-header">
    My Pupils
  </div>
  <vue-good-table
  :columns="myPupilsColumns"
  :rows="props.myPupils"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: false,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span v-if="props.column.field == 'uniqueCode'">
      <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
      </PupilStatusIndicator>
      <p v-if="isSecondaryTeacher(props.row)"> (Secondary)</p>
    </span>
    <span v-if="props.column.field == 'firstName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.firstName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'lastName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.lastName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'school.name'">
      <router-link :to="{ name: 'displayschool', params: {id: props.row.school._id} }">
        {{props.row.school.name}}
      </router-link>
    </span>
  </template>
  </vue-good-table>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import PupilStatusIndicator from '../../../../components/PupilStatusIndicator.vue'

let props = defineProps({
  myPupils: Array,
  requestedTeacherId: String
})

let myPupilsColumns = [
  {
    label: 'ID',
    field: 'uniqueCode',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'First Name',
    field: 'firstName',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Last Name',
    field: 'lastName',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'School',
    field: 'school.name',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Year Group',
    field: 'yearGroup',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Category',
    field: 'category',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
  {
    label: 'Status',
    field: 'status',
    sortable: true,
    globalSearchDisabled: true,
    filterOptions: {
      enabled: true
    },
  },
]
function isSecondaryTeacher(pupil) {
  return pupil.teachers?.map(teacher => teacher._id).includes(props.requestedTeacherId);
}
</script>
