<template lang="html">
  
  <div class="row justify-content-center">
    <div class="col-lg-5">
      <div class="card border-0 rounded-lg mt-5">
        <div class="card-header bg-white d-flex justify-content-center align-items-center">
    <img :src="logo" alt="" style="padding: 10px;">
</div>

        <div class="card-body">

          <div class="form-group">
            <label class="small mb-1" for="inputEmailAddress">Email</label>
            <input class="form-control py-4" id="inputEmailAddress" input name="username" type="email" placeholder="Enter email address" required>
          </div>

          <div class="form-group">
            <label class="small mb-1" for="inputPassword">Password</label>
            <input class="form-control py-4" input name="password" id="inputPassword" type="password" placeholder="Enter password" required>
          </div>

          <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
            <button @click="login()" class="btn btn-primary">Login</button>
          </div>
        </div>
      </div>
    </div>
</div>

</template>

<script setup>
import logo from '@/assets/logo.jpg';
import router from './router'
async function login() {
  let username = document.getElementById('inputEmailAddress').value
  let password = document.getElementById('inputPassword').value
  document.getElementById('inputEmailAddress').value = ""
  document.getElementById('inputPassword').value = ""
  let body = { username, password }
  let res = await fetch('https://be.pencasolutions.com/login/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(body)
  })
  if (res.status == 200) {
    router.push( '/dashboard' )
  } else {
    document.getElementById('inputEmailAddress').value = ""
    document.getElementById('inputPassword').value = ""
  }
}
</script>