<template>
    <div v-if="shouldDisplayUniqueCode" class="circleBesideText" :class="circleClass(pupilStatus)">  
        {{ pupilUniqueCode }}
    </div>
    <div v-else class="circleBesideText" :class="circleClass(pupilStatus)"></div>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps({
    pupilStatus: String,
    pupilUniqueCode: String,
    shouldDisplayUniqueCode: {
      type: Boolean,
      default: true
    }
})
function circleClass(value) {
    switch (value) {
    case 'OK': return 'circleBesideTextOk';
    case 'Follow Up': return 'circleBesideTextContact'
    case 'Contact / Chase': return 'circleBesideTextFollowUp';
    default: return '';
    }
}
</script>
<style>
.circleBesideText {
  position: relative;
  padding-left: 20px;
  /* space for the circle plus some gap */
}

.circleBesideText::before {
  content: "";
  /* empty content for the pseudo-element */
  position: absolute;
  left: 0;
  top: 50%;
  /* centering the circle vertically */
  transform: translateY(-50%);
  /* centering correction */
  width: 12px;
  /* size of the circle */
  height: 12px;
  /* size of the circle */
  border-radius: 50%;
  /* makes it a circle */
}

.circleBesideTextOk::before {
  background-color: green;
  /* circle color */
}

.circleBesideTextContact::before {
  background-color: orange;
  /* circle color */
}

.circleBesideTextFollowUp::before {
  background-color: red;
  /* circle color */
}
</style>