<template lang="html">
  <div class="card mb4">
  <div class="card-header">
    Services
    <button @click="stopEditingService()" class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#createService">Add Service</button>
    <button v-show="false" class="btn btn-sm btn-success text-light" id="presentservicemodal" type="button" data-bs-toggle="modal" data-bs-target="#createService">Add Service</button>
  </div>
    <vue-good-table
    :columns="serviceColumns"
    :rows="services"
    :pagination-options="{
     enabled: true,
     mode: 'pages'
    }"
    :search-options="{
     enabled: true,
     skipDiacritics: true,
    }">
    <template #table-row="props">
    <span v-if="props.column.field == 'actions'">
      <button class="btn btn-datatable btn-icon btn-transparent-dark" @click="editService(props.row)">
        <i class="fa-solid fa-pencil"></i>
      </button>
      <button class="btn btn-datatable btn-icon btn-transparent-dark" @click="deleteService(props.row)">
        <i class="fa-solid fa-trash"></i>
      </button>
    </span>
  </template>
    </vue-good-table>
  </div>

  <!-- Pupil service modal -->
  <div class="modal fade" id="createService" tabindex="-1" role="dialog" aria-labelledby="createService" aria-hidden="true">
      <div class="modal-dialog modal-m" role="document">
        <form @submit.prevent="submitService" method="POST">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 v-if="isEditingExistingService" class="modal-title">Update Service</h5>
                  <h5 v-else class="modal-title">Create Service</h5>
                  <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                    <div class="row">
                      <div class="mb-3 col-lg-4">
                        <label for="name">Name</label>
                        <input v-if="isEditingExistingService" class="form-control form-control" id="name" name="name" type="text" :value="serviceToEdit.name" required>
                        <input v-else class="form-control form-control" id="name" name="name" type="text" value= "" placeholder="Dropbox..." required>
                      </div>
                      <div class="mb-3 col-lg-4">
                        <label for="username">Username</label>
                        <input v-if="isEditingExistingService" class="form-control form-control" id="username" name="username" type="text" :value="serviceToEdit.username">
                        <input v-else class="form-control form-control" id="username" name="username" type="text" value= "" placeholder="user@dropbox.com">
                      </div>
                      <div class="mb-3 col-lg-4">
                        <label for="password">Password</label>
                        <input v-if="isEditingExistingService" class="form-control form-control" id="password" name="password" type="text" :value="serviceToEdit.password">
                        <input v-else class="form-control form-control" id="password" name="password" type="text" value= "">
                      </div>
                    </div>
                    <div class="mb-0">
                      <label for="notes">Notes</label>
                      <textarea v-if="isEditingExistingService" class="form-control form-control" id="notes" name="notes" rows="3" :value="serviceToEdit.notes"></textarea>
                      <textarea v-else class="form-control form-control" id="notes" name="notes" rows="3" value= ""></textarea>
                    </div>
              </div>
              <div class="modal-footer">
                <button v-if="isEditingExistingService" class="btn btn-secondary">Update Service</button>
                <button v-else class="btn btn-secondary">Create Service</button>
                <button class="btn btn-primary" id="closeservicemodal" type="button" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
        </form>
      </div>
  </div>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'

export default {
  components: {
    VueGoodTable
  },
  props: {
    isActivePupil: Boolean
  },
  created() {
    this.fetchServices()
  },

  data() {
    return {
      serviceColumns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Username',
          field: 'username',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Password',
          field: 'password',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
      ],
      services: [],
      isEditingExistingService: false,
      serviceToEdit: [],
    }
  },
  methods: {
      async fetchServices() {
        const pupilId = this.$route.params.id
        const res = await fetch('https://be.pencasolutions.com/pupils/' + pupilId + '/service', {
          credentials: 'include',
        })
        this.services = await res.json()
      },

      async submitService() {
        const { name, username, password, notes } = Object.fromEntries(new FormData (event.target) )
        const pupilId = this.$route.params.id

        const serviceSubmission = {
          name,
          username,
          password,
          notes,
          pupilId
        }

        if (this.isEditingExistingService) {
          serviceSubmission.pupilServiceId = this.serviceToEdit._id
        }

        const method = this.isEditingExistingService ? "PATCH" : "POST"

        await fetch('https://be.pencasolutions.com/pupils/' + pupilId + '/service', {
          method: method,
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(serviceSubmission),
        })
        document.getElementById('closeservicemodal').click()
        this.isEditingExistingService = false
        this.fetchServices()
      },
      async deleteService(serviceRow) {
        const pupilId = this.$route.params.id
        const serviceToDelete = serviceRow._id
        const body = {
          serviceToDelete
        }
        if(confirm("Are you sure you want to delete service " + serviceRow.name + "?")){
          await fetch('https://be.pencasolutions.com/pupils/' + pupilId + '/service', {
            method: 'DELETE',
            headers: {
              'Content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(body),
          })
        }
        this.isEditingExistingService = false
        this.fetchServices()
      },
      async editService(serviceRow) {
        this.isEditingExistingService = true
        this.serviceToEdit = serviceRow
        document.getElementById('presentservicemodal').click()
      },
      async stopEditingService() {
        this.isEditingExistingService = false
      },
  }
}
</script>
