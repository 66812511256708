<template>
  <CategoryAnalyticsTable/>
  <!-- <TeacherAnalyticsTable/> -->
</template>

<script>
import CategoryAnalyticsTable from '../Analytics/CategoryAnalyticsTable.vue'
// import TeacherAnalyticsTable from '../Analytics/TeacherAnalyticsTable.vue'

export default {
  name: 'Analytics',
  components: { CategoryAnalyticsTable  },
}
</script>

