<template>
  <h1>
    {{authority.name}}
    <button @click="toggleEdit()" class="btn btn-sm btn-dark text-light active me-2 float-right">Edit</button>
  </h1>
  <hr>
  <div class="mb-3 col-lg12">
    <div class="card mb4 pl-5 mt-3">
  <div class="card-header">Authority Information</div>
  <div class="card-body">
<div class="row">
    <div class="mb-3 col-lg-6">
      <label for="name">Authority Name</label>
      <input v-if="isEditing === false" class="form-control form-control" id="name" name="name" type="text" v-model="authority.name" readonly>
      <input v-else class="form-control form-control" id="name" name="name" type="text" v-model="editedAuthority.name" required>
    </div>
    <div class="mb-3 col-lg-6">
      <label for="abbreviation">Authority Abbreviation</label>
      <input v-if="isEditing === false" class="form-control form-control" id="abbreviation" name="abbreviation" type="text" v-model="authority.abbreviation" readonly>
      <input v-else class="form-control form-control" id="abbreviation" name="abbreviation" type="text" v-model="editedAuthority.abbreviation" required>
    </div>
  </div>
    <div class="mb-3">
      <label for="website">Website</label>
      <div v-if="authority.website === ''">
        <input v-if="isEditing === false" class="form-control form-control" id="website" name="website" type="url" v-model="authority.website" readonly>
        <input v-else class="form-control form-control" id="website" name="website" type="url" v-model="editedAuthority.website">
      </div>
      <div v-if="authority.website != ''">
        <div v-if="isEditing === false ">
          <a :href="'//' + authority.website" target="_blank">
            <input class="form-control form-control" id="website" name="website" type="url" v-model="authority.website" readonly>
          </a>
        </div>
        <div v-if="isEditing === true ">
          <input class="form-control form-control" id="website" name="website" type="url" v-model="editedAuthority.website">
        </div>
      </div>
    </div>
    <div class="mb-0">
      <label for="process">Authority Process</label>
      <textarea v-if="isEditing === false" class="form-control form-control" id="process" name="process" rows="3" v-model="authority.process" readonly></textarea>
      <textarea v-else class="form-control form-control" id="process" name="process" rows="3" v-model="editedAuthority.process"></textarea>
    </div>
  </div>
  </div>
</div>
<div v-if="isEditing === true" class="mb-4 pl-5 mt-3">
  <button @click="updateAuthority" id="submitnewauthoritybtn" class="btn btn-secondary">Update Authority</button>
  <button @click="cancelEditing" class="btn btn-primary">Cancel</button>
</div>

<div v-show="isEditing === false" class="mb-3 col-lg12">
<h3>Schools</h3>
  <vue-good-table
 :columns="schoolColumns"
 :rows="schoolRows"
 :pagination-options="{
   enabled: true,
   mode: 'pages'
 }"
 :search-options="{
   enabled: true,
   skipDiacritics: true,
 }">
 <template #table-row="props">
   <router-link :to="{ name: 'displayschool', params: {id: props.row._id} }">
     {{props.formattedRow[props.column.field]}}
   </router-link>
 </template>
</vue-good-table>
</div>

<div v-if="fetched" v-show="isEditing === false" class="mb-3 col-lg12">
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills" id="cardPill" role="tablist">
        <li class="nav-item"><a class="nav-link active" id="interactions-pill" href="#interactionspill" data-bs-toggle="tab" role="tab" aria-controls="interactions" aria-selected="true">Authority Interactions</a></li>
        <li class="nav-item"><a class="nav-link" id="pupilinteractions-pill" href="#pupilinteractionspill" data-bs-toggle="tab" role="tab" aria-controls="pupilinteractions" aria-selected="true">Pupil Interactions</a></li>
      </ul>
    </div>
    <div class="tab-content" id="cardPillContent">
      <div class="tab-pane fade show active" id="interactionspill" role="tabpanel" aria-labelledby="interaction-pill">
          <StaffInteractionUpdated
          :targetType='"localAuthority"'
          :isAuthoritySecondaryDisplay='false'
          :isDisplayingOnPupilPage='false'
          :localAuthorityId='id'
          />
      </div>
      <div class="tab-pane fade" id="pupilinteractionspill" role="tabpanel" aria-labelledby="pupilinteractions-pill">
        <StaffInteractionUpdated
          :targetType='"localAuthority"'
          :isAuthoritySecondaryDisplay='true'
          :isDisplayingOnPupilPage='false'
          :localAuthorityId='id'
          />
      </div>
    </div>
  </div>
</div>




<!-- <div v-show="isEditing === false" class="mb-3 col-lg12">
  <h3>Interactions</h3>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills card-header-pills" id="cardPill" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="authority-pill" href="#authorityPill" data-bs-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Authority Interactions </a></li>
        <li class="nav-item"><a class="nav-link" id="pupil-pill" href="#pupilPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false">Pupil Interactions</a></li>
      </ul>
    </div>
    <div class="tab-content" id="cardPillContent">
      <div class="tab-pane fade show active" id="authorityPill" role="tabpanel" aria-labelledby="authority-pill">
          <StaffInteractions
          :targetType='"localAuthority"'
          :localAuthorityId='id'
          />
      </div>
      <div class="tab-pane fade" id="pupilPill" role="tabpanel" aria-labelledby="pupil-pill">
          <StaffInteractions
            :targetType='"localAuthority"'
            :isAuthoritySecondaryDisplay='true'
            :localAuthorityId='authority._id'
          />
      </div>
    </div>
  </div>
</div> -->

<!-- <div v-show="isEditing === false" class="col-lg-12 col-md-6 col-sm-6 col-xs-6">
  <div class="card mb4 pl-5 mt-3">
  <div class="card-header">Pupils</div>
<vue-good-table
:columns="pupilColumns"
:rows="authority.pupils"
:pagination-options="{
 enabled: true,
 mode: 'pages'
}"
:search-options="{
 enabled: true,
 skipDiacritics: true,
}">
<template #table-row="props">
  <span v-if="props.column.field == 'category'
  || props.column.field == 'yearGroup'">
    {{props.formattedRow[props.column.field]}}
 </span>
 <span v-else>
   <router-link :to="{ name: 'displaypupil', params: {id: props.row._id } }">
   {{props.formattedRow[props.column.field]}}
   </router-link>
 </span>
</template>
</vue-good-table>
</div>
</div> -->

<div v-show="isEditing === false" class="mb-3 col-lg12">
  <h3>Contacts</h3>
  <ContactsTable @didupdatecontacts="didupdatecontacts"
   :createContactURL='createContactURL'
   :deleteContactURL='deleteContactURL'
   :contacts = "authority.contacts"
   :showPupilsSchoolContacts = "false"
  />
</div>


<div class="container p-5">
<!-- Contacts Modal -->
<div class="modal fade" id="createContactModal" tabindex="-1" role="dialog" aria-labelledby="createContactModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 v-if="isEditingExistingContact === true" class="modal-title">Edit Contact</h5>
        <h5 v-else class="modal-title">Create Contact</h5>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
        <div class="mb-3 col-lg-4">
          <label for="name">Name*</label>
          <input class="form-control form-control" id="name" input name="name" type="text" placeholder="John Smith" v-model="selectedContact.name" required>
        </div>
        <div class="mb-3 col-lg-4">
          <label for="role">Role*</label>
          <input class="form-control form-control" id="role" input name="role" type="text" placeholder="Support" v-model="selectedContact.role" required>
        </div>
        <div class="mb-3 col-lg-4">
          <label for="email">Email</label>
          <input class="form-control form-control" id="email" input name="email" type="email" placeholder="john.smith@example.com" v-model="selectedContact.email">
        </div>
        <div class="mb-3 col-lg-4">
          <label for="phoneNumber">Phone</label>
          <input class="form-control form-control" id="phoneNumber" input name="phoneNumber" type="text" placeholder="02031009321" v-model="selectedContact.phoneNumber">
        </div>
        <div class="mb-3 col-lg-4">
          <label for="phoneExtension">Extension</label>
          <input class="form-control form-control" id="phoneNumber" input name="extensionNumber" type="text" placeholder="08" v-model="selectedContact.extensionNumber">
        </div>
        <div class="mb-3 col-lg-4">
          <label for="mobileNumber">Mobile</label>
          <input class="form-control form-control" id="phoneNumber" input name="mobileNumber" type="text" placeholder="07440394423" v-model="selectedContact.mobileNumber">
        </div>
      </div>
      </div>
      <div class="modal-footer">
        <button @click="saveContactClicked" class="btn btn-secondary">Save Contact</button>
        <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

</div>


</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import router from '../../router'
import ContactsTable from '../../components/ContactsTable.vue'
// import StaffInteractions from '../../components/StaffInteractions.vue'
import StaffInteractionUpdated from '../../components/StaffInteractions/StaffInteractionUpdated.vue'

export default {
  name: 'GetAuthority',
  emits: ['updatepagetitle', 'shouldShowEditBtn'],
  data() {
    return {
      fetched: false,
      createContactURL: 'https://be.pencasolutions.com/authorities/' + this.$route.params.id + '/contacts/',
      deleteContactURL: 'https://be.pencasolutions.com/authorities/' + this.$route.params.id + '/contacts/',
      schoolColumns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        }
      ],
      schoolRows: [],
      // pupilColumns: [
      //   {
      //     label: 'Unique Code',
      //     field: 'uniqueCode',
      //     sortable: true,
      //     // globalSearchDisabled: true,
      //     // filterOptions: {
      //     //   enabled: true
      //     // },
      //   },
      //   {
      //     label: 'First Name',
      //     field: 'firstName',
      //     sortable: true,
      //     // globalSearchDisabled: true,
      //     // filterOptions: {
      //     //   enabled: true
      //     // },
      //   },
      //   {
      //     label: 'Last Name',
      //     field: 'lastName',
      //     sortable: true,
      //     // globalSearchDisabled: true,
      //     // filterOptions: {
      //     //   enabled: true
      //     // },
      //   },
      //   {
      //     label: 'Year Group',
      //     field: 'yearGroup',
      //     sortable: true,
      //     // globalSearchDisabled: true,
      //     // filterOptions: {
      //     //   enabled: true
      //     // },
      //   },
      //   {
      //     label: 'Category',
      //     field: 'category',
      //     sortable: true,
      //     // globalSearchDisabled: true,
      //     // filterOptions: {
      //     //   enabled: true
      //     // },
      //   },
      // ],
      // pupilRows: [],
      contactColumns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Role',
          field: 'role',
          sortable: true,
        },
        {
          label: 'Phone & Extension',
          field: 'phoneNumber',
          sortable: true,
        },
        {
          label: 'Email',
          field: 'email',
          sortable: true,
        },
        {
          label: 'Actions',
          field: 'actions',
        }
      ],
      contactRows: [],
      id: String,
      isEditing: false,
      authority: [],
      editedAuthority: {
        name: String,
        abbreviation: String,
        website: String,
        process: String
      },
      selectedContact: {
        id: String,
        name: String,
        phoneNumber: String,
        extensionNumber: String,
        mobileNumber: String,
        email: String,
        role: String,
      },
      isEditingExistingContact: false,
    }
  },
  components: {
    VueGoodTable,
    ContactsTable,
    // StaffInteractions,
    StaffInteractionUpdated
  },
  async created() {
    this.$emit('updatepagetitle', 'Local Authority')
    this.$emit('shouldShowEditBtn', true)
    this.id = this.$route.params.id;
    await this.fetchAuthority();
  },
  methods: {
    async fetchAuthority() {
      const res = await fetch('https://be.pencasolutions.com/authorities/' + this.id, {
        method: "GET",
        credentials: 'include',
      })
      const json = await res.json()
      this.authority = json
      this.editedAuthority.name = this.authority.name
      this.editedAuthority.abbreviation = this.authority.abbreviation
      this.editedAuthority.website = this.authority.website
      this.editedAuthority.process = this.authority.process
      this.setupTables();
      this.fetched = true
    },

    setupTables() {
      this.schoolRows = this.authority.schools
      // this.pupilRows = this.authority.pupils
      if('contacts' in this.authority) {
        this.contactRows = this.authority.contacts
      } else {
        this.contactRows = []
      }
    },

    async updateAuthority() {
      this.isEditing = false
      const res = await fetch('https://be.pencasolutions.com/authorities/' + this.id, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(this.editedAuthority)
      })

      this.authority = await res.json()
    },

    async cancelEditing() {
      this.isEditing = false
    },
    navigate() {
      router.go(-1);
    },
    addNewContactClicked() {
      this.isEditingExistingContact = false
      this.selectedContact.id = null
      this.selectedContact.email = null
      this.selectedContact.name = null
      this.selectedContact.role = null
      this.selectedContact.phoneNumber = null
      this.selectedContact.extensionNumber = null
      this.selectedContact.mobileNumber = null
    },

    editContactClicked(contactRow) {
      this.isEditingExistingContact = true
      this.selectedContact.id = contactRow.id
      this.selectedContact.email = contactRow.email
      this.selectedContact.name = contactRow.name
      this.selectedContact.role = contactRow.role
      this.selectedContact.phoneNumber = contactRow.phoneNumber
      this.selectedContact.extensionNumber = contactRow.extensionNumber
      this.selectedContact.mobileNumber = contactRow.mobileNumber
    },

    async resetEditedModel() {
      this.editedAuthority.name = this.authority.name
      this.editedAuthority.abbreviation = this.authority.abbreviation
      this.editedAuthority.website = this.authority.website
      this.editedAuthority.process = this.authority.process
    },
    async didupdatecontacts() {
      //Double calls to reload for some reason?!
      await this.fetchAuthority();
      await this.fetchAuthority();
    },
    toggleEdit() {
      this.isEditing = !this.isEditing
    }
  }
}
</script>
