<template lang="html">
  <form method="POST" action="#" id="createreviewform">

    <div class="card mb4 pl-5 mt-3">
      <div class="card-header">Pupil Information</div>
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-lg-6">
            <label for="name">Pupil Name</label>
              <input class="form-control" id="name" name="name" type="text" v-model="review.metaData.pupil.name" readonly>
          </div>
          <div class="mb-3 col-lg-6">
            <label for="school">School</label>
            <span v-if="review.metaData.school">
              <input class="form-control" id="school" name="school"  v-model="review.metaData.school.name" type="text" readonly>
            </span>
            <span v-else>
              <input class="form-control" id="school" name="school"  value="" type="text" readonly>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-lg-4">
            <label for="yeargroup">Year Group</label>
            <input class="form-control" id="yeargroup" name="yeargroup" type="text"  v-model="review.metaData.pupilYearGroup" readonly>
          </div>
          <div class="mb-4 col-lg-4">
              <label for="startDateTime">Start Time</label>
              <input class="form-control" id="startDateTime" name="startDateTime" :value="moment(review.metaData.startDateTime)" type="text" readonly>
          </div>
          <div class="mb-4 col-lg-4">
              <label for="completionDateTime">End Time</label>
              <input class="form-control" id="completionDateTime" name="completionDateTime" :value="moment(review.metaData.completionDateTime)" type="text" readonly>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-12 mb-4 pl-5 mt-3">
        <div class="card shadow mb-4">

          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">SETT Framework – (Student, Environment, Tasks, Tools)</h6>
          </div>

          <div class="card-body">

            <div class="modal-body col-md-12">
              <h6>Overview</h6>
              <textarea class="form-control" id="overView" v-model="review.overView" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>EHCP</h6>
              <textarea class="form-control" id="ehcpOutcomes" v-model="review.ehcpOutcomes" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>Student</h6>
              <textarea class="form-control" id="student" v-model="review.student" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>Environment and Support</h6>
              <textarea class="form-control" id="environment" v-model="review.environment" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>Tasks</h6>
              <textarea class="form-control" id="tasks" v-model="review.tasks" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>Existing tools and Support</h6>
                <textarea class="form-control" id="existingTools" v-model="review.existingTools" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>Possible tools and Support</h6>
                <textarea class="form-control" id="possibleTools" v-model="review.possibleTools" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>Additional Notes</h6>
                <textarea class="form-control" id="notes" v-model="review.notes" readonly rows=3></textarea>
            </div>

            <div class="modal-body col-md-12">
              <h6>Hardware & Software Request</h6>
              <textarea class="form-control" id="equipmentRequest" v-model="review.equipmentRequest" readonly rows=3></textarea>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- <div class="card-header py-3"> 
          <div class="row">
            <h6 class="m-0 font-weight-bold text-primary">
              Targets
            </h6>
          </div>
        </div>
        <vue-good-table :columns="columns" :rows="review.pupilTargets" :pagination-options="{
          enabled: true,
          mode: 'pages'
        }" :search-options="{
  enabled: false,
  skipDiacritics: true,
}">

          <template #table-row="props">
            <span v-if="props.column.field == 'target'" v-on:mouseover="showFullText(props.row.target)">
              {{ truncatedText(props.row.target) }}
            </span>
            <span v-else-if="props.column.field == 'action'" v-on:mouseover="showFullText(props.row.action)">
              {{ truncatedText(props.row.action) }}
            </span>
            <span v-else-if="props.column.field == 'targetDate'">
              {{ truncatedText(props.row.targetDate) }}
            </span>
          </template>
        </vue-good-table>
  <button type="submit" formmethod="GET" formaction="http://cenmac.pencasolutions.com/pupils/#(review.pupil)" class="btn btn-primary">Back</button> -->
  </form>

</template>

<script>
import moment from 'moment'
import 'vue-good-table-next/dist/vue-good-table-next.css'
// import { VueGoodTable } from 'vue-good-table-next'

// import router from '../../router'
export default {
  name: 'getreview',
  components: {
    // VueGoodTable
  },
  data() {
    return {
      review: [],
      columns: [
        {
          label: 'Target',
          field: 'target',
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Action',
          field: 'action',
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Target Date',
          field: 'targetDate',
          filterOptions: {
            enabled: true
          },
        },
      ],
    }
  },
  created() {
    console.log("used create review")
    this.fetchReview()
  },
  methods: {
    async fetchReview() {
      const currentPageURL = this.$router.currentRoute.value.fullPath
      console.log(currentPageURL)
      const res = await fetch('https://be.pencasolutions.com' + currentPageURL, {
        credentials: 'include'
      })
      this.review = await res.json()
    },
    moment(dateToFormat) {
      return moment.utc(dateToFormat).format('DD/MM/YYYY HH:mm');
    },
    truncatedText(text) {
      return text.length <= 200 ? text : text.slice(0, 200) + '...';
    },
    showFullText: function (text) {
      event.target.title = text;
    },
  }
}
</script>
