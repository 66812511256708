<template>
  <form @submit.prevent="submit" method="POST">
    <!-- School Information Card -->

    <div class="card mb4 pl-5 mt-3">
      <div class="card-header">School Information</div>
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-lg-6">
            <label for="name">School Name *</label>
            <input class="form-control form-control" id="name" name="name" type="text" placeholder="Anderton Primary School" required>
          </div>
          <div class="mb-3 col-lg-6">
            <label for="localAuthorityId">Local Authority *</label>
            <select id="localAuthorityId" input name="localAuthorityId" class="form-control">
              <option v-for="authority in localAuthorities" :value="authority._id" :key="authority" v-bind:selected="index === 0 ? false: true">
                   {{ authority.name }}
              </option>
            </select>

          </div>
        </div>
        <div class="row">

          <div class="mb-3 col-lg-4">
            <label for="inputPhase">Phase *</label>
            <select id="phase" input name="phase" class="form-control">
              <option v-for="phase in phases" :value="phase" :key="phase" v-bind:selected="index === 0 ? false: true">
                   {{ phase }}
              </option>
            </select>
          </div>

          <div class="mb-3 col-lg-4">
            <label for="minimumYeargroup">Lowest Year *</label>
            <select id="minimumYeargroup" input name="minYearGroup" class="form-control">
              <option v-for="year in yearGroups" :value="year" :key="year" v-bind:selected="index === 0 ? false: true">
                {{ year }}
              </option>
            </select>
          </div>

          <div class="mb-3 col-lg-4">
            <label for="maximumYeargroup">Highest Year *</label>
            <select id="maximumYeargroup" input name="maxYearGroup" class="form-control">
              <option v-for="year in yearGroups" :value="year" :key="year" v-bind:selected="index === 0">
                {{ year }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-lg-6">
            <label for="website">Website</label>
            <input class="form-control form-control" id="website" name="website" type="url" placeholder="https://www.surrey.org">
          </div>

          <div class="mb-3 col-lg-6">
            <label class="form-check-label" for="paysInvoicesDirectly">Pays Invoices Directly</label>
            <select id="paysInvoicesDirectly" input name="paysInvoicesDirectlyBool" class="form-control">
                <option value=false>No</option>
                <option value=true>Yes</option>
            </select>
          </div>
        </div>
        <div class="mb-0">
          <label for="transportNotes">Transportation Notes</label>
          <textarea class="form-control form-control" id="transportNotes" name="transportNotes" rows="3"></textarea>
        </div>
      </div>
    </div>

    <CreateAddressInfo />
    <button class="btn btn-primary" formmethod="POST" formaction="https://be.pencasolutions.com/schools">Create School</button>

</form>
</template>

<script>
import CreateAddressInfo from '../../components/AddressInfo/CreateAddressInfo'
import router from '../../router'

export default {
  name: 'CreateSchool',
  emits: ['updatepagetitle'],
  components: {
    CreateAddressInfo
  },
  data() {
    return {
      localAuthorities: [
        {
          _id: String,
          name: String
        }
      ],
      phases: [String],
      yearGroups: [String],
      selectedAuthority: String,
      selectedPhase: String,
      selectedMinYearGroup: String,
      selectedMaxYearGroup: String
    }
  },
  async created() {
    this.fetchCreateSchoolData()
  },
  methods: {
    async fetchCreateSchoolData() {
      const res = await fetch('https://be.pencasolutions.com/schools/createreq', {
        method: "GET",
        credentials: "include",
      })
      const json = await res.json()
      this.localAuthorities = json.localAuthorities
      this.phases = json.phases
      this.yearGroups = json.yearGroups
    },
    async submit(event) {
      event.preventDefault()

      const { name, phase, paysInvoicesDirectlyBool, localAuthorityId, minYearGroup, maxYearGroup, transportNotes,
      website } = Object.fromEntries(new FormData(event.target))

      const { addressLine1, addressLine2, contactNumber, contactNumber2, city, postCode, county } = Object.fromEntries(new FormData (event.target) )

      const address = {
        'firstLine': addressLine1,
        'secondLine': addressLine2,
        'primaryPhone': contactNumber,
        'secondaryPhone': contactNumber2,
         city, postCode, county
      }

      const paysInvoicesDirectly = (paysInvoicesDirectlyBool === "true");

      const newSchool = {
        name, phase, paysInvoicesDirectly, localAuthorityId, minYearGroup, maxYearGroup, transportNotes,
        website, address
      }

      const res = await fetch('https://be.pencasolutions.com/schools/', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(newSchool)
      })
      const resp = await res.json()
      router.push( { name: 'displayschool', params: {id: resp._id }} )
    }
  },
}

</script>
