<template>
  <div class="modal fade" id="addTeacherModal" tabindex="-1" role="dialog" aria-labelledby="addTeacherModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Teachers</h5>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12">
          <div class="row">
            <div class="col-lg-6">
              <select id="selectedteachertoadd">
                <!-- <option v-for="teacher in allTeachers" :value="teacher.id" :key="teacher.id" v-bind:selected="index === 0 ? true: false"> -->
                <option v-for="teacher in allTeachers" :value="teacher.id" :key="teacher.id">
                  {{ teacher.name}}
                </option>
              </select>
            </div>
          </div>
          <br>
        </div>
      </div>
      <div class="modal-footer">
        <button @click="assignAdditionalTeacher()" class="btn btn-success">Add Teacher</button>
        <button class="btn btn-primary" id="closeaddteachermodal" type="button" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AssignTeacherModal',
  props: {
    pupilId: String
  },
  data() {
    return  {
      allTeachers: [],
      createCompletedAssessment: false,
    }
  },
  async created() {
    const res = await fetch('https://be.pencasolutions.com/staff/teachers/', {
      method: "GET",
      credentials: 'include'
    })
    this.allTeachers = await res.json()
  },
  methods: {
    async assignAdditionalTeacher() {
      let url = 'https://be.pencasolutions.com/pupils/' + this.pupilId + '/addteacher'
      const selectedTeacher = document.getElementById('selectedteachertoadd').value
      const body = JSON.stringify({
        'teacherId':selectedTeacher,
        'createCompletedAssessment': false,
        'completedAssessmentDate': null
      })
      await this.sendPostRequest(url, body)
      this.$emit('didAssignAdditionalTeacher')
    },

    async sendPostRequest(url, body) {
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: body
      })
      document.getElementById('closeaddteachermodal').click()
      return resp
    },
  }
}
</script>
