<template>
  <AdminDash v-if="staffRole == 'Administrator'" />
  <TeacherDash v-else-if="staffRole == 'Teacher'" />
  <TechnicianDash v-else-if="staffRole == 'Technician'" />
</template>

<script>
import AdminDash from '../views/Dashboards/Admin/AdminDash.vue'
import TeacherDash from '../views/Dashboards/Teacher/TeacherDash.vue'
import TechnicianDash from '../views/Dashboards/Technician/TechDash.vue'
export default {
  name: 'Dashboard',
  data() {
    return {
      staffRole: String
    }
  },
  components: {
    AdminDash,
    TeacherDash,
    TechnicianDash,
  },
  async created() {
    //Update sidebar and dashboard
    const res = await fetch('https://be.pencasolutions.com/dashboard', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include',
    })
    const json = await res.json()
    this.staffRole = json.role
  }
}
</script>
