<template lang="html">
  <div class="card mb4 plr-5 mt-3">
    <span v-if="isDisplayingNonPupilAddress">
      <div class="card-header">Contact Information</div>
    </span>
    <span v-else>
      <div class="card-header">Home Address</div>
    </span>
    <div class="card-body">

      <span v-if="isDisplayingNonPupilAddress">
        <div class="row">
          <div class="mb-3 col-lg-6">
            <label for="contactNumber">Primary Phone</label>
            <input type="text" class="form-control" input name="contactNumber" id="contactNumber" :value="address.primaryPhone" readonly>
          </div>
          <div class="mb-3 col-lg-6">
            <label for="contactNumber2">Secondary Phone</label>
            <input type="text" class="form-control" input name="contactNumber2" id="contactNumber2" :value="address.secondaryPhone" readonly>
          </div>
        </div>
      </span>

      <div class="row">
        <div class="mb-3 col-lg-6">
          <label for="addressLine1">Address Line 1</label>
          <input type="text" class="form-control" input name="addressLine1" id="addressLine1" :value="address.firstLine" readonly>
        </div>
        <div class="mb-3 col-lg-6">
          <label for="addressLine2">Address Line 2</label>
          <input type="text" class="form-control" input name="addressLine2" id="addressLine2" :value="address.secondLine" readonly>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-lg-4">
          <label for="city">City</label>
          <input type="text" class="form-control" input name="city" id="city" :value="address.city" readonly>
        </div>
        <div class="mb-3 col-lg-4">
          <label for="county">County</label>
          <input type="text" class="form-control" input name="county" id="county" :value="address.county" readonly>
        </div>
        <div class="mb-3 col-lg-4">
          <label for="postcode">Postcode</label>
          <input type="text" class="form-control" input name="postcode" id="postcode" :value="address.postCode" readonly>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayAddressInfo",
  props: {
    address: {},
    isDisplayingNonPupilAddress: {
      type: Boolean,
      default: true,
    }
  },
}
</script>
