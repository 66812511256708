<template>
    <div class="modal-body col-md-12">
      <h6>{{ title }}</h6>
      <label :for="inputId">{{ description }}</label>
      <textarea v-if="previousReviewValue" class="form-control" :id="inputId + 'Prev'" 
        :value="previousReviewValue" readonly rows=3></textarea>
      <textarea class="form-control" :id="inputId" :name="inputName" rows=3 :value="vModel" @input="updateInputValue"></textarea>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      description: String,
      inputId: String,
      inputName: String,
      previousReviewValue: String,
      vModel: String
    },
    methods: {
      updateInputValue(event) {
        this.$emit('update:vModel', event.target.value);
      }
    }
  };
  </script>