<template>
  <form @submit.prevent="submit">
  <div class="mb-3 col-lg12">
    <div class="card mb4 pl-5 mt-3">
  <div class="card-header">Authority Information</div>
  <div class="card-body">
<div class="row">
    <div class="mb-3 col-lg-6">
      <label for="name">Authority Name</label>
      <input class="form-control form-control" id="name" name="name" type="text">
    </div>
    <div class="mb-3 col-lg-6">
      <label for="abbreviation">Authority Abbreviation</label>
      <input class="form-control form-control" id="abbreviation" name="abbreviation" type="text">
    </div>
  </div>
    <div class="mb-3">
      <label for="website">Website</label>
        <input class="form-control form-control" id="website" name="website" type="url">
      </div>
    <div class="mb-3">
      <label for="process">Authority Process</label>
      <textarea class="form-control form-control" id="process" name="process" rows="3"></textarea>
    </div>
  </div>
</div>
  </div>
  <div class="mb4 pl-5 mt-3">
  <button type="submit" id="submitnewauthoritybtn" class="btn btn-primary">Create Authority</button>
</div>
</form>

</template>

<script>
import router from '../../router'

export default {
  name: 'CreateAuthority',
  emits: ['updatepagetitle'],
  data() {
    return {
      // name: String,
      // abbreviation: String,
      // website: String,
      // process: String
    }
  },
  async created() {
    this.$emit('updatepagetitle', "Create Authority")
  },
  methods: {
    async submit(event) {
      console.log("event called")
      event.preventDefault()
      const { name, abbreviation, website , process } = Object.fromEntries(new FormData(event.target))
      const res = await fetch('https://be.pencasolutions.com/authorities/', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({name, abbreviation, website, process })
      })
      const resp = await res.json()
      router.push({ name: 'displayauthority', params: { id: resp._id } })
    },
  },
}

</script>
