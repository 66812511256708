<template>
  <span v-if="hasFetchedPupils">
    <h1> {{teacherName}} - Dashboard </h1>
    <hr>
    <BookAssessmentsTable
      :pupilsToBook="pupilsAwaitingAssessment"
      :requestedTeacherId="requestedTeacherId"
      @clickedReschedule = "showScheduleModal"
    />

    <ScheduledAssessmentsTable
      :scheduledPupils="pupilsAssessmentScheduled"
      :requestedTeacherId="requestedTeacherId"
      :assessmentTable="true"
      @clickedReschedule = "showScheduleModal"
    />

    <BookReviewsTable
      :pupilsToBook="pupilsAwaitingReview"
      :requestedTeacherId="requestedTeacherId"
      @clickedReschedule = "showScheduleModal"
    />

    <ScheduledPupilsTable
      :scheduledPupils="pupilsReviewScheduled"
      :requestedTeacherId="requestedTeacherId"
      :reviewTable="true"
      @clickedReschedule = "showScheduleModal"
    />

    <MyPupilsTable
      :myPupils="myPupils"
      :requestedTeacherId="requestedTeacherId"
    />

    <!-- <HardwareRequestsTable
      :myEquipmentRequests="myEquipmentRequests"
      @savedequipmentreq = "savedEquipReq(equipment)"
      @diddeleteequipment = "didDeleteEquipment(equipment)"
    /> -->
  </span>

  <span v-if="this.shouldShowScheduleModal">
  <RescheduleReviewModal
    :pupil="rescheduledPupil"
    :teacher="rescheduledPupilTeacher"
    @didSchedulePupil = "didSchedulePupil()"
    @close="shouldCloseScheduleModal()"
  />
  </span>
</template>

<script>
import BookAssessmentsTable from '../../../views/Dashboards/Teacher/Tables/BookAssessmentsTable.vue'
import ScheduledAssessmentsTable from '../../../views/Dashboards/Teacher/Tables/ScheduledAssessmentsAndReviews.vue'
import BookReviewsTable from '../../../views/Dashboards/Teacher/Tables/BookReviewsTable.vue'
import ScheduledPupilsTable from '../../../views/Dashboards/Teacher/Tables/ScheduledAssessmentsAndReviews.vue'
import MyPupilsTable from '../../../views/Dashboards/Teacher/Tables/MyPupils.vue'
import RescheduleReviewModal from '../../../components/RescheduleReviewModal.vue'
// import HardwareRequestsTable from '../../../views/Dashboards/Teacher/Tables/HardwareRequests.vue'

export default {
  data() {
    return {
      hasFetchedPupils: false,
      shouldShowScheduleModal: false,
      teacherName: "",
      requestedTeacherId: "",
      myPupils: {},
      rescheduledPupil: {},
      myEquipmentRequests: {},
    }
  },
  components: {
    BookAssessmentsTable,
    ScheduledPupilsTable,
    BookReviewsTable,
    ScheduledAssessmentsTable,
    MyPupilsTable,
    RescheduleReviewModal,
    // HardwareRequestsTable
  },
  async created() {
    await this.fetchTeacherDashboard()
  },
  computed: {
    pupilsAwaitingAssessment() {
      return this.myPupils.filter(({ status }) => status === 'Awaiting Assessment');
    },
    pupilsAwaitingReview() {
      return this.myPupils.filter(({ status }) => status === 'Awaiting Review');
    },
    pupilsAssessmentScheduled() {
      return this.myPupils.filter(({ status }) => status === 'Assessment Scheduled');
    },
    pupilsReviewScheduled() {
      return this.myPupils.filter(({ status }) => status === 'Review Scheduled');
    },
  },
  methods: {

    async fetchTeacherDashboard() {
      const res = await fetch('https://be.pencasolutions.com/dashboard/teacher', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })

      const json = await res.json()
      this.hasFetchedPupils = true
      this.teacherName = json.teacherName
      this.myPupils = json.myPupils
      this.myEquipmentRequests = json.myEquipmentRequests
      this.requestedTeacherId = json.requestedTeacherId
    },

    async didSchedulePupil() {
      this.shouldCloseScheduleModal()
      await this.fetchTeacherDashboard()
    },

    async showScheduleModal(pupilId) {
      this.shouldShowScheduleModal = true
      await this.fetchPupilForReschedule(pupilId)
      document.getElementById('showScheduleModalBtn').click()
    },

    shouldCloseScheduleModal() {
      this.rescheduledPupil = {}
      this.rescheduledPupilTeacher = {}
      this.shouldShowScheduleModal = false
      document.getElementById('closeschedulemodal').click()
    },

    async fetchPupilForReschedule(pupilId) {
      const res = await fetch('https://be.pencasolutions.com/pupils/' + pupilId, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include'
      })
      this.rescheduledPupil = await res.json()
    },

    async savedEquipReq() {
      await this.fetchTeacherDashboard()
    },
    async didDeleteEquipment() {
      await this.fetchTeacherDashboard()
    },
  }
}
</script>
