<template>
  <div class="modal fade" id="updatereviewdatemodal" tabindex="-1" role="dialog" aria-labelledby="updatereviewdatemodal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change {{ rescheduleType }}</h5>
        </div>
        <div class="modal-body">
          <div class="mb-4 col-lg-12">
            <label for="date">Change {{ rescheduleType }}</label>
            <input id="dateTime" input name="dateTime" class="form-control" type="datetime-local"
              v-bind:value="dateTimeNow" required />
          </div>
        </div>
        <div class="modal-footer">
          <button @click="updateDate()" id="updateDateBtn" class="btn btn-success">Update</button>
          <button class="btn btn-primary" id="closeupdatemodal" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeApprovalDateModal',
  props: {
    pupilId: String,
    rescheduleType: String,
    lastReviewDate: String,
    ehcpDate: String,
    completedAssessmentDate: String
  },
  methods: {
    async updateDate() {
      let baseurl = 'https://be.pencasolutions.com/pupils/' + this.pupilId + '/'
      // let endpoint = this.rescheduleType == "Last Review Date" ? 'lastreviewupdate' : 'assessmentcompletionupdate'
      let endpoint;
      switch (this.rescheduleType) {
        case "Last Review Date":
          endpoint = 'lastreviewupdate';
          break;
        case "ehcpDate":
          endpoint = 'ehcpdateupdate';
          break;
        default:
          endpoint = 'assessmentcompletionupdate';
      }
      let url = baseurl + endpoint
      const body = {
        'updatedDate': document.getElementById('dateTime').value
      }
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body)
      })
      document.getElementById('closeupdatemodal').click()
      this.$emit('didUpdateDate')
    },
  },
  computed: {
    dateTimeNow() {
      let today = new Date()
      let date = today.toISOString().split('T')[0]
      let timeSplit = today.toISOString().split('T')[1].split(':')
      let dateTimeNow = date + 'T' + timeSplit[0] + ":" + timeSplit[1]
      return dateTimeNow
    }
  }
}
</script>
