<template>
    <div class="modal fade" id="reschedulemodal" tabindex="-1" role="dialog" aria-labelledby="reschedulemodal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Schedule {{pupil.firstName}} {{pupil.lastName}}</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="mb-3 col-lg-6">
              <label for="name">Pupil</label>
              <input class="form-control form-control" id="name" v-bind:value="pupilName" name="name" type="text" readonly>
            </div>
            <div class="mb-3 col-lg-6">
              <label for="teacher">Teacher</label>
              <input v-if="pupil.teacher" class="form-control form-control" id="teacher" name="teacher" v-bind:value="pupil.teacher.name" type="text" readonly>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-lg-6">
              <label for="school">School</label>
              <input v-if="pupil.school" class="form-control form-control" id="school" name="school" v-bind:value="pupil.school.name" type="text" readonly>
            </div>
            <div class="mb-4 col-lg-6">
                <label for="dateTime">Date & Time</label>
                <span v-if="pupil.status === 'Awaiting Review' || pupil.status =='Review Scheduled'">
                  <input v-if="pupil.scheduledReviewDate" id="dateTime" input name="dateTime" class="form-control" type="datetime-local" v-bind:value="pupil.scheduledReviewDate" />
                  <input v-else id="dateTime" input name="dateTime" class="form-control" type="datetime-local" v-bind:value="dateTimeNow"/>
                </span>
                <span v-else-if='pupil.status == "Awaiting Assessment" || pupil.status == "Assessment Scheduled"'>
                  <input v-if="pupil.scheduledAssessmentDate" id="dateTime" input name="dateTime" class="form-control" type="datetime-local" v-bind:value="pupil.scheduledAssessmentDate" />
                  <input v-else id="dateTime" input name="dateTime" class="form-control" type="datetime-local" v-bind:value="dateTimeNow"/>
                </span>
                  <!-- <input id="dateTime" input name="dateTime" class="form-control" type="datetime-local" value="#(pupil.scheduledAssessmentDate)" /> -->
            </div>
          </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="schedule()">Schedule</button>
            <button @click="$emit('close')" class="btn btn-primary" id="closeschedulemodal" type="button" data-bs-dismiss="modal">Cancel</button>
            <!-- <button class="btn btn-success">Reject</button> -->
            <!-- <button @click="$emit('close')" class="btn btn-primary" id="closerejectmodal" type="button" data-bs-dismiss="modal">Close</button> -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    pupil: {},
  },
  computed: {
    pupilName() {
      return this.pupil.firstName + ' ' + this.pupil.lastName
    },
    dateTimeNow() {
      let today = new Date()
      let date = today.toISOString().split('T')[0]
      let timeSplit = today.toISOString().split('T')[1].split(':')
      let dateTimeNow = date + 'T' + timeSplit[0] + ":" + timeSplit[1]
      return dateTimeNow
    }
  },
  methods: {
    async schedule() {
      await fetch('https://be.pencasolutions.com/pupils/' + this.pupil._id + '/schedule', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          'dateTime': document.getElementById('dateTime').value
        })
      })
      //Use res.status to check the status code
      this.$emit('didSchedulePupil')
    }
  }
}
</script>
