<template>
  <div>
    <form @submit.prevent="submitForm">
      <div v-for="(device, index) in devices" :key="index" class="w-100 d-flex flex-wrap">
        <div class="card mb4 pl-5 mt-3">
          <div class="card-header">
            <button @click.prevent="addEmptyDevice(index)" class="btn btn-info btn-sm px-3">New Device</button>
            <button @click.prevent="cloneDevice(index)" class="btn btn-warning btn-sm px-3">Clone Device</button>
            <button @click.prevent="removeDevice(index)" class="btn btn-danger btn-sm px-3">Remove Device</button>
          </div>
          <div class="card-body">
            <div class="row">

              <!-- Supplier Input -->
              <div class="mb-3 col-lg-2">
                <label :for="'supplier' + index">Supplier
                  <button class="btn btn-sm btn-success text-light" type="button" style="font-size: 7px;"
                    @click="addNewSupplier()">+</button>
                </label>
                <select :id="'supplier' + index" v-model="device.supplier" class="form-control px-3" required>
                  <option value="" disabled>Select supplier</option>
                  <option v-for="s in suppliers" :key="s" :value="s">{{ s }}</option>
                </select>
              </div>

              <!-- Manufacturer Input -->
              <div class="mb-3 col-lg-2">
                <label :for="'manufacturer' + index">Manufacturer
                  <!-- <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#uploadFileModal">+</button> -->
                  <button class="btn btn-sm btn-success text-light" type="button" style="font-size: 7px;"
                    @click="addNewManufacturer()">+</button>
                </label>
                <select :id="'manufacturer' + index" v-model="device.manufacturer"
                  @change="() => fetchProductCategories(index)" :disabled="loading" class="form-control px-3" required>
                  <option value="" disabled>Select manufacturer</option>
                  <option v-for="m in manufacturers" :key="m" :value="m">{{ m }}</option>
                </select>
                <div v-if="loading">Loading...</div>
              </div>

              <!-- Category Input -->
              <div class="mb-3 col-lg-2">
                <label :for="'deviceType' + index">Category
                  <!-- <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#uploadFileModal">+</button> -->
                  <button class="btn btn-sm btn-success text-light" type="button" style="font-size: 7px;"
                    @click="addNewCategory(index)">+</button>
                </label>
                <select :id="'deviceType' + index" v-model="device.productDeviceType" class="form-control px-3"
                  @change="fetchProductTypes(index)" required>
                  <option value="" disabled>Select product</option>
                  <option v-for="p in deviceProducts[index]" :key="p" :value="p">{{ p }}</option>
                </select>
              </div>

              <!-- Type Input -->
              <div class="mb-3 col-lg-2">
                <label :for="'productName' + index">Name
                  <!-- <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#uploadFileModal">+</button> -->
                  <button class="btn btn-sm btn-success text-light" type="button" style="font-size: 7px;"
                    @click="addNewType(index)">+</button>
                </label>
                <select :id="'productName' + index" v-model="device.productName" class="form-control px-3"
                  @change="fetchProductTypeVersions(index)" required>
                  <option value="" disabled>Select name</option>
                  <option v-for="p in deviceProductTypes[index]" :key="p" :value="p">{{ p }}</option>
                </select>
              </div>

              <!-- Version Input -->
              <div class="mb-3 col-lg-3">
                <label :for="'version' + index">Version
                  <!-- <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#uploadFileModal">+</button> -->
                  <button class="btn btn-sm btn-success text-light" type="button" style="font-size: 7px;"
                    @click="addNewVersion(index)">+</button>
                </label>
                <select :id="'version' + index" v-model="device.productVersion" class="form-control px-3" required>
                  <option value="" disabled>Select type</option>
                  <option v-for="p in deviceProductTypeVersions[index]" :key="p" :value="p">{{ p }}</option>
                </select>
              </div>

              <!-- Serial Number Input -->
              <div class="mb-3 col-lg-3">
                <label :for="'serial' + index">Serial Number</label>
                <input :id="'serial' + index" v-model="device.serial" type="text">
              </div>

              <!-- Invoice Reference Input -->
              <div class="mb-3 col-lg-3">
                <label :for="'invoice' + index">Invoice Reference</label>
                <input :id="'invoice' + index" v-model="device.invoiceReference" type="text">
              </div>

              <div class="mb-3 col-lg-3">
                <label :for="'hardwareid' + index">Hardware ID</label>
                <input :id="'hardwareid' + index" v-model="device.hardwareId" type="text" placeholder="System Generated">
              </div>

              <!-- Cost Input -->
              <div class="mb-3 col-lg-2">
                <label :for="'cost' + index">Cost (£)</label>
                <input :id="'cost' + index" v-model="device.cost" type="number" step="0.01" min="0">
              </div>

              <!-- Purchase Date Input -->
              <div class="mb-3 col-lg-2">
                <label :for="'pdate' + index">Purchase Date</label>
                <input :id="'pdate' + index" v-model="device.purchaseDate" type="date" required>
              </div>

              <!-- Is Havering -->
              <div class="mb-3 col-lg-3">
                <label class="form-check-label" :for="'havering' + index">Havering?</label>
                <select :id="'isHavering' + index" v-model="device.isHavering" class="form-control">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit button -->
      <br>
      <button type="submit" class="btn btn-primary">Save Hardware</button>
    </form>
  </div>


  <!-- ...and so on for the other types -->
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from 'vue-router'

export default {
  name: "DeviceForm",
  setup() {
    const router = useRouter();
    // State
    let manufacturers = ref([]);
    let suppliers = ref([]);
    let loading = ref(false);
    let devices = reactive([createEmptyDevice()]);
    let deviceProducts = reactive([]);
    let deviceProductTypes = reactive([]);
    let deviceProductTypeVersions = reactive([]);

    // Creates an empty device object
    function createEmptyDevice() {
      return {
        supplier: "",
        manufacturer: "",
        productDeviceType: "",
        productName: "",
        productVersion: "",
        cost: 0.0,
        serial: "",
        isHavering: false,
        purchaseDate: "",
        invoiceReference: "",
        hardwareId: ""
      };
    }
    function addEmptyDevice() {
      let newDevice = createEmptyDevice()
      devices.push(newDevice)
    }

    function cloneDevice(index) {
      // Create a prompt that asks for a quantity and defaults to one, make sure it's only numbers that can be inserted
      let quantity = parseInt(window.prompt("How many devices should be added?", 1), 10);
      // Validate quantity
      if (isNaN(quantity) || quantity < 1) {
        alert("Invalid number of devices.");
        return;
      }
      for (let i = 0; i < quantity; i++) {
        var clonedDevice = JSON.parse(JSON.stringify(devices[index]));
        if (clonedDevice['hardwareId'] != "" && clonedDevice['hardwareId'] != null) {
          let hardwareId = clonedDevice['hardwareId'];
          let match = hardwareId.match(/^(\d+)(.*)$/);

          if (match) {
            let numberPart = parseInt(match[1], 10);
            let nonNumberPart = match[2];

            numberPart + (i + 1);  // increment the number part by 1
            clonedDevice['hardwareId'] = numberPart.toString() + nonNumberPart;
          }
        }
        // Cloning auxiliary data for selects
        deviceProducts.push([...deviceProducts[index]]);
        deviceProductTypes.push([...deviceProductTypes[index]]);
        deviceProductTypeVersions.push([...deviceProductTypeVersions[index]]);
        devices.push(clonedDevice);
      }
    }

    function removeDevice(index) {
      if (devices.length > 1) {
        devices.splice(index, 1);
      }
    }

    // Fetch manufacturers from API
    async function fetchManufacturersAndSuppliers() {
      loading.value = true;
      try {
        const manufacturerSupplierResponse = await fetch("https://be.pencasolutions.com/hardware/manufacturers-suppliers", {
          method: "GET",
          credentials: 'include',
        });
        const data = await manufacturerSupplierResponse.json();
        manufacturers.value = [...data["manufacturers"]];
        suppliers.value = [...data["suppliers"]];
        loading.value = false;
      } catch (error) {
        console.error(error);
        loading.value = false;
      }
    }

    // Fetch products for a specific manufacturer from API
    async function fetchProductCategories(index) {
      if (devices[index].manufacturer) {
        devices[index].productDeviceType = '';
        devices[index].productName = '';
        devices[index].productVersion = '';
        deviceProductTypes[index] = [];
        deviceProductTypeVersions[index] = [];
        try {
          const response = await fetch(`https://be.pencasolutions.com/hardware/products/categories/`, {
            method: "POST",
            credentials: 'include',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ "manufacturer": devices[index].manufacturer })
          });
          const data = await response.json();
          deviceProducts[index] = data;
          console.log(deviceProducts[index])
        } catch (error) {
          console.error(error);
        }
      }
    }

    // Fetch product types for a specific manufacturer from API
    async function fetchProductTypes(index) {
      if (devices[index].productDeviceType) {
        devices[index].productName = '';
        devices[index].productVersion = '';
        deviceProductTypeVersions[index] = [];
        try {
          const response = await fetch(`https://be.pencasolutions.com/hardware/products/categories/types/`, {  // Adjust the endpoint if necessary
            method: "POST",
            credentials: 'include',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "manufacturer": devices[index].manufacturer,
              "deviceType": devices[index].productDeviceType  // This parameter might be required to fetch the correct types for the chosen product
            })
          });
          const data = await response.json();
          deviceProductTypes[index] = data;  // Make sure to declare this reactive array in your setup function
          console.log(deviceProductTypes[index]);
        } catch (error) {
          console.error(error);
        }
      }
    }

    // Fetch product types for a specific manufacturer from API
    async function fetchProductTypeVersions(index) {
      if (devices[index].productName) {
        devices[index].productVersion = '';
        try {
          const response = await fetch(`https://be.pencasolutions.com/hardware/products/categories/types/version`, {  // Adjust the endpoint if necessary
            method: "POST",
            credentials: 'include',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "manufacturer": devices[index].manufacturer,
              "deviceType": devices[index].productDeviceType, // This parameter might be required to fetch the correct types for the chosen product
              "name": devices[index].productName
            })
          });
          const data = await response.json();
          deviceProductTypeVersions[index] = data;  // Make sure to declare this reactive array in your setup function
          console.log(deviceProductTypeVersions[index]);
        } catch (error) {
          console.error(error);
        }
      }
    }

    async function addNewSupplier() {
      let newSupplier = window.prompt("Enter new supplier");
      if (newSupplier) {
        suppliers.value.push(newSupplier);
        suppliers.value.sort(); // This will sort the array alphabetically
      }
    }

    // Add new manufacturer - Implementation required
    function addNewManufacturer() {
      let newManufacturer = window.prompt("Enter new manufacturer");
      if (newManufacturer) {
        manufacturers.value.push(newManufacturer);
        manufacturers.value.sort(); // This will sort the array alphabetically
      }
    }

    function addNewCategory(index) {
      let newCategory = window.prompt("Enter new category");
      if (newCategory) {
        deviceProducts[index].push(newCategory);
        deviceProducts[index].sort(); // This will sort the array alphabetically
      }
    }

    function addNewType(index) {
      let newType = window.prompt("Enter new type");
      if (newType) {
        deviceProductTypes[index].push(newType);
        deviceProductTypes[index].sort(); // This will sort the array alphabetically
      }
    }
    function addNewVersion(index) {
      let newVersion = window.prompt("Enter new version");
      if (newVersion) {
        deviceProductTypeVersions[index].push(newVersion);
        deviceProductTypeVersions[index].sort(); // This will sort the array alphabetically
      }
    }

    //re Add new device to devices array
    function addDevice() {
      devices.push(createEmptyDevice());
      deviceProducts.push([]);
    }

    // Submit form to API
    async function submitForm() {
      let body = JSON.stringify({ items: devices })
      try {
        const response = await fetch("https://be.pencasolutions.com/hardware", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: 'include',
          body: body  // Added "items" key here
        });
        if (response.ok) {
          // Handle successful submission
          router.push({ name: 'Hardware' });
        } else {
          // Handle errors
          console.error("Error submitting form:", await response.text());
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Fetch manufacturers on component mount
    onMounted(fetchManufacturersAndSuppliers);

    return {
      manufacturers,
      suppliers,
      loading,
      devices,
      deviceProducts,
      deviceProductTypes,
      deviceProductTypeVersions,
      addNewSupplier,
      addNewManufacturer,
      addNewCategory,
      addNewType,
      addNewVersion,
      addEmptyDevice,
      cloneDevice,
      removeDevice,
      fetchProductCategories,
      fetchProductTypes,
      fetchProductTypeVersions,
      addDevice,
      submitForm,
    };
  },
};
</script>
