<template>
  <div class="modal fade" id="assignTeacherModal" tabindex="-1" role="dialog" aria-labelledby="assignTeacherModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Teachers</h5>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12">
          <div class="row">
            <div v-if="reassignPupil == false" class="col-lg-6">
              <select id="selectedteacher">
                <!-- <option v-for="teacher in allTeachers" :value="teacher.id" :key="teacher.id" v-bind:selected="index === 0 ? true: false"> -->
                <option v-for="teacher in allTeachers" :value="teacher.id" :key="teacher.id">
                  {{ teacher.name}}
                </option>
              </select>
            </div>
            <div v-else class="col-lg-12">
              <select id="selectedteacher" class="col-lg-12">
                <!-- <option v-for="teacher in allTeachers" :value="teacher.id" :key="teacher.id" v-bind:selected="index === 0 ? true: false"> -->
                <option v-for="teacher in allTeachers" :value="teacher.id" :key="teacher.id">
                  {{ teacher.name}}
                </option>
              </select>
            </div>
            <div v-if="reassignPupil == false" class="col-lg-6">
              <div v-if="assignToPupil" class="form-check">
                <input @click="toggleCompletedAssessmentCheck()" class="form-check-input" type="checkbox" value="" id="completedAssessmentCheck">
                <label class="form-check-label" for="flexCheckDefault">
                  Completed Assessment
                </label>
              </div>
            </div>
          </div>
          <br>
          <div v-show="createCompletedAssessment">
            <div class="mb-4 col-lg-4">
                <label for="date">Completion Date</label>
                <input id="date" input name="date" class="form-control" type="date" value=""/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button v-if="shouldAssignToSchool" @click="assignTeacherToSchool()" class="btn btn-success">Assign To School</button>
        <button v-if="assignToPupil" @click="assignPupilToTeacher()" class="btn btn-success">Assign Teacher</button>
        <button v-if="reassignPupil" @click="reassignPupilToTeacher()" class="btn btn-success">Reassign To Teacher</button>
        <button class="btn btn-primary" id="closeassignmodal" type="button" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AssignTeacherModal',
  props: {
    assignToSchool: Boolean,
    assignToPupil: Boolean,
    reassignPupil: Boolean,
    pupilId: String,
  },
  data() {
    return  {
      allTeachers: [],
      createCompletedAssessment: false,
    }
  },
  computed: {
    shouldAssignToSchool: function(){
      return this.assignToSchool
    },
    shouldAssignToPupil: function(){
      return this.assignToPupil
    },
    // pupilIdToAssignToTeacher: function() {
    //   return this.pupilId
    // }
  },
  async created() {
    const res = await fetch('https://be.pencasolutions.com/staff/teachers/', {
      method: "GET",
      credentials: 'include'
    })
    this.allTeachers = await res.json()
  },
  methods: {
    async assignTeacherToSchool() {
      // const selectedTeacher = this.selectedTeacher
      const selectedTeacher = document.getElementById('selectedteacher').value
      await fetch('https://be.pencasolutions.com/schools/' + this.$route.params.id + '/assign', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({selectedTeacher})
      })
      this.$emit('didassignteacher')
      document.getElementById('closeassignmodal').click()
    },
    async assignPupilToTeacher() {
      // const selectedTeacher = this.selectedTeacher
      const selectedTeacher = document.getElementById('selectedteacher').value
      const dateValue = document.getElementById('date').value
      await fetch('https://be.pencasolutions.com/pupils/' + this.pupilId + '/assign', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          'teacherId':selectedTeacher,
          'createCompletedAssessment': this.createCompletedAssessment,
          'completedAssessmentDate': dateValue
        })
      })
      document.getElementById('closeassignmodal').click()
      this.$emit('didAssignPupil')
    },
    async reassignPupilToTeacher() {
      // const selectedTeacher = this.selectedTeacher
      const selectedTeacher = document.getElementById('selectedteacher').value

      await fetch('https://be.pencasolutions.com/pupils/' + this.pupilId + '/reassign', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          'teacherId':selectedTeacher,
          'createCompletedAssessment': false,
          'completedAssessmentDate': null
        })
      })
      document.getElementById('closeassignmodal').click()
      this.$emit('didReassignPupil')
    },
    toggleCompletedAssessmentCheck() {
      this.createCompletedAssessment = !this.createCompletedAssessment
      // Set todays date by defaullt for the date picker
      if (this.createCompletedAssessment) {
        document.getElementById('date').valueAsDate = new Date()
      }
    }
  }
}
</script>
