<template>
    <div class="container">
        <h1>Log IT Ticket</h1>
        <form>
            <div class="row">
            <div class="form-group col-lg-6">
                <label for="category">Category</label>
                <select class="form-control" id="category" v-model="ticket.category">
                    <option value="software">Software</option>
                    <option value="hardware">Hardware</option>
                </select>
            </div>
            <div class="form-group col-lg-6" v-if="ticket.category === 'software'">
                <label for="subCategory">Sub Category</label>
                <select class="form-control" id="subCategory" v-model="ticket.subCategory">
                    <option value="install">Install</option>
                    <option value="setup">Setup</option>
                </select>
            </div>
            <div class="form-group col-lg-6" v-if="ticket.category === 'hardware'">
                <label for="subCategory">Sub Category</label>
                <select class="form-control" id="subCategory" v-model="ticket.subCategory">
                    <option value="repair">Repair</option>
                    <option value="upgrade">Upgrade</option>
                </select>
            </div>
        </div>
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" class="form-control" id="title" v-model="ticket.title">
            </div>
            <div class="form-group">
                <label for="description">Description</label>
                <textarea class="form-control" id="description" rows="3" v-model="ticket.description"></textarea>
            </div>
            <br>
            <button type="submit" class="btn btn-primary" @click.prevent="submitTicket">Submit</button>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ticket: {
                title: '',
                description: '',
                category: '',
                subCategory: '',
                priority: 'low'
            }
        };
    },
    methods: {
        submitTicket() {
            // TODO: Implement ticket submission logic
            console.log('Ticket submitted:', this.ticket);
        }
    }
};
</script>

<style scoped>
.container {
    margin-top: 20px;
}
</style>
