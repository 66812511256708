<template>
  <form @submit.prevent="submit" method="POST">
    <div class="modal fade" id="rejectionmodal" tabindex="-1" role="dialog" aria-labelledby="rejectionmodal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Reject {{rejectedPupil.firstName}} {{rejectedPupil.lastName}}</h5>
        </div>
        <div class="modal-body">
          <div class="form-group col-md-12">
            <label for="reason">Rejection Reason</label>
            <textarea class="form-control" id="reason" name="reason" rows=3 required></textarea>
          </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success">Reject</button>
            <button @click="$emit('close')" class="btn btn-primary" id="closerejectmodal" type="button" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>


<script>
export default {
  name: 'RejectedPupilModal',
  props: {
    rejectedPupil: {}
  },
  methods: {
    async submit() {
      event.preventDefault()

      const { reason } = Object.fromEntries(new FormData(event.target))
      const body = { reason }
      await fetch('https://be.pencasolutions.com/pupils/' + this.rejectedPupil._id + '/reject', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body)
      })
      //Use res.status to check the status code
      this.$emit('didReject');
      //Emit to parent we have submitted a rejection and should reload

    },
  }
}
</script>
