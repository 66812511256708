<template>
  <div class="sidebarlink" emit="changedNavLink" @click="$emit('didClickNavLink', routerName)">
    <router-link :to="{ name: routerName }">
      <div class="nav-link">
        <div class="nav-link-icon">
          <img :src="img" with="20" height="20" hspace="7.5">
          <p :style="updateTextColor()"> {{routerName}} </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SideBarNavLink",
  props: {
    isActive: Boolean,
    routerName: String,
    icon: String,
  },
  computed: {
    img() {
      return require("../assets/sidebaricons/" + this.icon)
    },
  },
  methods: {
    updateTextColor() {
      return this.isActive ? 'color:Blue;' : 'color:Gray';
    }
  }
}
</script>
