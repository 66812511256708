<template lang="html">
  <!-- Upload File Modal -->
  <div class="modal fade" id="addprevreviewmodal" tabindex="-1" role="dialog" aria-labelledby="addprevreviewmodal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Previous Review</h5>
      </div>
      <div class="modal-body">
        <input id="date" input name="date" class="form-control" type="datetime-local"/>
        <div class="modal-footer">
          <button @click="addPreviewReview()" id="addprevreviewbtn" class="btn btn-success">Add Review</button>
          <button class="btn btn-primary" id="closeprevreviewmodal" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    pupilId: String
  },
  methods: {
    async addPreviewReview() {
      var fetchMethod = 'POST'
      var url = 'https://be.pencasolutions.com/pupils/' + this.pupilId + '/addprevreview'
      let selectedDate = document.getElementById('date').value
      let body = JSON.stringify({'selectedDate':selectedDate})
      console.log(body)
      await fetch(url, {
        method:  fetchMethod,
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: body
      })
      await this.$emit('didAddPrevReview')
    },
  },
}
</script>
