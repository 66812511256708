<template lang="html">
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6">
    <div class="card plr-5 mt-3">
       <div class="card-header">
         Files
         <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#uploadFileModal">+</button>
       </div>
       <div class="card-body">
        <p v-for="file in pupilFiles" :key="file._id">
    <a href="#" @click.prevent="downloadFile(file)" rel="noreferrer noopener">
      {{ file.filename }}
    </a>
    <span @click="deleteFileClicked(file)"><i class="fa-solid fa-trash"></i></span>
    <br>
  </p>
       </div>
     </div>
  </div>

  <!-- Upload File Modal -->
  <div class="modal fade" id="uploadFileModal" tabindex="-1" role="dialog" aria-labelledby="uploadFileModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Upload File</h5>
      </div>
      <div class="modal-body">
        <input
          id="fileUpload"
          type="file"
          @change="onFileChanged($event)"
          accept="*"
          capture
        />
        <div class="modal-footer">
          <button @click="uploadFile()" id="uploadfilebtn" class="btn btn-success">{{uploadButtonText}}</button>
          <button class="btn btn-primary" id="closefileuploadmodal" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    pupilId: String
  },
  data() {
    return {
      pupilFiles: [],
      fileToUpload: Object,
      uploadButtonText: "Upload File"
    }
  },
  created() {
    this.fetchPupilFiles()
  },
  computed: {
    fileName() {
      return ""
    },
  },
  methods: {
    onFileChanged(e) {
      this.fileToUpload = e.target.files[0]
    },
    async fetchPupilFiles() {
      let res = await fetch('https://be.pencasolutions.com/pupils/' + this.pupilId + '/files', {
        credentials: 'include'
      })
      this.pupilFiles = await res.json()
      console.log(this.pupilFiles)
      // this.pupilFiles = await res.status
      // console.log(this.pupilFiles)
    },
    async fetchFile() {
      console.log('fetch file called')
    },
    async uploadFile() {
      document.getElementById('uploadfilebtn').disabled = true
      this.uploadButtonText = 'Uploading'

      var formData = new FormData();
      formData.append('file',  this.fileToUpload)

      await fetch('https://be.pencasolutions.com/pupils/' + this.pupilId + '/files', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        credentials: 'include',
        body: formData
      } )
      await this.fetchPupilFiles()
      document.getElementById('fileUpload').value = ""
      document.getElementById('closefileuploadmodal').click()
      this.uploadButtonText = 'Upload'
      document.getElementById('uploadfilebtn').disabled = false
    },
    async openFile() {
      console.log("open file clicked")
    },
    async deleteFileClicked(file) {
      if(confirm("Are you sure you want to delete " + file.filename +"?" )){
        await fetch('https://be.pencasolutions.com/pupils/'+ this.pupilId + '/files/' + file._id, {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include'
        })
        await this.fetchPupilFiles()
      }
      // this.reload()
    },
    async downloadFile(file) {
      try {
        let response = await fetch('https://be.pencasolutions.com/pupils/'+ this.pupilId + '/files/' + file._id, {
          method: 'GET',
          credentials: 'include'
        })
        if (!response.ok) throw new Error('Network response was not ok');
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.filename);  // Set the filename here
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error("Failed to download the file:", err);
      }
    }
  },
}
</script>
