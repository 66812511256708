<template>
    <div class="card mb4 pl-5 mt-3" v-if="createReq">
        <div class="card-header">Pupil Information</div>
        <div class="card-body">
            <div class="row">
                <div class="mb-3 col-lg-6">
                    <label for="name">Pupil Name</label>
                    <input class="form-control form-control" id="name" name="name" type="text" :value="createReq.pupil.name"
                        readonly>
                </div>
                <div class="mb-3 col-lg-6">
                    <label for="school">School</label>
                    <input class="form-control" id="school" name="school" :value=createReq.school.name type="text" readonly>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-lg-4">
                    <label for="yeargroup">Year Group</label>
                    <input class="form-control" id="yeargroup" name="yeargroup" type="text"
                        :value="createReq.pupilYearGroup" readonly>
                </div>
                <div class="mb-4 col-lg-4">
                    <label for="startDateTime">Start Time</label>
                    <input id="startDateTime" input name="startDateTime" class="form-control" type="datetime-local"
                        @input="updateStartDateTime" required />
                </div>
                <div class="mb-4 col-lg-4">
                    <label for="completionDateTime">End Time</label>
                    <input id="completionDateTime" input name="completionDateTime" class="form-control"
                        @input="updateCompletionDateTime" type="datetime-local" required />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        createReq: Object,
    },
    methods: {
        updateStartDateTime(event) {
            this.$emit('update:updateDateValue', { prop: 'startDateTime', value: event.target.value });
        },
        updateCompletionDateTime(event) {
            this.$emit('update:updateDateValue', { prop: 'completionDateTime', value: event.target.value });
        },
    },
};
</script>