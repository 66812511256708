<template>
  <vue-good-table
  :columns="columns"
  :rows="diversities"
  :pagination-options="{
   enabled: true,
   mode: 'pages',
  }"
  v-on:row-click="didClickRow"
  :search-options="{
   enabled: true,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span>
      {{props.formattedRow[props.column.field]}}
    </span>
  </template>
  </vue-good-table>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'


export default {
  components: {
    VueGoodTable,
  },
  emits:['didSelectDiversity'],
  props: {
    diversities: {
      type: Array,
      required: false,
      default: () => [
        {
         id: String,
         name: String,
         isSelected: true
        }
      ]
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    didClickRow(params) {
      params.isSelected = true
      this.$emit('didSelectDiversity', params.row)
      //emit
    },
  }
}
</script>
