<template lang="html">
<div class="pupil">
  <div class="row">
    <h1>
      {{pupil.firstName}} {{pupil.middleName}} {{pupil.lastName}} {{ pupil.status == 'Limbo' ? '(Limbo)' : '' }}
      <!-- <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal"
              data-bs-target="#createInteraction">Add Interaction</button>
      <button v-if="isEditing ===false" id="showStaffInteractionModalBtn" class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#createInteraction" style="display: none"></button> -->

      <button v-show="!isEditing" v-if="pupil.status != 'Archived' && pupil.status != 'Rejected'" @click="toggleEdit()" class="btn btn-sm btn-dark text-light active me-2 float-right">Edit</button>
    </h1>
    <hr>
  </div>
  <button v-if="isEditing ===false && pupil.status != 'Archived' && pupil.status != 'Rejected'" @click="showArchivalModal()" class="btn btn-sm btn-warning text-light">Archive Pupil</button>
  <button v-if="isEditing ===false && pupil.status != 'Archived' && pupil.status != 'Rejected'" id="showArchivalModalBtn" class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#archivePupilModal" style="display: none"></button>

  <button v-if="isEditing ===false && pupil.status != 'Archived' && pupil.status != 'Rejected'" id="showAssignTeacherModalBtn" class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#assignTeacherModal" style="display: none"></button>
  <button v-if="isEditing ===false && pupil.status != 'Archived' && pupil.status != 'Rejected'" id="showAddTeacherModalBtn" class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#addTeacherModal" style="display: none"></button>
  <span v-if="pupil.teacher">
    <button v-if="isEditing ===false && pupil.status != 'Archived' && pupil.status != 'Rejected'" @click="showAssignTeacherModal()" class="btn btn-sm btn-success text-light">Reassign Pupil</button>
    <button v-if="isEditing ===false && pupil.status != 'Archived' && pupil.status != 'Rejected'" @click="showAddTeacherModal()" class="btn btn-sm btn-info text-light">Add Teacher</button>
  </span>
  <button v-if="isEditing ===false && pupil.status != 'Archived' && pupil.status != 'Rejected' && pupil.status != 'Limbo'" @click="setLimbo()" class="btn btn-sm btn-danger text-light">Move to Limbo</button>

  <form @submit.prevent="submit" method="POST">
  <div class="card mb4 pl-5 mt-3">
  <div class="card-header">
    Pupil Information
  </div>
  <div class="card-body">
    <div class="row">
      <div class="mb-3 col-lg-4">
        <label for="firstName">First Name</label>
        <input v-if="isEditing === false" class="form-control form-control" id="firstName" name="firstName" type="text" :value="pupil.firstName" readonly>
        <input v-else class="form-control form-control" id="firstName" name="firstName" type="text" :value="pupil.firstName">
      </div>

      <div class="mb-3 col-lg-4">
        <label for="middleName">Middle Name</label>
        <input v-if="isEditing === false" class="form-control form-control" id="middleName" name="middleName" :value="pupil.middleName" type="text" readonly>
        <input v-else class="form-control form-control" id="middleName" name="middleName" :value="pupil.middleName" type="text">
      </div>
      <div class="mb-3 col-lg-4">
        <label for="lastName">Last Name</label>
        <input v-if="isEditing === false" class="form-control form-control" id="lastName" name="lastName" type="text" :value="pupil.lastName" readonly>
        <input v-else class="form-control form-control" id="lastName" name="lastName" type="text" :value="pupil.lastName">
      </div>
    </div>
    <div class="row">
      <div v-if="isEditing === false" class="mb-3 col-lg-4">
        <label for="schoolId">School</label>
        <input v-if="!pupil.school" type="text" class="form-control" input name="schoolId" id="schoolId" value="-" readonly>
        <router-link v-else :to="{ name: 'displayschool', params: {id: pupil.school._id} }">
          <input type="text" class="form-control" input name="schoolId" id="schoolId" :value="pupil.school.name" readonly>
        </router-link>
      </div>
      <div v-else class="mb-3 col-lg-4">
        <label for="school">School</label>
        <select id="schoolId" input name="schoolId" class="form-control">
          <option v-for="school in schools" :value="school._id" :key="school" :selected="school._id == pupil.school._id? true : false">
               {{ school.name }}
          </option>
        </select>
      </div>
      <div v-if="isEditing === false" class="mb-3 col-lg-4">
        <label for="localAuthorityId">Local Authority</label>
        <router-link :to="{ name: 'displayauthority', params: {id: pupil.authority._id} }">
          <input type="text" class="form-control" input name="localAuthorityId" id="localAuthorityId" :value="pupil.authority.name" readonly>
        </router-link>
      </div>
      <div v-else class="mb-3 col-lg-4">
        <label for="localAuthority">Local Authority</label>
        <select id="localAuthorityId" input name="localAuthorityId" class="form-control">
          <option v-for="authority in localAuthorities" :value="authority._id" :key="authority" :selected="authority._id == pupil.authority._id? true : false">
               {{ authority.name }}
          </option>
        </select>
      </div>
      <div v-if="isEditing === false" class="mb-3 col-lg-4">
        <label for="gender">Gender</label>
        <input type="text" class="form-control" input name="gender" id="gender" :value="pupil.gender" readonly>
      </div>
      <div v-else class="mb-3 col-lg-4">
        <label for="gender">Gender</label>
        <select id="gender" input name="gender" class="form-control">
          <option v-for="gender in genders" :value="gender" :key="gender" :selected="gender == pupil.gender? true : false">
               {{ gender }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div v-if="isEditing === false" class="mb-3 col-lg-3">
        <label for="phase">Phase</label>
        <input type="text" class="form-control" input name="phase" id="phase" :value="pupil.phase" readonly>
      </div>
      <div v-else class="mb-3 col-lg-3">
        <label for="phase">Phase</label>
        <select id="phase" input name="phase" class="form-control">
          <option v-for="phase in phases" :value="phase" :key="phase" :selected="phase == pupil.phase? true : false">
               {{ phase }}
          </option>
        </select>
      </div>
      <div v-if="isEditing === false" class="mb-3 col-lg-3">
        <label for="category">Category</label>
        <input type="text" class="form-control" input name="category" id="category" :value="pupil.category" readonly>
      </div>
      <div v-else class="mb-3 col-lg-3">
        <label for="category">Category</label>
        <select id="inputCategory" input name="category" class="form-control">
          <option v-for="category in categories" :value="category" :key="category" :selected="category == pupil.category? true : false">
               {{ category }}
          </option>
        </select>
      </div>
      <div v-if="isEditing === false" class="mb-3 col-lg-3">
        <label class="form-check-label" for="aac">AAC</label>
          <input v-if="pupil.aac === 'yes' && pupil.aacHub === false" type="text" class="form-control" input name="aac" id="aac" value="Yes" readonly>
          <input v-if="pupil.aac === 'yes' && pupil.aacHub === true" type="text" class="form-control" input name="aac" id="aac" value="Yes (Hub)" readonly>
          <input v-if="pupil.aac === 'no'" type="text" class="form-control" input name="aac" id="aac" value="No" readonly>
      </div>
      <div v-else class="mb-3 col-lg-3">
        <label class="form-check-label" for="paysInvoicesDirectly">AAC</label>
        <select id="aac" input name="aac" class="form-control">
          <option value="yes" :selected="pupil.aac === 'yes' && pupil.aacHub === false">Yes</option>
          <option value="yeshub" :selected="pupil.aac === 'yes' && pupil.aacHub === true">Yes (HUB)</option>
          <option value="no" :selected="pupil.aac === 'no'">No</option>
        </select>
      </div>
      <div v-if="isEditing === false" class="mb-3 col-lg-3">
        <label class="form-check-label" for="ehcp">EHCP</label>
          <input v-if="pupil.ehcp" type="text" class="form-control" input name="ehcp" id="ehcp" value="Yes" readonly>
          <input v-else type="text" class="form-control" input name="ehcp" id="ehcp" value="No" readonly>
      </div>
      <div v-else class="mb-3 col-lg-3">
        <label class="form-check-label" for="ehcp">EHCP</label>
        <select id="ehcp" input name="ehcp" class="form-control">
          <option value=true :selected="pupil.ehcp ? true : false">Yes</option>
          <option value=false :selected="pupil.ehcp ? false : true">No</option>
        </select>
      </div>

      <div class="row">
        <div v-if="isEditing === false" class="mb-3 col-lg-3">
          <label for="yearGroup">Year Group</label>
          <input type="text" class="form-control" input name="yearGroup" id="yearGroup" :value="pupil.yearGroup" readonly>
        </div>
        <div v-else class="mb-3 col-lg-4">
          <label for="yearGroup">Year Group</label>
          <select id="yearGroup" input name="yearGroup" class="form-control">
            <option v-for="yearGroup in yearGroups" :value="yearGroup" :key="yearGroup" :selected="yearGroup == pupil.yearGroup? true : false">
                 {{ yearGroup }}
            </option>
          </select>
        </div>
        <div v-if="isEditing === false" class="mb-3 col-lg-3">
          <label for="uniqueCode" @click="assignNewUniqueCode()">Unique Code <i class="fa-solid fa-arrows-rotate"></i></label>
          <input type="text" class="form-control" input name="uniqueCode" id="uniqueCode" :value="pupil.uniqueCode" readonly>
        </div>
        <div v-if="isEditing === false" class="mb-4 col-lg-3">
          <label for="dob">Date of Birth</label>
          <input id="dob" input name="dob" class="form-control" :value="pupil.dob" readonly type="date" />
        </div>
        <div v-else class="mb-4 col-lg-4">
            <label for="dob">Date of Birth</label>
            <input id="dob" input name="dob" class="form-control" type="date" :value="pupil.dob" required/>
        </div>
        <div v-if="isEditing === false" class="mb-4 col-lg-3">
          <label for="referralDate">Referral Date</label>
          <input id="referralDate" input name="refferalDate" class="form-control" :value="pupil.refferalDate" readonly type="date" />
        </div>
        <div v-else class="mb-4 col-lg-4">
            <label for="referralDate">Referral Date</label>
            <input id="referralDate" input name="referralDate" class="form-control" type="date" :value="pupil.refferalDate" required/>
        </div>

        <div v-if="isEditing === false" class="mb-3 col-lg-3">
          <label for="teacher">Teachers</label>
          <input v-if="!pupil.teacher" type="text" class="form-control" input name="teacher" id="teacher" value="-" readonly>
          <input v-else type="text" class="form-control" input name="teacher" id="teacher" :value="pupil.teacher.name" readonly>
          <li v-for="teacher in pupil.teachers" :value="teacher.name" :key="teacher">
              {{teacher.name}}
              <button type="button" @click="removeTeacherFromPupil(teacher)" class="btn btn-datatable btn-icon btn-transparent-dark" id="removeteacherbtn">
                <i class="fa-solid fa-trash"></i>
              </button>
          </li>
        </div>
        <div v-if="isEditing === false" class="mb-3 col-lg-3">
          <label for="status">Status</label>
          <input type="text" class="form-control" id="status" :value="pupil.status" readonly>
        </div>

        <div v-if="isEditing === false" class="mb-3 col-lg-3">
          <div v-if="pupil.status == 'Awaiting Review' || 'Review Scheduled'">
          <label v-if="pupil.lastReviewDate" @click="shouldShowRescheduleModal('Last Review Date')" for="status">Last Review Date <i class="fa-regular fa-clock"></i></label>
          <label v-else for="status">Last Review Date</label>
          <input type="text" class="form-control" id="lastreview" :value="momentDDMMYYYY(pupil.lastReviewDate)" readonly>
        </div>
        </div>

        <div v-if="isEditing === false" class="mb-3 col-lg-3">
          <div v-if="pupil.status == 'Awaiting Review' || 'Review Scheduled'">
            <label for="status">Scheduled Review Date</label>
          <input type="text" class="form-control" id="nextreview" :value="momentDDMMYYYY(pupil.scheduledReviewDate)" readonly>
        </div>
        </div>
        <div v-if="isEditing === false" class="mb-3 col-lg-3">
          <div v-if="pupil.status == 'Awaiting Review' || 'Review Scheduled'">
          <label v-if="pupil.completedAssessmentDate" @click="shouldShowRescheduleModal('Completed Assessment Date')" for="status">Completed Assessment Date <i class="fa-regular fa-clock"></i></label>
          <label v-else for="status">Completed Assessment Date</label>
          <input type="text" class="form-control" id="completedassessmentdate" :value="momentDDMMYYYY(pupil.completedAssessmentDate)" readonly>
        </div>
        </div>
        <div v-if="isEditing === false && pupil.ehcp" class="mb-3 col-lg-3">
          <label @click="shouldShowRescheduleModal('ehcpDate')">EHCP Date <i class="fa-regular fa-clock"></i></label>
          <!-- <label for="status">EHCP Date</label> -->
          <input type="text" class="form-control" id="ehcpdate" :value="momentDDMMYYYY(pupil.ehcpDate)" readonly>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
<EditAddressInfo v-if="isEditing"
:address= "pupil.address"
:isEditingNonPupilAddress= "false"
/>
</div>

<div v-if="isEditing === true" class="mb-4 pl-5 mt-3">
  <button v-if="isEditing" type="submit" class="btn btn-primary">Update Pupil</button>
  <button v-if="isEditing" @click="toggleEdit()" class="btn btn-secondary">Cancel</button>
</div>

</form>

<div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <DisplayAddressInfo v-if="!isEditing"
          :address= "pupil.address"
          :isDisplayingNonPupilAddress= "false"
        />
    </div>

    <div v-show="isEditing == false" class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <div class="card plr-5 mt-3">
        <PupilServices
          :isActivePupil='true'
        />
      </div>
    </div>
</div>

<div v-show="isEditing == false" class="row">
      <FileUpload :pupilId="pupil._id"/>
      <LanguageList />
      <DiversityList />
</div>


<span v-show="isEditing == false">



<div class="card plr-5 mt-3">
<ContactsTable v-if="didFetchPupil" @didupdatecontacts="didupdatecontacts"
 :createContactURL='createContactURL'
 :deleteContactURL='deleteContactURL'
 :createSchoolContactURL='createSchoolContactURL'
 :deleteSchoolContactURL='deleteSchoolContactURL'
 :contacts = "pupil.contacts"
 :schoolContacts = "pupil.school.contacts"
 :showPupilsSchoolContacts = "true"
/>
</div>


<div class="card plr-5 mt-3" v-show="didFetchPupil">
  <!-- <StaffInteractionUpdated></StaffInteractionUpdated> -->
  <StaffInteractionUpdated
    :targetType='"pupil"'
    :isAuthoritySecondaryDisplay="false"
    :isDisplayingOnPupilPage="true"
    :pupilId="pupil._id"
  />
</div>

<DisplayHardwareTable
:hardware = "pupil.hardware"
:pupil="pupil"
@didUpdateHardware= "didUpdateHardware()"
/>


<DisplaySoftwareTable
:software = "pupil.software"
:pupil="pupil"
@didRevokeLicense= "didRevokeLicense()"
/>

<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
  <div class="card plr-5 mt-3">
  <div class="card-header">
    Completed Reviews
  </div>
  <vue-good-table
  :columns="reviewColumns"
  :rows="pupil.completedReviews"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: true,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span v-if="props.column.field == 'completionDate'">
      <router-link :to="{ name: 'getreview', params: {id: props.row.pupil._id, reviewid: props.row._id } }">
        {{moment(props.row.completionDateTime)}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'teacher'">
      <router-link :to="{ name: 'displayteacher', params: {id: props.row.teacher._id } }">
        {{props.row.teacher.name}}
      </router-link>
    </span>
  </template>
  </vue-good-table>
  </div>
  </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
<div class="card mb4 pl-5 mt-3">
  <ChangeLogTable :changeLog="pupil.history" />
</div>
</div>
</div>

<!-- Archival modal -->
<ArchivePupilModal v-if="didFetchPupil"
  :pupilId = "this.pupil._id"
  @didarchivepupil = "didarchivepupil"
/>
<AssignTeacherModal v-if="didFetchPupil"
  :pupilId = "this.pupil._id"
  :reassignPupil = true
  @didReassignPupil = "didReAssignPupil"
/>
<AssignAddtionalTeacherModal v-if="didFetchPupil"
  :pupilId = "this.pupil._id"
  :shouldAssignAdditionalTeacher = true
  @didAssignAdditionalTeacher = "didAssignAdditionalTeacher"
/>
</span>
</div>

<button hidden id="showRescheduleModalBtn" data-bs-toggle="modal" data-bs-target="#updatereviewdatemodal" style="display: none"></button>
<ChangeReviewDateModal v-if="didFetchPupil"
  :pupilId = "this.pupil._id"
  :rescheduleType = "this.rescheduleType"
  :lastReviewDate = "this.pupil.lastReviewDate"
  :completedAssessmentDate = "this.pupil.completedAssessmentDate"
  :ehcpDate = "this.pupil.ehcpDate"
  @didUpdateDate = "fetchPupil"
/>

</template>

<script>
import moment from 'moment'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
//import StaffInteractions from '../../components/StaffInteractions.vue'
import PupilServices from '../../components/PupilDisplayComponents/PupilServices.vue'
import FileUpload from '../../components/PupilDisplayComponents/FileUpload.vue'
import DiversityList from '../../components/PupilDisplayComponents/DiversityList.vue'
import LanguageList from '../../components/PupilDisplayComponents/LanguageList.vue'
import DisplayAddressInfo from '../../components/AddressInfo/DisplayAddressInfo.vue'
import EditAddressInfo from '../../components/AddressInfo/EditAddressInfo.vue'
import ContactsTable from '../../components/ContactsTable.vue'
import ArchivePupilModal from '../../components/PupilDisplayComponents/ArchivePupilModal.vue'
import AssignTeacherModal from '../../components/AssignTeacherModal.vue'
import AssignAddtionalTeacherModal from '../../components/AddTeacherModal.vue'
import ChangeReviewDateModal from '../../components/PupilDisplayComponents/ChangeReviewDateModal.vue'
import DisplayHardwareTable from '../../components/PupilDisplayComponents/DisplayHardwareTable.vue'
import ChangeLogTable from '../../components/ChangeLogTable.vue'
import StaffInteractionUpdated from '../../components/StaffInteractions/StaffInteractionUpdated.vue'
import DisplaySoftwareTable from '../../components/PupilDisplayComponents/DisplaySoftwareTable.vue'


export default {
  name: 'GetPupil',
  computed: {
    createSchoolContactURL() {
      return 'https://be.pencasolutions.com/schools/' + this.pupil.school._id + '/contacts/'
    },
    deleteSchoolContactURL() {
      return 'https://be.pencasolutions.com/schools/' + this.pupil.school._id + '/contacts/'
    },
  },
  data() {
    return {
      pupil: {},
      didFetchPupil: false,
      isEditing: false,
      rescheduleType: String,
      shouldShowArchivalModal: false,
      shouldShowAssignTeacherModal: false,
      shouldShowAddTeacherModal: false,
      createContactURL: 'https://be.pencasolutions.com/pupils/' + this.$route.params.id + '/contacts/',
      deleteContactURL: 'https://be.pencasolutions.com/pupils/' + this.$route.params.id + '/contacts/',
      // createSchoolContactURL: 'https://be.pencasolutions.com/schools/' + this.pupil.school._id + '/contacts/',
      // deleteSchoolContactURL: 'https://be.pencasolutions.com/schools/' + this.pupil.school._id + '/contacts/',
      reviewColumns: [
        {
          label: 'Date',
          field: 'completionDate',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Teacher',
          field: 'teacher',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
      ],
      localAuthorities: [
        {
            name: "",
            _id: ""
        }
      ],
      schools: [],
      phases: [],
      genders: [],
      categories: [],
      yearGroups: [],
      languages: [],
      diversities: [],
    }
  },
  created() {
    this.fetchCreatePupilData()
    this.fetchPupil()

    // if (this.pupil.status == "active")  {
    //
    // }
  },
  components: {
    VueGoodTable,
    DisplayAddressInfo,
    EditAddressInfo,
    PupilServices,
    FileUpload,
    DiversityList,
    LanguageList,
    ContactsTable,
    ArchivePupilModal,
    AssignTeacherModal,
    AssignAddtionalTeacherModal,
    ChangeReviewDateModal,
    DisplayHardwareTable,
    DisplaySoftwareTable,
    ChangeLogTable,
    StaffInteractionUpdated
  },
  methods: {
    async fetchPupil() {
      const res = await fetch('https://be.pencasolutions.com/pupils/' + this.$route.params.id, {
        credentials: 'include',
      })
      this.pupil = await res.json()
      this.didFetchPupil = true
    },
    async didupdatecontacts() {
      //Double calls to reload.
      await this.fetchPupil();
      await this.fetchPupil();
    },
    async showArchivalModal() {
      this.shouldShowArchivalModal = true
      document.getElementById('showArchivalModalBtn').click()
    },

    async didarchivepupil() {
      await this.fetchPupil();
    },

    async fetchCreatePupilData() {
      const res = await fetch('https://be.pencasolutions.com/pupils/createreq', {
        credentials: 'include'
      })
      const json = await res.json()
      this.localAuthorities = json.localAuthorities
      this.phases = json.phases
      this.yearGroups = json.yearGroups
      this.schools = json.schools
      this.genders = json.genders
      this.categories = json.categories
      this.languages = json.languages
      this.diversities = json.diversities
    },
    moment(dateToFormat) {
      let date = moment.utc(dateToFormat);
      if (dateToFormat == null || !date.isValid()) {
        return "-";
      }
      return moment.utc(dateToFormat).format('DD/MM/YYYY HH:mm');
    },
    momentDDMMYYYY (dateToFormat) {
      let date = moment.utc(dateToFormat);
      if (dateToFormat == null || !date.isValid()) {
        return "-";
      }
      return moment.utc(dateToFormat).format('DD/MM/YYYY');
    },
    toggleEdit() {
      this.isEditing = !this.isEditing
    },
    async showAssignTeacherModal() {
      this.shouldShowAssignTeacherModal = true
      document.getElementById('showAssignTeacherModalBtn').click()
    },
    async showAddTeacherModal() {
      this.shouldShowAddTeacherModal = true
      document.getElementById('showAddTeacherModalBtn').click()
    },

    async assignNewUniqueCode() {
      let nextUniqueCode = await fetch('https://be.pencasolutions.com/admin/nextid/' + this.pupil._id, {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => response.text())

      let assignCodeMsg = "The next code available is: " + nextUniqueCode + "\n"

      if(confirm(assignCodeMsg)) {
        await fetch('https://be.pencasolutions.com/admin/generateid/' + this.pupil._id, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include',
        })
        this.pupil.uniqueCode = nextUniqueCode
      }
    },

    shouldCloseAssignTeacherModal() {
      this.shouldShowAssignTeacherModal = false
      document.getElementById('closeassignmodal').click()
    },

    shouldCloseAddTeacherModal() {
      this.shouldShowAssignTeacherModal = false
      document.getElementById('closeassignmodal').click()
    },

    async didReAssignPupil() {
      await this.fetchPupil();
    },

    async didAssignAdditionalTeacher() {
      await this.fetchPupil();
    },

    async shouldShowRescheduleModal(rescheduleType) {
      this.rescheduleType = rescheduleType
      document.getElementById('showRescheduleModalBtn').click()
    },

    async didUpdateHardware() {
      await this.fetchPupil()
    },

    async didRevokeLicense() {
      await this.fetchPupil()
    },

    async removeTeacherFromPupil(teacher) {
      // let teacherId =
      if(confirm("Are you sure you want to remove " + teacher.name + " as a teacher?")) {
        const res = await fetch('https://be.pencasolutions.com/pupils/' + this.$route.params.id + "/removeteacher", {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({'teacherId': teacher._id}),
        })
        if (res.status === 200) {
          this.fetchPupil()
        }
      }
    },

    showStaffInteractionModal() {
      document.getElementById('showStaffInteractionModalBtn').click()
    },

    async submit(event) {
      this.toggleEdit()
      var { firstName, middleName, lastName, schoolId, localAuthorityId, gender, phase, category, aac, ehcp, yearGroup, dob, referralDate } = Object.fromEntries(new FormData (event.target) )
      const { addressLine1, addressLine2, contactNumber, contactNumber2, city, postCode, county } = Object.fromEntries(new FormData (event.target) )

      const address = {
        'firstLine': addressLine1,
        'secondLine': addressLine2,
        'primaryPhone': contactNumber,
        'secondaryPhone': contactNumber2,
         city, postCode, county
      }
      //Converting to booleans as the event target see's them as strings
      const EHCP = (ehcp === "true");
      // const AAC =  (aac === "true");
      var aacHub = false 
      if (aac === "yeshub") {
        aac = "yes"
        aacHub = true
      }

      const pupilPatch = {
        firstName, middleName, lastName, schoolId, localAuthorityId, gender, phase, category, EHCP, aac, aacHub, yearGroup, dob, referralDate, address
      }

      await fetch('https://be.pencasolutions.com/pupils/' + this.pupil._id, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(pupilPatch)
      })
      await this.fetchPupil()
  },
  async setLimbo() {
    if(confirm("Are you sure you want to move this pupil to limbo?")) {
      await fetch('https://be.pencasolutions.com/pupils/' + this.pupil._id + '/limbo', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
      await this.fetchPupil()
    }
  },
},
}
</script>
