<template lang="html">
  <h1> {{adminName}} - Dashboard </h1>
  <hr>
  <div class="card plr-5 mt-3">
  <div class="card-header">
    New Pupils
  </div>
  <vue-good-table
  :columns="newPupilsColumns"
  :rows="newPupils"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: false,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span v-if="props.column.field == 'uniqueCode'">
      <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
      </PupilStatusIndicator>
    </span>
    <span v-if="props.column.field == 'firstName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.firstName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'lastName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.lastName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'school.name'">
      <router-link :to="{ name: 'displayschool', params: {id: props.row.school._id} }">
        {{props.row.school.name}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'actions'">
      <button v-if="props.row.status == 'New Referral'" @click="sentToAuthority(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Sent to authority"><i class="fa-solid fa-check"></i></button>
      <button class="btn btn-sm btn-success text-light" id="showRejectionModalBtn" type="button" data-bs-toggle="modal" data-bs-target="#rejectionmodal" style="display: none"></button>
      <button v-if="props.row.status == 'Awaiting LA Approval'" @click="showRejectionModal(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Reject Pupil"><i class="fa-solid fa-xmark"></i></button>
      <button class="btn btn-sm btn-success text-light" id="showApproveModalBtn" type="button" data-bs-toggle="modal" data-bs-target="#approvenmodal" style="display: none"></button>
      <button v-if="props.row.status == 'Awaiting LA Approval'" @click="showApprovalModal(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Approve Pupil"><i class="fa-solid fa-upload"></i></button>
      <button id="showAssignTeacherModalBtn" class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#assignTeacherModal" style="display: none"></button>
      <button v-if="props.row.status == 'Awaiting Assignment'" @click="showAssignTeacherModal(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Assign Pupil"><i class="fa-solid fa-arrow-right"></i></button>
    </span>
  </template>
  </vue-good-table>
  </div>

  <div class="card plr-5 mt-3">
  <div class="card-header">
    Schedule Assessments & Reviews
  </div>
  <vue-good-table
  :columns="pupilsToScheduleColumns"
  :rows="pupilsRequiringScheduling"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: false,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span v-if="props.column.field == 'uniqueCode'">
      <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
      </PupilStatusIndicator>
    </span>
    <span v-if="props.column.field == 'firstName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.firstName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'lastName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.lastName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'school.name'">
      <router-link :to="{ name: 'displayschool', params: {id: props.row.school._id} }">
        {{props.row.school.name}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'lastReviewDate'">
      <span v-if="props.row.lastReviewDate">
        {{moment(props.row.lastReviewDate)}}
      </span>
      <span v-else>
        <button @click="showPrevReviewModal(props.row._id)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Add Previous Review"><i class="fa-solid fa-plus"></i></button>
        <button class="btn btn-sm btn-success text-light" id="showPrevReviewModalBtn" type="button" data-bs-toggle="modal" data-bs-target="#addprevreviewmodal" style="display: none"></button>
      </span>

    </span>
    <span v-if="props.column.field == 'actions'">
      <button v-if="this.shouldShowScheduleModal" class="btn btn-datatable btn-icon btn-transparent-dark" id="showScheduleModalBtn" type="button" data-bs-toggle="modal" data-bs-target="#reschedulemodal" style="display: none"><i class="fa-regular fa-clock"></i></button>
      <button @click="showScheduleModal(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Schedule"><i class="fa-regular fa-clock"></i></button>
    </span>
  </template>
  </vue-good-table>
  </div>

  <div class="card plr-5 mt-3">
  <div class="card-header">
    Booked Reviews
  </div>
  <vue-good-table
  :columns="bookedPupilsColumns"
  :rows="bookedPupils"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: false,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span v-if="props.column.field == 'uniqueCode'">
      <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode">
      </PupilStatusIndicator>
    </span>
    <span v-if="props.column.field == 'firstName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.firstName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'lastName'">
      <router-link :to="{ name: 'displaypupil', params: {id: props.row._id} }">
        {{props.row.lastName}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'school.name'">
      <router-link :to="{ name: 'displayschool', params: {id: props.row.school._id} }">
        {{props.row.school.name}}
      </router-link>
    </span>
    <span v-if="props.column.field == 'date'">
      <span v-if="props.row.status == 'Assessment Scheduled'">
        {{moment(props.row.scheduledAssessmentDate)}}
      </span>
      <span v-else-if="props.row.status == 'Review Scheduled'">
        {{moment(props.row.scheduledReviewDate)}}
      </span>
    </span>
    <span v-if="props.column.field == 'actions'">
      <button v-if="this.shouldShowScheduleModal" class="btn btn-datatable btn-icon btn-transparent-dark" id="showScheduleModalBtn" type="button" data-bs-toggle="modal" data-bs-target="#reschedulemodal" style="display: none"><i class="fa-regular fa-clock"></i></button>
      <button @click="showScheduleModal(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark" title="Reschedule"><i class="fa-regular fa-clock"></i></button>
    </span>
  </template>
  </vue-good-table>
  </div>

  <div class="card plr-5 mt-3">
  <div class="card-header">
    Equipment Requests
  </div>
  <vue-good-table
  :columns="equipmentRequestColumns"
  :rows="equipmentRequests"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: false,
   skipDiacritics: true,
  }">
  </vue-good-table>
  </div>

  <RejectPupilModal
    :rejectedPupil="rejectedPupil"
    @didReject = "didRejectPupil()"
    @close="shouldCloseRejectModal()"
  />

  <ApprovePupilModal
    :approvedPupil="approvedPupil"
    @didApprove = "didApprovePupil()"
    @close="shouldCloseApprovalModal()"
  />

<span v-if="this.shouldShowPrevReviewModal">
  <PreviousReviewModal
    :pupilId="prevreviewpupilid"
    @didAddPrevReview = "didAddPrevReview()"
    @close="shouldClosePrevReviewModal()"
  />
</span>

<span v-if="this.shouldShowScheduleModal">
<RescheduleReviewModal
  :pupil="rescheduledPupil"
  :teacher="rescheduledPupilTeacher"
  @didSchedulePupil = "didSchedulePupil()"
  @close="shouldCloseScheduleModal()"
/>
</span>


  <AssignTeacherModal
    :assignToPupil = "this.shouldShowAssignTeacherModal"
    :pupilId = "this.pupilIdToAssignToTeacher"
    @didAssignPupil = "didAssignPupil()"
  />


</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import RejectPupilModal from '../../../components/RejectPupilModal.vue'
import ApprovePupilModal from '../../../components/ApprovePupilModal.vue'
import AssignTeacherModal from '../../../components/AssignTeacherModal.vue'
import PreviousReviewModal from '../../../components/AddPrevReviewModal.vue'
import RescheduleReviewModal from '../../../components/RescheduleReviewModal.vue'
import moment from 'moment'
import PupilStatusIndicator from '../../../components/PupilStatusIndicator.vue'
export default {
  name: "AdminDash",
  created() {
    this.fetchPupilsForAdmin()
  },
  components: {
    VueGoodTable,
    RejectPupilModal,
    ApprovePupilModal,
    AssignTeacherModal,
    PreviousReviewModal,
    RescheduleReviewModal,
    PupilStatusIndicator
  },
  data() {
    return {
      selectedFile: null,
      adminName: "",
      newPupils: [],
      pupilsRequiringScheduling: [],
      bookedPupils: [],
      equipmentRequests: [],
      shouldShowAcceptModal: false,
      shouldShowRejectionModal: false,
      shouldShowAssignTeacherModal: false,
      shouldShowPrevReviewModal: false,
      shouldShowScheduleModal: false,
      pupilIdToAssignToTeacher: String,
      approvedPupil: {},
      rejectedPupil: {},
      prevreviewpupilid: {},
      rescheduledPupil: {},
      rescheduledPupilTeacher: {},
      newPupilsColumns: [
        {
          label: 'ID',
          field: 'uniqueCode',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'First Name',
          field: 'firstName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Last Name',
          field: 'lastName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'School',
          field: 'school.name',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Year Group',
          field: 'yearGroup',
          sortable: true,
          sortFn: this.sortYearGrps,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
      ],
      pupilsToScheduleColumns: [
        {
          label: 'ID',
          field: 'uniqueCode',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'First Name',
          field: 'firstName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Last Name',
          field: 'lastName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'School',
          field: 'school.name',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Year Group',
          field: 'yearGroup',
          sortable: true,
          sortFn: this.sortYearGrps,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Last Review',
          field: 'lastReviewDate',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: false
          },
        },
      ],
      bookedPupilsColumns: [
        {
          label: 'ID',
          field: 'uniqueCode',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'First Name',
          field: 'firstName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Last Name',
          field: 'lastName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'School',
          field: 'school.name',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Year Group',
          field: 'yearGroup',
          sortable: true,
          sortFn: this.sortYearGrps,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Date',
          field: 'date',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: false
          },
        },
      ],
      equipmentRequestColumns: [
        {
          label: 'Date',
          field: 'firstName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Staff Member',
          field: 'requestedBy',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Description',
          field: 'lastName',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Amount',
          field: 'yearGroup',
          sortable: true,
          sortFn: this.sortYearGrps,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: false
          },
        },
      ],
    }
  },
  methods: {
    async fetchPupilsForAdmin() {
      const res = await fetch('https://be.pencasolutions.com/dashboard/admin', {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
      const json = await res.json()
      this.newPupils = json.newPupils
      this.pupilsRequiringScheduling = json.pupilsRequiringScheduling
      this.bookedPupils = json.bookedPupils
      this.adminName = json.adminName
    },
    async sentToAuthority(pupilRow) {
      await fetch('https://be.pencasolutions.com/pupils/' + pupilRow._id + '/sent', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
      await this.fetchPupilsForAdmin()
    },

    moment(dateToFormat) {
      return moment.utc(dateToFormat).format('DD/MM/YYYY');
    },

    showRejectionModal(pupilRow) {
      this.rejectedPupil = pupilRow
      this.shouldShowRejectionModal = true
      document.getElementById('showRejectionModalBtn').click()
    },

    shouldCloseRejectModal() {
      this.shouldShowRejectionModal = false
      document.getElementById('closerejectmodal').click()
    },

    async didApprovePupil() {
      this.shouldCloseApprovalModal()
      await this.fetchPupilsForAdmin()
    },

    showApprovalModal(pupilRow) {
      this.approvedPupil = pupilRow
      this.shouldShowApprovalModal = true
      document.getElementById('showApproveModalBtn').click()
    },

    shouldCloseApprovalModal() {
      this.shouldShowApprovalModal = false
      document.getElementById('closeapprovemodal').click()
    },

    async didRejectPupil() {
      this.shouldCloseAssignTeacherModal()
      await this.fetchPupilsForAdmin()
    },

    showAssignTeacherModal(pupilRow) {
      this.pupilIdToAssignToTeacher = pupilRow._id
      this.shouldShowAssignTeacherModal = true
      document.getElementById('showAssignTeacherModalBtn').click()
    },

    shouldCloseAssignTeacherModal() {
      this.shouldShowAssignTeacherModal = false
      document.getElementById('closeassignmodal').click()
    },

    showPrevReviewModal(pupilId) {
      this.prevreviewpupilid = pupilId
      this.shouldShowPrevReviewModal = true
      document.getElementById('showPrevReviewModalBtn').click()
    },

    shouldClosePrevReviewModal() {
      this.shouldShowPrevReviewModal = false
      this.prevreviewpupil = {}
      document.getElementById('closeprevreviewmodal').click()
    },

    async didAddPrevReview() {
      this.shouldClosePrevReviewModal()
      await this.fetchPupilsForAdmin()
    },

    async showScheduleModal(pupilRow) {
      this.shouldShowScheduleModal = true
      await this.fetchPupilForReschedule(pupilRow._id)
      document.getElementById('showScheduleModalBtn').click()
    },

    shouldCloseScheduleModal() {
      this.rescheduledPupil = {}
      this.rescheduledPupilTeacher = {}
      this.shouldShowScheduleModal = false
      document.getElementById('closeschedulemodal').click()
    },

    async didSchedulePupil() {
      this.shouldCloseScheduleModal()
      await this.fetchPupilsForAdmin()
    },

    async fetchPupilForReschedule(pupilId) {
      const res = await fetch('https://be.pencasolutions.com/pupils/' + pupilId, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include'
      })
      this.rescheduledPupil = await res.json()
    },

    async didAssignPupil() {
      await this.fetchPupilsForAdmin()
    },

    // async approvePupil(pupilRow) {
    //   await fetch('https://be.pencasolutions.com/pupils/' + pupilRow._id + '/approve', {
    //     method: 'POST',
    //     headers: {
    //       'Content-type': 'application/json',
    //     },
    //     credentials: 'include',
    //   })
    //   await this.fetchPupilsForAdmin()
    // },
    async assignPupil(pupilRow) {
      const res = await fetch('https://be.pencasolutions.com/pupils/' + pupilRow._id + '/assign', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
      })
      //Needs pupil assignment
      await res.json()
      await this.fetchPupilsForAdmin()
    },
  },

  sortYearGrps (a, b) { 

  //Give the strings numberical values to sort by 
  const yearGrps = {
    "N": -1, 
    "R": -2,
    "16+": 17, 
  }
  //If yearGrps doesnt contain a use the value of a otherwise use a new value from the dictionary
  const aVal = yearGrps[a] || parseInt(a)
  const bVal = yearGrps[b] || parseInt(b)

  return parseInt(aVal) - parseInt(bVal)

  },
}
</script>
