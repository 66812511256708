<template lang="html">
  <div class="card plr-5 mt-3">
    <div class="card-header">
      Hardware
      <button v-if="isGeneratingIndemnity === false" @click="isGeneratingIndemnity = true"
        class="btn btn-sm btn-success text-light" type="button">Generate Indemnity</button>
      <span v-else>
        <button @click="downloadIndemnity()" :disabled="!selectedDisplayIds.length"
          class="btn btn-sm btn-success text-light" type="button">Download</button>
        <button @click="cancelIndemnity()" class="btn btn-sm btn-info text-light"
          type="button">Cancel</button>
      </span>
    </div>
    <vue-good-table :columns="columns" :rows="hardware" :pagination-options="{
      enabled: true,
      mode: 'pages'
    }" :search-options="{
  enabled: false,
  skipDiacritics: true,
}">

      <template #table-row="props">
        <span v-if="props.column.field == 'displayId'">
          <!-- <router-link :to="{ name: 'displayhardwareproduct', params: {id: props.row._id} }"> -->
          {{ props.formattedRow[props.column.field] }}
          <!-- </router-link> -->
        </span>
        <span v-if="props.column.field == 'pupil.name'">
          <span v-if="props.row.pupil">
            <router-link :to="{ name: 'displaypupil', params: { id: props.row.pupil._id } }">
              {{ props.row.pupil.name }}
            </router-link>
          </span>
        </span>
        <span v-if="props.column.field == 'actions'">
          <span v-if="isGeneratingIndemnity === false">
            <button @click="checkinHardware(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
              title="Check hardware into stock">
              {{ props.formattedRow[props.column.field] }}
              <i class="fa-solid fa-check"></i>
            </button>
            <button @click="retireHardware(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
              title="Retire Hardware">
              {{ props.formattedRow[props.column.field] }}
              <i class="fa-solid fa-xmark"></i>
            </button>
          </span>
          <span v-else>
            <button @click="addRemoveDisplayIdForIndemnity(props.row.displayId)"
              class="btn btn-datatable btn-icon btn-transparent-dark">
              {{ props.formattedRow[props.column.field] }}
              <!-- If displayId exists in the array, show the checkmark icon -->
              <span v-if="displayIdExistsInSelectedArr(props.row.displayId)">
                <i class="fa-solid fa-check"></i>
              </span>
              <span v-else>
                <!-- If displayId doesn't exist in the array, show the xmark icon -->
                <i class="fa-solid fa-xmark"></i>
              </span>

            </button>
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'

export default {
  name: "DisplayHardwareTable",
  components: {
    VueGoodTable,
  },
  props: {
    hardware: [],
    pupil: {}
  },
  data() {
    return {
      isGeneratingIndemnity: false,
      selectedDisplayIds: [],
      columns: [
        {
          label: 'ID',
          field: 'displayId',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Category',
          field: 'product.name',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Type',
          field: 'product.deviceType',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Version',
          field: 'version',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Serial',
          field: 'serialNumber',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
    }
  },
  methods: {
    async checkinHardware(hardwareRow) {
      let checkHardwareIntoStockMsg = 'Validate the following before checking into stock:\n' +
        'Condition of equipment\n' +
        'Laptops/pc - Licences (still active)\n' +
        'Software on the device\n'
      if (confirm(checkHardwareIntoStockMsg)) {
        await fetch("https://be.pencasolutions.com/" + 'hardware/' + hardwareRow._id + '/checkin', {
          method: "PATCH",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        })
        this.$emit('didUpdateHardware')
      }
    },

    async retireHardware(hardwareRow) {
      if (confirm("Are you sure you wish to retire hardware?")) {
        await fetch("https://be.pencasolutions.com/" + 'hardware/' + hardwareRow._id + '/retire', {
          method: "PATCH",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        })
        this.$emit('didUpdateHardware')
      }
    },

    generateIndemnityClicked() {
      this.isGeneratingIndemnity = true
    },

    addRemoveDisplayIdForIndemnity(displayId) {
      const index = this.selectedDisplayIds.indexOf(displayId);

      if (index !== -1) {
        // If displayId exists in the array, remove it
        this.selectedDisplayIds.splice(index, 1);
      } else {
        // If displayId doesn't exist in the array, add it
        this.selectedDisplayIds.push(displayId);
      }
    },
    displayIdExistsInSelectedArr(displayId) {
      return this.selectedDisplayIds.includes(displayId);
    },

    cancelIndemnity() {
      this.isGeneratingIndemnity = false 
      this.selectedDisplayIds = []
    },

    async downloadIndemnity() {
      const url = "https://be.pencasolutions.com/pupils/" + this.pupil._id + "/indemnity";
      const data = {
        "selectedDisplayIds": this.selectedDisplayIds
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = "Home and School Indemnity - " + this.pupil.firstName + " " + this.pupil.lastName + ".docx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
      }
      this.isGeneratingIndemnity = false 
      this.selectedDisplayIds = []
    }
  }
}

</script>
