<template>
  <form @submit.prevent="submit" method="POST">
  <div class="row">
    <h1>
      {{school.name}}
      <button v-show="!isEditing" @click="toggleEdit()" class="btn btn-sm btn-dark text-light active me-2 float-right" type="button">Edit</button>
    </h1>
  </div>
  <hr>

  <div class="card mb4 pl-5 mt-3">
    <div class="card-header">School Information</div>
    <div class="card-body">
      <div class="row">
        <div class="mb-3 col-lg-6">
          <label for="name">School Name</label>
          <input v-if="isEditing === false" class="form-control form-control" id="name" name="name" type="text" :value="school.name" readonly>
          <input v-else class="form-control form-control" id="name" name="name" type="text" :value="school.name">
        </div>

        <div v-if="isEditing === false" class="mb-3 col-lg-6">
          <label for="inputLA">Local Authority</label>
          <div v-if="isLoaded">
          <router-link :to="{ name: 'displayauthority', params: {id: school.localAuthority._id } }">
            <input class="form-control form-control" id="authority" name="authority" type="text" :value="school.localAuthority.name" readonly>
          </router-link>
        </div>
        </div>
        <div v-else class="mb-3 col-lg-6">
        <label for="localAuthorityId">Local Authority *</label>
        <select id="localAuthorityId" input name="localAuthorityId" class="form-control">
          <option v-for="authority in school.authorities" :value="authority._id" :key="authority.name" :selected="authority._id == school.localAuthority._id ? true : false">
               {{ authority.name }}
          </option>
        </select>
        </div>

      </div>
      <div class="row">
        <div class="mb-3 col-lg-4">
          <label for="inputPhase">Phase</label>
          <input v-if="isEditing === false" class="form-control form-control" id="phase" name="phase" type="text" :value="school.phase" readonly>
          <select v-else id="inputPhase" input name="phase" class="form-control">
            <option v-for="phase in school.phases" :value="phase" :key="phase" :selected="phase == school.phase? true : false">
                 {{ phase }}
            </option>
          </select>
        </div>
        <div class="mb-3 col-lg-4">
          <label for="minimumYeargroup">Lowest Year</label>
          <input v-if="isEditing === false" class="form-control form-control" id="minimumYeargroup" name="minimumYeargroup" type="text" :value="school.minYearGroup" readonly>
          <select v-else id="minimumYeargroup" input name="minYearGroup" class="form-control">
            <option v-for="year in school.yearGroups" :value="year" :key="year" :selected="year == school.minYearGroup? true : false">
                 {{ year }}
            </option>
          </select>
        </div>
        <div class="mb-3 col-lg-4">
          <label for="maximumYeargroup">Highest Year</label>
          <input v-if="isEditing === false" class="form-control form-control" id="maximumYeargroup" name="maximumYeargroup" type="text" :value="school.maxYearGroup" readonly>
          <select v-else id="maximumYeargroup" input name="maxYearGroup" class="form-control">
            <option v-for="year in school.yearGroups" :value="year" :key="year" :selected="year == school.maxYearGroup? true : false">
                 {{ year }}
            </option>
          </select>

        </div>
      </div>
      <div class="row">
      <div class="mb-4 col-lg-6">
        <label for="website">Website</label>
          <span v-if="isEditing === false">
            <span v-if="school.website === ''" >
              <input class="form-control form-control" id="website" name="website" type="url" :value="school.website" readonly>
            </span>
            <a v-else :href="school.website">
              <input v-if="isEditing === false" class="form-control form-control" id="website" name="website" type="url" :value="school.website" readonly>
              <input v-else class="form-control form-control" id="website" name="website" type="url" :value="school.website">
            </a>
          </span>
          <span v-else>
            <input v-if="school.website === ''" class="form-control form-control" id="website" name="website" type="url" placeholder="https://www.surrey.org">
            <input v-else class="form-control form-control" id="website" name="website" type="url" :value="school.website">
          </span>

      </div>

      <div v-if="isEditing === false" class="mb-4 col-lg-6">
        <label for="paysInvoicesDirectly">Pays Invoices Directly</label>
        <input v-if="school.paysInvoicesDirectly === true" class="form-control form-control" id="paysInvoicesDirectly" value="Yes" readonly>
        <input v-else class="form-control form-control" id="paysInvoicesDirectly" value="No" readonly>
      </div>

      <div v-else class="mb-4 col-lg-6">
        <label for="paysInvoicesDirectly">Pays Invoices Directly</label>
        <select v-if="isEditing === true" id="paysInvoicesDirectly" input name="paysInvoicesDirectly" class="form-control">
            <option value=true :selected="school.paysInvoicesDirectly ? true : false">Yes</option>
            <option value=false :selected="school.paysInvoicesDirectly ? false : true">No</option>
        </select>
      </div>

      </div>
      <div class="mb-0">
        <label for="transportNotes">Transportation Notes</label>
        <textarea v-if="isEditing === false" v-model="school.transportNotes" class="form-control form-control" id="transportNotes" name="transportNotes" rows="3" readonly></textarea>
        <textarea v-else v-model="school.transportNotes" class="form-control form-control" id="transportNotes" name="transportNotes" rows="3"></textarea>
      </div>
    </div>
  </div>


  <div class="row">

    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <!-- Contact Information Card -->
      <DisplayAddressInfo v-if="!isEditing"
      :address= "school.address"
      />
      <EditAddressInfo v-else
      :address= "school.address"
      />
    </div>


    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

      <div v-if="isEditing === false" class="card mb4 plr-5 mt-3">
        <ContactsTable
          @didupdatecontacts="didupdatecontacts"
          :createContactURL='createContactURL'
          :deleteContactURL='deleteContactURL'
          :contacts = "school.contacts"
          :showPupilsSchoolContacts="false"
        />
      </div>

    </div>
  </div>

<div v-if="isEditing === true" class="mb-4 pl-5 mt-3">
  <button v-if="isEditing" type="submit" class="btn btn-primary">Update School</button>
  <button v-if="isEditing" @click="toggleEdit()" class="btn btn-secondary">Cancel</button>
</div>

</form>

<span v-show="isEditing == false">
<div class="card plr-5 mt-3">
<StaffInteractionUpdated v-if="isLoaded" :key="tableKey"
  :targetType='"school"'
  :isAuthoritySecondaryDisplay='false'
  :isDisplayingOnPupilPage='false'
  :schoolId='school._id'
  :schoolPupilRows='school.pupils'
  />
</div>
</span>
<div v-show="isEditing === false" class="col-lg-12 col-md-6 col-sm-6 col-xs-6">
  <div class="card mb4 pl-5 mt-3">
  <div class="card-header">Pupils</div>
<vue-good-table
:columns="pupilColumns"
:rows="school.pupils"
:pagination-options="{
 enabled: true,
 mode: 'pages'
}"
:search-options="{
 enabled: true,
 skipDiacritics: true,
}">
<template #table-row="props">
  <span v-if="props.column.field == 'uniqueCode'">
    <PupilStatusIndicator :pupilStatus="props.row.actionStatus" :pupilUniqueCode="props.row.uniqueCode"/>
  </span>
  <span v-else-if="props.column.field == 'category'
  || props.column.field == 'yearGroup'">
    {{props.formattedRow[props.column.field]}}
 </span>
 <span v-else>
   <router-link :to="{ name: 'displaypupil', params: {id: props.row._id } }">
   {{props.formattedRow[props.column.field]}}
   </router-link>
 </span>
</template>
</vue-good-table>
</div>
</div>

  <div v-show="isEditing === false" class="col-lg-12 col-md-6 col-sm-6 col-xs-6">
    <div class="card mb4 pl-5 mt-3">

    <div class="card-header">
    Teachers
    <!-- <button class="btn btn-sm btn-success text-light" type="button" data-bs-toggle="modal" data-bs-target="#assignTeacherModal">Add Advisory Team Member To School</button> -->
  </div>
  <vue-good-table
  :columns="teacherColumns"
  :rows="school.teachers"
  :pagination-options="{
   enabled: true,
   mode: 'pages'
  }"
  :search-options="{
   enabled: true,
   skipDiacritics: true,
  }">
  <template #table-row="props">
    <span v-if="props.column.field == 'teacher.name'">
      <router-link :to="{ name: 'displayteacher', params: {id: props.row.teacher._id} }">
        {{props.formattedRow[props.column.field]}}
      </router-link>
    </span>
  </template>
  </vue-good-table>
</div>
  </div>

  <div v-if="isEditing === false" class="mb-4">

<AssignTeacherModal
  @didassignteacher="didassignteacher"
  :assignToSchool = "true"
/>
</div>
   <!-- Assign Teacher Modal -->
   <!-- <div class="modal fade" id="assignTeacherModal" tabindex="-1" role="dialog" aria-labelledby="assignTeacherModal" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <h5 class="modal-title">Teachers</h5>
       </div>
       <div class="modal-body">
         <div class="form-group col-md-12">
           <select id="selectedteacher">
               <option v-for="teacher in allTeachers" :value="teacher.id" :key="teacher.id" v-bind:selected="index === 0 ? true: false">
              {{ teacher.name}}
            </option>
          </select>
         </div>
         </div>
         <div class="modal-footer">
           <button @click="assignTeacher()" class="btn btn-success">Assign Teacher</button>
           <button class="btn btn-primary" id="close" type="button" data-bs-dismiss="modal">Close</button>
         </div>
       </div>
   </div>
</div> -->

</template>

<script>
// import Table from '@/components/Table.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import router from '../../router'
import ContactsTable from '../../components/ContactsTable.vue'
import EditAddressInfo from '../../components/AddressInfo/EditAddressInfo.vue'
import DisplayAddressInfo from '../../components/AddressInfo/DisplayAddressInfo.vue'
import AssignTeacherModal from '../../components/AssignTeacherModal.vue'
// import StaffInteractions from '../../components/StaffInteractions.vue'
import StaffInteractionUpdated from '../../components/StaffInteractions/StaffInteractionUpdated.vue'
import PupilStatusIndicator from '../../components/PupilStatusIndicator.vue'

export default {
  name: 'GetSchool',
  components: {
    VueGoodTable,
    ContactsTable,
    DisplayAddressInfo,
    EditAddressInfo,
    AssignTeacherModal,
    // StaffInteractions,
    StaffInteractionUpdated,
    PupilStatusIndicator
  },
  data() {
    return {
      tableKey: 0,
      id: "",
      isEditing: false,
      selectedAuthority: 'Bolton',
      selectedTeacher: 1,
      createContactURL: 'https://be.pencasolutions.com/schools/' + this.$route.params.id + '/contacts/',
      deleteContactURL: 'https://be.pencasolutions.com/schools/' + this.$route.params.id + '/contacts/',
      school: [],
      isLoaded: false,
      pupilColumns: [
        {
          label: 'Unique Code',
          field: 'uniqueCode',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'First Name',
          field: 'firstName',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Last Name',
          field: 'lastName',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'Year Group',
          field: 'yearGroup',
          sortable: true,
          //Add a sort function to sort by year group converting the strings to ints
          sortFn: this.sortYearGrps,
        },
        {
          label: 'Category',
          field: 'category',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
      ],
      pupilRows: [],
      teacherColumns: [
        {
          label: 'Name',
          field: 'teacher.name',
          sortable: true,
          // globalSearchDisabled: true,
          // filterOptions: {
          //   enabled: true
          // },
        },
        {
          label: 'A',
          field: 'aCount',
          sortable: true,
        },
        {
          label: 'B',
          field: 'bCount',
          sortable: true,
        },
        {
          label: 'C',
          field: 'cCount',
          sortable: true,
        },
        {
          label: 'D',
          field: 'dCount',
          sortable: true
        },
        {
          label: 'E',
          field: 'eCount',
          sortable: true
        }
      ],
      teacherRows: [],
    }
  },
  async created() {
    this.id = this.$route.params.id;
    await this.reload()
  },
  navigate() {
    router.go(-1);
  },
  methods: {
    async reload() {
      this.isEditing = false
      await this.fetchSchool()
    },
    async fetchSchool() {
      const res = await fetch('https://be.pencasolutions.com/schools/' + this.id, {
        method: "GET",
        credentials: 'include',
      })
      this.school = await res.json()
      this.isLoaded = true
    },

    async didupdatecontacts() {
      //Double calls to reload for some reason?!
      await this.fetchSchool();
      await this.fetchSchool();
    },

    async didassignteacher() {
      console.log('assigned teacher')
      await this.fetchSchool();
      await this.fetchSchool();
    },

    toggleEdit() {
      this.isEditing = !this.isEditing
    },
    sortYearGrps (a, b) { 

    //Give the strings numberical values to sort by 
    const yearGrps = {
      "N": -1, 
      "R": -2,
      "16+": 17, 
    }
    //If yearGrps doesnt contain a use the value of a otherwise use a new value from the dictionary
    const aVal = yearGrps[a] || parseInt(a)
    const bVal = yearGrps[b] || parseInt(b)

    return parseInt(aVal) - parseInt(bVal)

    },

    async submit(event) {
      event.preventDefault()

      const { name, phase, localAuthorityId, minYearGroup, maxYearGroup, transportNotes,
      website } = Object.fromEntries(new FormData(event.target))

      const { addressLine1, addressLine2, contactNumber, contactNumber2, city, postCode, county } = Object.fromEntries(new FormData (event.target) )

      var { paysInvoicesDirectly } = Object.fromEntries(new FormData(event.target))
      paysInvoicesDirectly = paysInvoicesDirectly === "true" ? true : false
      const address = {
        'firstLine': addressLine1,
        'secondLine': addressLine2,
        'primaryPhone': contactNumber,
        'secondaryPhone': contactNumber2,
         city, postCode, county
      }

      const patchedSchool = {
        name, phase, paysInvoicesDirectly, localAuthorityId, minYearGroup, maxYearGroup, transportNotes,
        website, address
      }

      const res = await fetch('https://be.pencasolutions.com/schools/' + this.id, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(patchedSchool)
      })
      await res.json()
      this.isEditing = false
      this.reload()
  }
}
}
</script>
