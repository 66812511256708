<template>
  <h1>Hardware</h1>
  <hr>
  <div v-if="selectedHardware.length > 0">
    <h2>
      Selected Equipment
      <button @click="showAssignModal(selectedHardware[0]._id)" class="btn btn-sm btn-success text-light float-right">Assign</button>
      <button @click="selectedHardware = []" class="btn btn-sm btn-danger text-light float-right">Clear</button>
    </h2>
    <ul>
      <li v-for="hardware in selectedHardware" :key="hardware.displayId">
        {{ hardware.displayId }} - 
        {{ hardware.product.name }} - 
        {{ hardware.product.deviceType }} - 
        {{ hardware.version }}
      </li>
    </ul>
    <hr>
  </div>
  <div class="hardware">
    <button class="btn btn-sm btn-primary text-light px-4 mr-2" id="allBtn" @click="setFilter('allBtn')">All</button>
    <button class="btn btn-sm btn-success text-light px-4 mr-2" id="eyegazeBtn"
      @click="setFilter('eyegazeBtn')">Eyegaze</button>
    <button class="btn btn-sm btn-success text-light px-4 mr-2" id="haveringBtn"
      @click="setFilter('haveringBtn')">Havering</button>
      <button @click="isEditing = !isEditing" class="btn btn-sm btn-dark text-light active me-2 float-right" style="margin-left: 20px;">Edit</button>
    <br>
    <!-- <Table :columns='columns' :rows='rows' />
   -->
    <vue-good-table mode="remote" v-on:page-change="onPageChange" v-on:sort-change="onSortChange"
      v-on:column-filter="onColumnFilter" v-on:per-page-change="onPerPageChange" :totalRows="totalRecords"
      :columns="columns" :rows="hardware" :pagination-options="{
        enabled: true,
        mode: 'pages'
      }" :search-options="{
  enabled: false,
  skipDiacritics: true,
}">

      <template #table-row="props">
        <span v-if="props.column.field == 'displayId'">
          <router-link :to="{ name: 'displayhardware', params: {id: props.row._id} }">
            {{ props.formattedRow[props.column.field] }}
          </router-link>
        </span>
        <span v-if="props.column.field == 'product.name'">
          {{ props.row.product.name ?? ""}}
          <span v-if="isEditing" @click="updateName('name', props.row)">
            <i class="fa-solid fa-pencil"></i>
          </span>
        </span>
        <span v-if="props.column.field == 'product.deviceType'">
          {{ props.row.product.deviceType ?? "" }}
          <span v-if="isEditing" @click="updateName('deviceType', props.row)" > 
            <i class="fa-solid fa-pencil"></i>
          </span>
        </span>
        <span v-if="props.column.field == 'version'">
          {{ props.row.version }}
          <span v-if="isEditing" @click="updateName('version', props.row)">
            <i class="fa-solid fa-pencil"></i>
          </span>
        </span>
        <span v-if="props.column.field == 'cost'">
          £{{ props.row.cost.toFixed(2) }}
        </span>
        <span v-if="props.column.field == 'pupil.name'">
          <span v-if="props.row.pupil">
            <router-link :to="{ name: 'displaypupil', params: { id: props.row.pupil._id } }">
              {{ props.row.pupil.name }}
            </router-link>
          </span>
        </span>
        <span v-if="props.column.field == 'select'">
          <input type="checkbox" v-model="selectedHardware" :value="props.row">
        </span>
        <span v-if="props.column.field == 'actions'">

          <button class="btn btn-sm btn-success text-light" id="showAssignModal" type="button" data-bs-toggle="modal"
            data-bs-target="#assignHardwareModal" style="display: none"></button>

          <button @click="showAssignModal(props.row._id)" class="btn btn-datatable btn-icon btn-transparent-dark"
            title="Assign Hardware">
            {{ props.formattedRow[props.column.field] }}
            <i class="fa-solid fa-arrow-right"></i>
          </button>

          <button @click="checkinHardware(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
            title="Check hardware into stock">
            {{ props.formattedRow[props.column.field] }}
            <i class="fa-solid fa-check"></i>
          </button>
          <button @click="retireHardware(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
            title="Retire Hardware">
            {{ props.formattedRow[props.column.field] }}
            <i class="fa-solid fa-xmark"></i>
          </button>
          <button v-if="isInStock(props.row)" @click="deleteHardware(props.row)" class="btn btn-datatable btn-icon btn-transparent-dark"
            title="Delete Hardware">
            {{ props.formattedRow[props.column.field] }}
            <i class="fa-solid fa-xmark" style="color: red;"></i>
          </button>
        </span>
      </template>
    </vue-good-table>
  </div>

  <AssignHardwareModal v-if="shouldShowAssignModal" 
    :hardwareId="this.selectedHardwareId" 
    :pupils="this.pupils"
    :batchHardwareToAssign="selectedHardware"
    @didassignhardware="didAssignHardware" />

  <div class="row">
    <div class="col-lg-2 mt-3">

  <router-link :to="{ name: 'createhardware' }">
      <button class="btn btn-primary">Add Equipment</button>
    <!-- </div> -->
  </router-link> 
  </div>

  <div class="col-lg-2 mt-3 custom-padding">
  <!-- <router-link :to="{ name: 'createhardware' }"> -->
    <!-- <div class="card mb4 mt-3 col-lg-2"> -->
      <button class="btn btn-dark" disabled>Equipment Request</button>
    <!-- </div> -->
  <!-- </router-link>  -->
  </div>
</div>

<button hidden id="showRenameHardwareModalBtn" data-bs-toggle="modal" data-bs-target="#renamehardwaremodal" style="display: none"></button>
<RenameHardwareModal v-show="showRenameHardwareModal"
    :existingName="modalExistingName"
    @confirm="handleModalConfirm"
    @close="closeModal"
  />

</template>

<script>
// import Table from '@/components/Table.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
import AssignHardwareModal from '../../components/AssignHardwareModal.vue'
import RenameHardwareModal from '../../components/RenameHardwareModal.vue'

export default {
  name: 'ListHardware',
  data() {
    return {
      isLoading: false,
      pupilsLoaded: false,
      pupils: [],
      selectedHardwareId: String,
      showRenameHardwareModal: false,
      modalExistingName: '',
      shouldShowAssignModal: false,
      productNameChange: {},
      selectedHardware: [],
      endPointToPatch: '',
      columns: [
        {
          label: 'ID',
          field: 'displayId',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Category',
          field: 'product.name',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Name',
          field: 'product.deviceType',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Version',
          field: 'version',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Serial',
          field: 'serialNumber',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Status',
          field: 'assignmentStatus',
          sortable: true,
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Assigned To',
          field: 'pupil.name',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true
          },
        },
        {
          label: 'Cost',
          field: 'cost',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: false
          },
        },
        {
          label: 'Select',
          field: 'select',
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
      isEditing: false,
      hardware: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: ''
        },
        page: 1,
        perPage: 10,
        isEyeGaze: false,
        isHavering: false
      }
    }
  },
  components: {
    VueGoodTable,
    AssignHardwareModal,
    RenameHardwareModal,
  },
  async created() {
    this.loadItems()
    this.fetchPupils()

  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      const res = await fetch('https://be.pencasolutions.com/hardware/paginated', {
        method: "POST",
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ 'serverParams': this.serverParams })
      })
      const data = await res.json()
      this.hardware = data['hardware']
      this.totalRecords = data['totalRecords']
    },

    async fetchPupils() {
      let res = await fetch('https://be.pencasolutions.com/pupils/', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })

      const json = await res.json()
      this.pupils = json['activePupils'].map(pupil => (
        {
          'id': pupil._id,
          'uniqueCode': pupil.uniqueCode,
          'firstName': pupil.firstName,
          'lastName': pupil.lastName,
          'year': pupil.yearGroup,
          'category': pupil.category,
          'teacher': pupil.teacher,
          'aac': pupil.aac,
          'lastReviewDate': pupil.lastReviewDate,
          'school': pupil.school,
          'status': pupil.status,
          'actionStatus': pupil.actionStatus
        }
      ))
      this.pupilsLoaded = true
    },

    showAssignModal(hardwareId) {
      this.selectedHardwareId = hardwareId
      this.shouldShowAssignModal = true
      document.getElementById('showAssignModal').click()
    },

    async didAssignHardware() {
      this.selectedHardwareId = ""
      this.shouldShowAssignModal = false
      this.selectedHardware = []
      await this.loadItems()
    },

    async checkinHardware(hardwareRow) {
      let checkHardwareIntoStockMsg = 'Validate the following before checking into stock:\n' +
        'Condition of equipment\n' +
        'Laptops/pc - Licences (still active)\n' +
        'Software on the device\n'
      if (confirm(checkHardwareIntoStockMsg)) {
        await fetch("https://be.pencasolutions.com/" + 'hardware/' + hardwareRow._id + '/checkin', {
          method: "PATCH",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        })
        await this.loadItems()
      }
    },

    async retireHardware(hardwareRow) {
      if (confirm("Are you sure you wish to retire hardware?")) {
        await fetch("https://be.pencasolutions.com/" + 'hardware/' + hardwareRow._id + '/retire', {
          method: "PATCH",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        })
        await this.loadItems()
      }
    },

    async deleteHardware(hardwareRow) {
      if (confirm("Are you sure you wish to delete hardware?")) {
        await fetch("https://be.pencasolutions.com/" + 'hardware/' + hardwareRow._id, {
          method: "DELETE",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        })
        await this.loadItems()
      }
    },

    async setFilter(btnId) {
      switch (btnId) {
        case "allBtn":
          this.serverParams.isEyeGaze = false 
          this.serverParams.isHavering = false
          this.loadItems()
          break;
        case "eyegazeBtn":
          this.serverParams.isEyeGaze = true
          this.serverParams.isHavering = false
          this.loadItems()
          break;
        case "haveringBtn":
          this.serverParams.isEyeGaze = false 
          this.serverParams.isHavering = true
          this.loadItems()
          break;
      }

      var btns = ["allBtn", "eyegazeBtn", "haveringBtn"]
      var filteredBtns = btns.filter(function (btn) {
        return btn !== btnId;
      });
      btns.forEach(function (btn) {
        document.getElementById(btn).classList.remove("btn-success");
        document.getElementById(btn).classList.remove("btn-primary");
      });
      filteredBtns.forEach(function (btn) {
        document.getElementById(btn).classList.add("btn-success");
      });
      document.getElementById(btnId).classList.add("btn-primary");
    },

    async updateName(fieldName, hardware) {

      //Product name change
      this.productNameChange = {
        "manufacturer": hardware.product.manufacturer,
        "version": hardware.version,
        "productName": hardware.product.name,
        "productDeviceType": hardware.product.deviceType,
        "updateType": "",
        "newValue": "",
        "individualId": hardware._id
      }

      switch (fieldName) {
        case "version":
          this.modalExistingName = hardware.version;
          this.endPointToPatch = "version"
          this.productNameChange["updateType"] = "version"
          break;

        case "name":
          this.modalExistingName = hardware.product.name;
          this.endPointToPatch = "updatename"
          this.productNameChange["updateType"] = "productName"
          break;

        case "deviceType":
          this.modalExistingName = hardware.product.deviceType;
          this.endPointToPatch = "devicetype"
          this.productNameChange["updateType"] = "productDeviceType"
          break;
      }
      this.showRenameHardwareModal = true;
      document.getElementById('showRenameHardwareModalBtn').click()
    },
    async handleModalConfirm(data) {
      console.log(data)
      this.productNameChange["newValue"] = data['newName']
      this.productNameChange["shouldUpdateAll"] = data['shouldUpdateAll']
      console.log(this.productNameChange)
      // console.log(newName, shouldUpdateAll)

      if (this.productNameChange != null && this.productNameChange != "") {
        await fetch("https://be.pencasolutions.com/hardware/products/" + this.endPointToPatch, {
          method: "PATCH",
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(this.productNameChange)
        })
        await this.loadItems()
        this.isEditing = false
      }
      this.closeRenameModal()
    },
    closeRenameModal() {
      this.showRenameHardwareModal = false 
    },
    isInStock(pupilRow) {
      return pupilRow.assignmentStatus === "In stock"
    },
  },
}
</script>

<style>
  .custom-padding {
    padding-left: 5;
    padding-right: 35;
  }
</style>