<template lang="html">
  <!-- Contact Information Card -->
<div class="card mb-3 pl-5 mt-3">
  <span v-if="isCreatingNonPupilAddress">
    <div class="card-header">Contact Information</div>
  </span>
  <span v-else>
    <div class="card-header">Home Address</div>
  </span>
  <div class="card-body">

    <span v-if="isCreatingNonPupilAddress">
    <div class="row">
    <div class="mb-3 col-lg-6">
      <label for="contactNumber">Primary Phone *</label>
      <input type="text" class="form-control" input name="contactNumber" id="contactNumber" required>
    </div>
    <div class="mb-3 col-lg-6">
    <label for="contactNumber2">Secondary Phone</label>
    <input type="text" class="form-control" input name="contactNumber2" id="contactNumber2">
    </div>
  </div>
</span>

  <div class="row">
    <div class="mb-3 col-lg-6">
      <label for="addressLine1">Address Line 1*</label>
      <input type="text" class="form-control" input name="addressLine1" id="addressLine1" placeholder="1234 Main St" required>
    </div>
    <div class="mb-3 col-lg-6">
      <label for="addressLine2">Address Line 2</label>
      <input type="text" class="form-control" input name="addressLine2" id="addressLine2" placeholder="Apartment, studio, or floor">
    </div>
  </div>

  <div class="row">
      <div class="mb-3 col-lg-4">
      <label for="city">City *</label>
      <input type="text" class="form-control" input name="city" id="city" required>
    </div>
      <div class="mb-3 col-lg-4">
        <label for="county">County *</label>
        <select id="county" input name="county" class="form-control">
          <option v-for="county in counties" :value="county" :key="county" v-bind:selected="index === 0 ? false: true">
               {{ county }}
          </option>
        </select>
      </div>
      <div class="mb-3 col-lg-4">
        <label for="postcode">Postcode *</label>
        <input type="text" class="form-control" input name="postCode" id="postCode" required>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "CreateAddressInfo",
  props: {
    address: {},
    isCreatingNonPupilAddress: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // address: [],
      counties: [String]
    }
  },
  async created() {
    const res = await fetch('https://be.pencasolutions.com/counties/', {
      method: "GET",
      credentials: 'include',
    })
    this.counties = await res.json()
  }
}
</script>
