<template>
  <span v-if="hasFetchedPupils">
    <h1> {{teacherName}} Caseload</h1>
    <hr>
    <BookAssessmentsTable
      :pupilsToBook="pupilsAwaitingAssessment"
      :requestedTeacherId="requestedTeacherId"
      @clickedReschedule = "showScheduleModal"
    />

    <ScheduledAssessmentsTable
      :scheduledPupils="pupilsAssessmentScheduled"
      :assessmentTable="true"
      :requestedTeacherId="requestedTeacherId"
      @clickedReschedule = "showScheduleModal"
    />

    <BookReviewsTable
      :pupilsToBook="pupilsAwaitingReview"
      :requestedTeacherId="requestedTeacherId"
      @clickedReschedule = "showScheduleModal"
    />

    <ScheduledPupilsTable
      :scheduledPupils="pupilsReviewScheduled"
      :reviewTable="true"
      :requestedTeacherId="requestedTeacherId"
      @clickedReschedule = "showScheduleModal"
    />

    <MyPupilsTable
      :myPupils="myPupils"
      :requestedTeacherId="requestedTeacherId"
    />

    <HardwareRequestTable
      :myEquipmentRequests="myEquipmentRequests"
    />
  </span>
</template>

<script setup>
import { ref, computed, onMounted} from 'vue'
import { useRoute } from 'vue-router';
import BookAssessmentsTable from '../../../views/Staff/Teacher/Tables/BookAssessmentsTable.vue'
import ScheduledAssessmentsTable from '../../../views/Staff/Teacher/Tables/ScheduledAssessmentsAndReviews.vue'
import BookReviewsTable from '../../../views/Staff/Teacher/Tables/BookReviewsTable.vue'
import ScheduledPupilsTable from '../../../views/Staff/Teacher/Tables/ScheduledAssessmentsAndReviews.vue'
import MyPupilsTable from '../../../views/Staff/Teacher/Tables/MyPupils.vue'
import HardwareRequestTable from '../../../views/Staff/Teacher/Tables/HardwareRequests.vue' 

let hasFetchedPupils = ref(false)
let teacherName = ref('')
let myPupils = ref([])
let myEquipmentRequests = ref([])
let requestedTeacherId = ref('')
let route = useRoute()

onMounted(async () => {
  await fetchTeacher();
});

//Computed properties to filter pupils based on status
const pupilsAwaitingAssessment = computed(() => myPupils.value.filter(({ status }) => status === 'Awaiting Assessment'))
const pupilsAwaitingReview = computed(() => myPupils.value.filter(({ status }) => status === 'Awaiting Review'))
const pupilsAssessmentScheduled = computed(() => myPupils.value.filter(({ status }) => status === 'Assessment Scheduled'))
const pupilsReviewScheduled = computed(() => myPupils.value.filter(({ status }) => status === 'Review Scheduled'))

async function fetchTeacher() {
  let teacherId = route.params.id; // Accessing the route parameter
  console.log(teacherId)
  let res = await fetch(`https://be.pencasolutions.com/staff/teachers/${teacherId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  const json = await res.json()
  console.log(json)
  hasFetchedPupils.value = true
  teacherName.value = json.teacherName
  myPupils.value = json.myPupils
  myEquipmentRequests.value = json.myEquipmentRequests
  requestedTeacherId.value = json.requestedTeacherId
}
</script>
