<template>
    <div class="modal fade" id="approvenmodal" tabindex="-1" role="dialog" aria-labelledby="approvenmodal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Approval File {{approvedPupil.firstName}} {{approvedPupil.lastName}}</h5>
        </div>
        <div class="modal-body">
            <input
              id="fileApprovalUpload"
              type="file"
              @change="onFileChanged($event)"
              accept="image/*"
              capture
            />
          </div>
          <div class="modal-footer">
            <button @click="uploadFile()" id="uploadfileapprovalbtn" class="btn btn-success">{{uploadButtonText}}</button>
            <button @click="$emit('close')" class="btn btn-primary" id="closeapprovemodal" type="button" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
export default {
  name: 'ApprovePupilModal',
  data() {
    return {
      fileToUpload: Object,
      uploadButtonText: "Approve"
    }
  },
  props: {
    approvedPupil: {}
  },
  methods: {
    onFileChanged(e) {
      this.fileToUpload = e.target.files[0]
      this.uploadButtonText = 'Upload and approve'
    },
    async uploadFile() {
      document.getElementById('uploadfileapprovalbtn').disabled = true
      this.uploadButtonText = 'Uploading'

      var formData = new FormData();
      formData.append('file',  this.fileToUpload)

        await fetch('https://be.pencasolutions.com/pupils/' + this.approvedPupil._id + '/approve', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        credentials: 'include',
        body: formData
      } )
      document.getElementById('fileApprovalUpload').value = ""
      document.getElementById('closeapprovemodal').click()
      this.uploadButtonText = 'Approve'
      document.getElementById('uploadfileapprovalbtn').disabled = false
      this.$emit('didApprove')
    },
  }
}
</script>
